import React from 'react';
import { CheckCircleIcon, XCircleIcon, ArrowPathIcon } from '@heroicons/react/20/solid';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import type { IPipelineStatus } from 'utils/platformApiTypes';

const tooltipStyles = {
  ...defaultStyles,
  backgroundColor: '#4b5563',
  color: 'white',
  fontSize: 12,
  zIndex: 30,
  maxWidth: 250,
};

interface TooltipData {
  description: string;
}

interface PipelineStatusBadgeProps {
  status?: IPipelineStatus;
  url?: string;
}

export default function PipelineStatusBadge(props: PipelineStatusBadgeProps): JSX.Element {
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip<TooltipData>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  if (
    props.status !== 'success' &&
    props.status !== 'failed' &&
    props.status !== 'pending' &&
    props.status !== 'in-progress'
  ) {
    return <></>;
  }

  const badge = (
    <>
      {props.status === 'success' && <CheckCircleIcon className="w-5 h-5 text-green-500" />}
      {props.status === 'failed' && <XCircleIcon className="w-5 h-5 text-red-500" />}
      {(props.status === 'in-progress' || props.status === 'pending') && (
        <ArrowPathIcon className="w-5 h-5 text-blue-500" />
      )}
    </>
  );

  if (props.status !== undefined && props.url !== undefined) {
    return (
      <>
        <a
          href={props.url}
          target="_blank"
          rel="noreferrer"
          className="cursor-pointer"
          ref={containerRef}
          onMouseLeave={() => {
            hideTooltip();
          }}
          onMouseMove={(event) => {
            const eventSvgCoords = localPoint(event);
            showTooltip({
              tooltipData: {
                description:
                  props.status === 'in-progress'
                    ? 'Pipeline running'
                    : props.status === 'success' || props.status === 'manual'
                    ? 'Pipeline passed'
                    : `Pipeline ${props.status as string}` ?? '',
              },
              tooltipTop: eventSvgCoords?.y,
              tooltipLeft: eventSvgCoords?.x,
            });
          }}
        >
          {badge}
        </a>
        {tooltipOpen && tooltipData !== undefined && (
          <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
            <div className="text-sm">{tooltipData.description}</div>
          </TooltipInPortal>
        )}
      </>
    );
  }

  return badge;
}
