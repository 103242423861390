import React, { useState } from 'react';
import { useAppSelector } from 'app/hooks';
import { selectDeploymentOption, selectSelectedModules } from '../../native-app-builder/reducers/nativeAppSlice';
import { selectSnowflakeAccount } from 'app/snowflakeSlice';
import StepWrapper from 'components/StepWrapper';
import OverviewBlock from 'components/OverviewBlock';
import LoadingAndErrorSection from 'components/LoadingAndErrorSection';
import { IModuleType } from 'utils/types';

export interface SscSolutionSummaryStepProps {
  onBack: () => void;
  onContinue: () => void;
}

export default function SscSolutionSummaryStep(props: SscSolutionSummaryStepProps): JSX.Element {
  const deploymentOption = useAppSelector(selectDeploymentOption);
  const selectedModules = useAppSelector(selectSelectedModules);
  const snowflakeAccount = useAppSelector(selectSnowflakeAccount);

  const [loading] = useState<boolean>(false);
  const [failed] = useState<boolean>(false);
  const [errorMessage] = useState<string>('Failed create project');

  // const stats = [
  //   { name: 'Python UDF', value: 2 },
  //   { name: 'Streamlit', value: 1 },
  //   { name: 'SQL UDF', value: 1 },
  //   { name: 'SPCS', value: 1 },
  // ];

  const getLogo = (moduleType: IModuleType): string => {
    switch (moduleType) {
      case 'Python UDF':
        return '/static/python-udf-logo.png';
      case 'Streamlit':
        return '/static/streamlit-logo.png';
      case 'SQL UDF':
        return '/static/sql-udf-logo.png';
      case 'Snowpark Container Services':
        return '/static/spcs-logo.png';
      case 'Snowflake AI':
        return '/static/snowflake-ai-logo.png';
      case 'Cortex AI':
        return '/static/snowflake-cortex-logo.png';
      case 'Cortex AI Search':
        return '/static/snowflake-cortex-logo.png';
      case 'Shared Data':
        return '/static/snowflake-sharing-logo.png';
      case 'Notebook':
        return '/static/notebook-logo.png';
      default:
        return '/static/snowflake-cortex-logo.png';
    }
  };

  return (
    <StepWrapper
      title="Summary"
      subtitle="Review your new SSC Solution"
      onBack={() => props.onBack()}
      onContinue={() => props.onContinue()}
      continueDisabled={loading}
    >
      <div className="px-6 w-full wide:w-3/4 mx-[auto]">
        <div className="w-full">
          <OverviewBlock dividerText="New project details" backgroundColor="bg-gray-50">
            <dl className="grid grid-cols-2">
              <div className="border-gray-100 px-4 py-3">
                <dt className="text-sm font-semibold text-gray-900">Group</dt>
                <dd className="text-sm text-gray-700">internal/test_group</dd>
              </div>
              <div className="border-gray-100 px-4 py-3">
                <dt className="text-sm font-semibold text-gray-900">Project</dt>
                <dd className="text-sm text-gray-700 truncate">test_ssc_solution</dd>
              </div>
            </dl>
          </OverviewBlock>
        </div>
        <div className="w-full">
          <OverviewBlock dividerText="SSC Solution modules">
            {selectedModules.length === 0 ? (
              <div className="text-sm text-gray-700 text-center">There are no modules in your SSC Solution</div>
            ) : (
              <>
                <div className="ml-2 text-md text-gray-700 font-medium">My SSC Solution</div>
                <div className="p-4 rounded-xl border border-gray-300 bg-gray-50">
                  <ul role="list" className="grid grid-cols-1 gap-4 sm:grid-cols-2 xl:grid-cols-3 wide:grid-cols-4">
                    {selectedModules.map((module, moduleIdx) => (
                      <li key={moduleIdx}>
                        <button
                          type="button"
                          className="group bg-white flex w-full items-center justify-between space-x-3 rounded-full border border-gray-300 p-2 text-left shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          <span className="flex min-w-0 flex-1 items-center space-x-3">
                            <span className="block flex-shrink-0">
                              <img
                                className="h-10 w-10 object-contain p-1 rounded-full overflow-visible"
                                src={getLogo(module.type)}
                              />
                            </span>
                            <span className="block min-w-0 flex-1">
                              <span className="block truncate text-sm font-medium text-gray-900">{module.type}</span>
                              <span className="block truncate text-sm font-medium text-gray-500">{module.name}</span>
                            </span>
                          </span>
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </>
            )}
            {/* <div className="text-sm text-gray-600 px-4">
              <dl className="border border-gray-300 rounded-md overflow-hidden mx-auto grid grid-cols-4 gap-px bg-gray-900/5">
                {stats.map((stat) => (
                  <div
                    key={stat.name}
                    className="flex flex-wrap items-baseline justify-between gap-x-2 gap-y-1 bg-white px-6 py-4"
                  >
                    <dt className="text-sm font-medium leading-6 text-gray-500">{stat.name}</dt>
                    <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                      {stat.value}
                    </dd>
                  </div>
                ))}
              </dl>
            </div> */}
          </OverviewBlock>
        </div>

        <OverviewBlock dividerText="Snowflake account" backgroundColor="bg-gray-50">
          <div className="px-6 text-sm text-gray-600">
            {deploymentOption === 'none' && <p>No Snowflake accounts have been connected to the SSC Solution.</p>}
            {deploymentOption === 'local' && <p>No Snowflake accounts have been connected to the SSC Solution.</p>}
            {deploymentOption === 'single' && <p>A main Snowflake instance has been connected to the SSC Solution.</p>}
            {deploymentOption === 'double' && (
              <p>A main Snowflake instance and an external test instance have been connected to the SSC Solution.</p>
            )}
            {(deploymentOption === 'single' || deploymentOption === 'double') && (
              <div className="mt-2">
                <span>Main Snowflake instance:</span>
                <span className="font-semibold text-gray-800 ml-2">{snowflakeAccount}</span>
              </div>
            )}
            {deploymentOption === 'double' && (
              <div className="mt-2">
                <span>Test Snowflake instance:</span>
                <span className="font-semibold text-gray-800 ml-2">dmbhtnr-nu5112</span>
              </div>
            )}
          </div>
        </OverviewBlock>
        <LoadingAndErrorSection isLoading={loading} isFailed={failed} errorMessage={errorMessage} />
      </div>
    </StepWrapper>
  );
}
