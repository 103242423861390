import React from 'react';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { selectSelectedDatabase, setSelectedDatabase } from 'app/snowflakeSlice';
import { selectEnvironmentManagement, clearSelectedObjects } from '../reducers/builderSlice';
import StepWrapper from 'components/StepWrapper';
import DatabaseSelector from 'components/DatabaseSelector';
import InfoSection from 'components/InfoSection';

export interface ProductionDatabaseStepProps {
  onBack: () => void;
  onContinue: () => void;
}

export default function ProductionDatabaseStep(props: ProductionDatabaseStepProps): JSX.Element {
  const dispatch = useAppDispatch();
  const selectedDatabase = useAppSelector(selectSelectedDatabase);
  const environmentManagement = useAppSelector(selectEnvironmentManagement);

  /* eslint-disable @typescript-eslint/strict-boolean-expressions */
  return (
    <StepWrapper
      title="Which database would you like to select tables from to add to the dataset?"
      subtitle="Select the database that holds the tables to include in your dataset"
      onBack={() => props.onBack()}
      onContinue={() => props.onContinue()}
      continueDisabled={selectedDatabase === ''}
    >
      <div className="min-h-full flex flex-col">
        <DatabaseSelector
          onSelect={(database) => {
            dispatch(clearSelectedObjects());
            dispatch(setSelectedDatabase(database));
          }}
          selectedDatabases={selectedDatabase !== '' ? [selectedDatabase] : []}
        />
        <div className="w-3/4 mt-[auto] self-center">
          <InfoSection
            text={
              environmentManagement === 'managed'
                ? 'Create a copy by cloning the source database and easily manage it in our data product platform by generating configuration files and performing development in a safe environment. Once satisfied with the changes, apply them to the original database objects.'
                : 'Create the metadata-only database for the dataset — without copying the source — and manage it in the platform, excluding its source. Synchronize your changes manually.'
            }
          />
        </div>
      </div>
    </StepWrapper>
  );
}
