import React, { useState } from 'react';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { selectDeploymentOption, setDeploymentOption } from 'features/native-app-builder/reducers/nativeAppSlice';
import StepWrapper from 'components/StepWrapper';
import LoadingAndErrorSection from 'components/LoadingAndErrorSection';
import ViewImageModal from 'components/ViewImageModal';

export interface DeploymentConfigurationStepProps {
  onBack: () => void;
  onContinue: () => void;
}

export default function DeploymentConfigurationStep(props: DeploymentConfigurationStepProps): JSX.Element {
  const dispatch = useAppDispatch();
  const deploymentOption = useAppSelector(selectDeploymentOption);

  const [loading] = useState<boolean>(false);
  const [failed] = useState<boolean>(false);
  const [errorMessage] = useState<string>('');

  const [imageModalOpen, setImageModalOpen] = useState<boolean>(false);
  const [modalImageSrc, setModalImageSrc] = useState<string>('/static/single-account.png');

  return (
    <StepWrapper
      title="Deployment configuration"
      subtitle="How would you like to test your components?"
      onBack={() => props.onBack()}
      onContinue={() => props.onContinue()}
    >
      <div className="h-full flex flex-col items-center">
        <fieldset className="w-3/4">
          <div className="divide-y divide-gray-200">
            <div className="relative flex items-start pb-6 pt-3.5">
              <div className="min-w-0 flex-1 text-sm leading-6">
                <label htmlFor="first-option" className="font-medium text-gray-900">
                  Create project without deployment testing
                </label>
                <p className="text-gray-500">This does not setup any deployment testing.</p>
              </div>
              <div className="ml-3 flex h-6 items-center">
                <input
                  id="first-option"
                  name="deployment"
                  type="radio"
                  checked={deploymentOption === 'none'}
                  onChange={() => dispatch(setDeploymentOption('none'))}
                  className="w-5 h-5 rounded border-gray-300"
                />
              </div>
            </div>
            <div className="relative flex items-start pb-6 pt-3.5">
              <div className="min-w-0 flex-1 text-sm leading-6">
                <label htmlFor="ddl-option" className="font-medium text-gray-900">
                  Create project and include local deployment testing
                </label>
                <p className="text-gray-500">
                  This allows local testing within DataOps.live Develop but doesn&apos;t setup any actual testing
                  against Snowflake. This should only be used if you don&apos;t currently have access to a Snowflake
                  account.
                </p>
              </div>
              <div className="ml-3 flex h-6 items-center">
                <input
                  id="second-option"
                  name="deployment"
                  type="radio"
                  checked={deploymentOption === 'local'}
                  onChange={() => dispatch(setDeploymentOption('local'))}
                  className="w-5 h-5 rounded border-gray-300"
                />
              </div>
            </div>
            <div className="relative flex items-start pb-6 pt-3.5">
              <div className="min-w-0 flex-1 text-sm leading-6">
                <label htmlFor="ddl-option" className="font-medium text-gray-900">
                  Create project and include local deployment testing and 1 Snowflake Instance
                </label>
                <p className="text-gray-500">
                  This allows local testing within DataOps.live Develop AND actual testing against Snowflake. This
                  allows the components to be deployed to a Snowflake Account as a Producer, and tested in the same
                  Account as a Consumer. This should only be used if you only have access to single Snowflake account.
                </p>
                <div
                  className="w-1/2 mx-[auto] cursor-pointer"
                  onClick={() => {
                    setModalImageSrc('/static/single-account.png');
                    setImageModalOpen(true);
                  }}
                >
                  <img src="/static/single-account.png" alt="Single account diagram" />
                </div>
              </div>
              <div className="ml-3 flex h-6 items-center">
                <input
                  id="third-option"
                  name="deployment"
                  type="radio"
                  checked={deploymentOption === 'single'}
                  onChange={() => dispatch(setDeploymentOption('single'))}
                  className="w-5 h-5 rounded border-gray-300"
                />
              </div>
            </div>
            <div className="relative flex items-start pb-6 pt-3.5">
              <div className="min-w-0 flex-1 text-sm leading-6">
                <label htmlFor="ddl-option" className="font-medium text-gray-900">
                  Create project and include local deployment testing and 2 Snowflake Instance
                </label>
                <p className="text-gray-500">
                  This allows local testing within DataOps.live Develop AND actual testing against Snowflake. This
                  allows the components be deployed to a Snowflake Account as a Producer, and tested in the different
                  Account as a Consumer. This is the recommended approach as it most accurately reflects having the
                  Producer in one account and Consumer(s) in others.
                </p>
                <div
                  className="w-2/3 mx-[auto] cursor-pointer"
                  onClick={() => {
                    setModalImageSrc('/static/dual-account.png');
                    setImageModalOpen(true);
                  }}
                >
                  <img src="/static/dual-account.png" alt="Dual account diagram" />
                </div>
              </div>
              <div className="ml-3 flex h-6 items-center">
                <input
                  id="fourth-option"
                  name="deployment"
                  type="radio"
                  checked={deploymentOption === 'double'}
                  onChange={() => dispatch(setDeploymentOption('double'))}
                  className="w-5 h-5 rounded border-gray-300"
                />
              </div>
            </div>
          </div>
        </fieldset>
        <LoadingAndErrorSection isLoading={loading} isFailed={failed} errorMessage={errorMessage} />
      </div>
      <ViewImageModal imageSrc={modalImageSrc} open={imageModalOpen} setOpen={setImageModalOpen} />
    </StepWrapper>
  );
}
