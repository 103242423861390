import React, { useState, useRef, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useCreateMergeRequestMutation } from 'app/platformApi';

interface CreateMRDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  projectId: string;
  branchName: string;
}

const CreateMRDialog = (props: CreateMRDialogProps): JSX.Element => {
  const cancelButtonRef = useRef(null);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const [createMergeRequest] = useCreateMergeRequestMutation();

  const onCreateMR = (): void => {
    createMergeRequest({
      projectId: props.projectId,
      sourceBranch: props.branchName,
      targetBranch: 'sit',
      title,
      description,
    })
      .unwrap()
      .then(() => {
        props.setOpen(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => props.setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="w-[25rem] relative transform overflow-visible rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <Dialog.Title as="h3" className="mb-4 text-lg text-center font-semibold leading-6 text-gray-900">
                  Submit for Review
                </Dialog.Title>
                <div className="w-full">
                  <div className="mb-2">
                    <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                      Title
                    </label>
                    <div className="">
                      <div className="flex rounded-md shadow-sm">
                        <input
                          type="text"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          name="title"
                          id="title"
                          autoComplete="title"
                          required
                          className="block rounded-md flex-1 ring-1 ring-inset ring-gray-300 border-0 bg-transparent p-1.5 text-black placeholder:text-gray-400 sm:text-sm disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                      Description
                    </label>
                    <div className="">
                      <textarea
                        id="decription"
                        name="description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        rows={4}
                        required
                        className="block w-full rounded-md border-0 p-1.5 text-sm text-black placeholder:text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300"
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-6 flex items-center justify-center gap-2">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-dataops-secondary-blue px-3 py-2 text-sm font-semibold text-gray-50 shadow-sm hover:bg-hover-secondary-blue sm:mt-0 sm:w-auto"
                    onClick={() => onCreateMR()}
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    ref={cancelButtonRef}
                    onClick={() => props.setOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CreateMRDialog;
