import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
import type { RootState } from 'app/store';
import type { ISqlModel } from '../types/sqlBuilderTypes';

export interface IAstraZenecaState {
  skipSnowflakeConnectionStep: boolean;
  additionalSourceDatabases: string[];
  name: string;
  description: string;
  jiraTicketNo: string;
  owner: string;
  dataProductManager: string;
  version: string;
  versionDescription: string;
  type: string;
  dataProductType: string;
  containsPatientData: boolean;
  containsPersonalData: boolean;
  isGxpValidated: boolean;
  isSoxControlled: boolean;
  purpose: string;
  specification1: string;
  sourceSystem: string;
  l1BusinessCapability: string;
  l1BusinessCapabilityMainCategory: string;
  l1BusinessCapabilitySubCategory: string;
  format: string;
  dataOffice: string;
  informationSecurityClass: string;
  application: string;
  gradCode: string;
  businessFunction: string;
  businessCapability: string;
  fairProfileName: string;
  useCase: string;
  url: string;
  businessTerm: string;
  businessTermDefinition: string;
  isPowerUserMode: boolean;
  models: ISqlModel[] | undefined;
  reviewedByInformationArchitect: boolean;
}

export const initialState: IAstraZenecaState = {
  skipSnowflakeConnectionStep: false,
  additionalSourceDatabases: [],
  name: '',
  description: '',
  jiraTicketNo: '',
  owner: '',
  dataProductManager: '',
  version: '',
  versionDescription: '',
  type: '',
  dataProductType: '',
  containsPatientData: false,
  containsPersonalData: false,
  isGxpValidated: false,
  isSoxControlled: false,
  purpose: '',
  specification1: '',
  sourceSystem: '',
  l1BusinessCapability: '',
  l1BusinessCapabilityMainCategory: '',
  l1BusinessCapabilitySubCategory: '',
  format: 'RDBMS',
  dataOffice: 'Operations Data Office',
  informationSecurityClass: '',
  application: '',
  gradCode: 'GRAD - 0000',
  businessFunction: '',
  businessCapability: '',
  fairProfileName: '',
  useCase: '',
  url: '',
  businessTerm: '',
  businessTermDefinition: '',
  isPowerUserMode: false,
  models: undefined,
  reviewedByInformationArchitect: false,
};

export const astraZenecaSlice = createSlice({
  name: 'az',
  initialState,
  reducers: {
    setSkipSnowflakeConnectionStep: (state, action: PayloadAction<boolean>) => {
      state.skipSnowflakeConnectionStep = action.payload;
    },
    setAdditionalSourceDatabases: (state, action: PayloadAction<string[]>) => {
      state.additionalSourceDatabases = action.payload;
    },
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setDescription: (state, action: PayloadAction<string>) => {
      state.description = action.payload;
    },
    setJiraTicketNo: (state, action: PayloadAction<string>) => {
      state.jiraTicketNo = action.payload;
    },
    setOwner: (state, action: PayloadAction<string>) => {
      state.owner = action.payload;
    },
    setDataProductManager: (state, action: PayloadAction<string>) => {
      state.dataProductManager = action.payload;
    },
    setVersion: (state, action: PayloadAction<string>) => {
      state.version = action.payload;
    },
    setVersionDescription: (state, action: PayloadAction<string>) => {
      state.versionDescription = action.payload;
    },
    setType: (state, action: PayloadAction<string>) => {
      state.type = action.payload;
    },
    setDataProductType: (state, action: PayloadAction<string>) => {
      state.dataProductType = action.payload;
    },
    setContainsPatientData: (state, action: PayloadAction<boolean>) => {
      state.containsPatientData = action.payload;
    },
    setContainsPersonalData: (state, action: PayloadAction<boolean>) => {
      state.containsPersonalData = action.payload;
    },
    setIsGxpValidated: (state, action: PayloadAction<boolean>) => {
      state.isGxpValidated = action.payload;
    },
    setIsSoxControlled: (state, action: PayloadAction<boolean>) => {
      state.isSoxControlled = action.payload;
    },
    setPurpose: (state, action: PayloadAction<string>) => {
      state.purpose = action.payload;
    },
    setSpecification1: (state, action: PayloadAction<string>) => {
      state.specification1 = action.payload;
    },
    setSourceSystem: (state, action: PayloadAction<string>) => {
      state.sourceSystem = action.payload;
    },
    setL1BusinessCapability: (state, action: PayloadAction<string>) => {
      state.l1BusinessCapability = action.payload;
    },
    setL1BusinessCapabilityMainCategory: (state, action: PayloadAction<string>) => {
      state.l1BusinessCapabilityMainCategory = action.payload;
    },
    setL1BusinessCapabilitySubCategory: (state, action: PayloadAction<string>) => {
      state.l1BusinessCapabilitySubCategory = action.payload;
    },
    setFormat: (state, action: PayloadAction<string>) => {
      state.format = action.payload;
    },
    setDataOffice: (state, action: PayloadAction<string>) => {
      state.dataOffice = action.payload;
    },
    setInformationSecurityClass: (state, action: PayloadAction<string>) => {
      state.informationSecurityClass = action.payload;
    },
    setApplication: (state, action: PayloadAction<string>) => {
      state.application = action.payload;
    },
    setGradCode: (state, action: PayloadAction<string>) => {
      state.gradCode = action.payload;
    },
    setBusinessFunction: (state, action: PayloadAction<string>) => {
      state.businessFunction = action.payload;
    },
    setBusinessCapability: (state, action: PayloadAction<string>) => {
      state.businessCapability = action.payload;
    },
    setFairProfileName: (state, action: PayloadAction<string>) => {
      state.fairProfileName = action.payload;
    },
    setUseCase: (state, action: PayloadAction<string>) => {
      state.useCase = action.payload;
    },
    setUrl: (state, action: PayloadAction<string>) => {
      state.url = action.payload;
    },
    setBusinessTerm: (state, action: PayloadAction<string>) => {
      state.businessTerm = action.payload;
    },
    setBusinessTermDefinition: (state, action: PayloadAction<string>) => {
      state.businessTermDefinition = action.payload;
    },
    setIsPowerUserMode: (state, action: PayloadAction<boolean>) => {
      state.isPowerUserMode = action.payload;
    },
    setModels: (state, action: PayloadAction<ISqlModel[] | undefined>) => {
      state.models = action.payload;
    },
    setReviewedByInformationArchitect: (state, action: PayloadAction<boolean>) => {
      state.reviewedByInformationArchitect = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
});

export const {
  setSkipSnowflakeConnectionStep,
  setAdditionalSourceDatabases,
  setName,
  setDescription,
  setJiraTicketNo,
  setOwner,
  setDataProductManager,
  setVersion,
  setVersionDescription,
  setType,
  setDataProductType,
  setContainsPatientData,
  setContainsPersonalData,
  setIsGxpValidated,
  setIsSoxControlled,
  setPurpose,
  setSpecification1,
  setSourceSystem,
  setL1BusinessCapability,
  setL1BusinessCapabilityMainCategory,
  setL1BusinessCapabilitySubCategory,
  setFormat,
  setDataOffice,
  setInformationSecurityClass,
  setApplication,
  setGradCode,
  setBusinessFunction,
  setBusinessCapability,
  setFairProfileName,
  setUseCase,
  setUrl,
  setBusinessTerm,
  setBusinessTermDefinition,
  setIsPowerUserMode,
  setModels,
  setReviewedByInformationArchitect,
} = astraZenecaSlice.actions;

export const selectSkipSnowflakeConnectionStep = (state: RootState): boolean => state.az.skipSnowflakeConnectionStep;
export const selectAdditionalSourceDatabases = (state: RootState): string[] => state.az.additionalSourceDatabases;
export const selectName = (state: RootState): string => state.az.name;
export const selectDescription = (state: RootState): string => state.az.description;
export const selectJiraTicketNo = (state: RootState): string => state.az.jiraTicketNo;
export const selectOwner = (state: RootState): string => state.az.owner;
export const selectDataProductManager = (state: RootState): string => state.az.dataProductManager;
export const selectVersion = (state: RootState): string => state.az.version;
export const selectVersionDescription = (state: RootState): string => state.az.versionDescription;
export const selectType = (state: RootState): string => state.az.type;
export const selectDataProductType = (state: RootState): string => state.az.dataProductType;
export const selectContainsPatientData = (state: RootState): boolean => state.az.containsPatientData;
export const selectContainsPersonalData = (state: RootState): boolean => state.az.containsPersonalData;
export const selectIsGxpValidated = (state: RootState): boolean => state.az.isGxpValidated;
export const selectIsSoxControlled = (state: RootState): boolean => state.az.isSoxControlled;
export const selectPurpose = (state: RootState): string => state.az.purpose;
export const selectSpecification1 = (state: RootState): string => state.az.specification1;
export const selectSourceSystem = (state: RootState): string => state.az.sourceSystem;
export const selectL1BusinessCapability = (state: RootState): string => state.az.l1BusinessCapability;
export const selectL1BusinessCapabilityMainCategory = (state: RootState): string =>
  state.az.l1BusinessCapabilityMainCategory;
export const selectL1BusinessCapabilitySubCategory = (state: RootState): string =>
  state.az.l1BusinessCapabilitySubCategory;
export const selectFormat = (state: RootState): string => state.az.format;
export const selectDataOffice = (state: RootState): string => state.az.dataOffice;
export const selectInformationSecurityClass = (state: RootState): string => state.az.informationSecurityClass;
export const selectApplication = (state: RootState): string => state.az.application;
export const selectGradCode = (state: RootState): string => state.az.gradCode;
export const selectBusinessFunction = (state: RootState): string => state.az.businessFunction;
export const selectBusinessCapability = (state: RootState): string => state.az.businessCapability;
export const selectFairProfileName = (state: RootState): string => state.az.fairProfileName;
export const selectUseCase = (state: RootState): string => state.az.useCase;
export const selectUrl = (state: RootState): string => state.az.url;
export const selectBusinessTerm = (state: RootState): string => state.az.businessTerm;
export const selectBusinessTermDefinition = (state: RootState): string => state.az.businessTermDefinition;
export const selectIsPowerUserMode = (state: RootState): boolean => state.az.isPowerUserMode;
export const selectModels = (state: RootState): ISqlModel[] | undefined => state.az.models;
export const selectReviewedByInformationArchitect = (state: RootState): boolean =>
  state.az.reviewedByInformationArchitect;

export default astraZenecaSlice.reducer;
