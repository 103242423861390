import React from 'react';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { selectSelectedDatabase, setSelectedDatabase } from 'app/snowflakeSlice';
import StepWrapper from 'components/StepWrapper';
import DatabaseSelector from 'components/DatabaseSelector';

export interface TargetDatabaseStepProps {
  onBack: () => void;
  onContinue: () => void;
}

export default function TargetDatabaseStep(props: TargetDatabaseStepProps): JSX.Element {
  const dispatch = useAppDispatch();
  const selectedDatabase = useAppSelector(selectSelectedDatabase);

  return (
    <StepWrapper
      title="Target database"
      subtitle="Select the target database"
      onBack={() => props.onBack()}
      onContinue={() => props.onContinue()}
    >
      <div className="h-full">
        <DatabaseSelector
          onSelect={(database) => {
            dispatch(setSelectedDatabase(database));
          }}
          selectedDatabases={selectedDatabase !== '' ? [selectedDatabase] : []}
        />
      </div>
    </StepWrapper>
  );
}
