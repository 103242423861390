import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
import type { IModule } from 'utils/types';
import type { RootState } from 'app/store';

type DeploymentOption = 'none' | 'local' | 'single' | 'double';
type StreamlitDeploymentOption = 'sis' | 'standalone';

export interface INativeAppState {
  nativeAppName: string;
  selectedModules: IModule[];
  deplyomentOption: DeploymentOption;

  testSnowflakeAccount: string;
  testSnowflakeUsername: string;
  testSnowflakeWarehouse: string;
  testSnowflakeRole: string;
  testSnowflakeAccountConnected: boolean;

  // Config which will be probably handled by IModules
  // Python UDF
  pythonUdfDescription: string;

  // SQL UDF
  sqlUdfDescription: string;

  // Streamlit config
  streamlitDeploymentOption: StreamlitDeploymentOption;
  streamlitDescription: string;

  // SPCS config
  spcsMinNodes: number;
  spcsMaxNodes: number;
  spcsInstanceFamily: string;
  spcsDescription: string;

  // Snowflake AI
  snowflakeAiDescription: string;

  // Cortex AI
  cortexDescription: string;

  // Cortex AI Search
  cortexSearchDescription: string;

  // Package
  packageName: string;
  packageRole: string;
  packageWarehouse: string;
  applicationName: string;
  applicationRole: string;
  applicationWarehouse: string;
}

export const initialState: INativeAppState = {
  nativeAppName: 'My Native App',
  selectedModules: [],
  deplyomentOption: 'single',
  testSnowflakeAccount: '',
  testSnowflakeUsername: '',
  testSnowflakeWarehouse: '',
  testSnowflakeRole: '',
  testSnowflakeAccountConnected: false,
  pythonUdfDescription: '',
  sqlUdfDescription: '',
  streamlitDeploymentOption: 'sis',
  streamlitDescription: '',
  spcsMinNodes: 1,
  spcsMaxNodes: 2,
  spcsInstanceFamily: 'CPU | M',
  spcsDescription: '',
  snowflakeAiDescription: '',
  cortexDescription: '',
  cortexSearchDescription: '',
  packageName: 'my_native_app_pkg',
  packageRole: 'APP_ROLE',
  packageWarehouse: 'COMPUTE_WH',
  applicationName: 'my_native_app',
  applicationRole: 'APP_ROLE',
  applicationWarehouse: 'COMPUTE_WH',
};

export const nativeAppSlice = createSlice({
  name: 'nativeApp',
  initialState,
  reducers: {
    setNativeAppName: (state, action: PayloadAction<string>) => {
      state.nativeAppName = action.payload;
      state.packageName = `${action.payload.replaceAll(' ', '_').toLowerCase()}_pkg`;
      state.applicationName = action.payload.replaceAll(' ', '_').toLowerCase();
    },
    setSelectedModules: (state, action: PayloadAction<IModule[]>) => {
      state.selectedModules = action.payload;
    },
    setTestSnowflakeAccount: (state, action: PayloadAction<string>) => {
      state.testSnowflakeAccount = action.payload;
    },
    setTestSnowflakeUsername: (state, action: PayloadAction<string>) => {
      state.testSnowflakeUsername = action.payload;
    },
    setTestSnowflakeWarehouse: (state, action: PayloadAction<string>) => {
      state.testSnowflakeWarehouse = action.payload;
    },
    setTestSnowflakeRole: (state, action: PayloadAction<string>) => {
      state.testSnowflakeRole = action.payload;
    },
    setTestSnowflakeAccountConnected: (state, action: PayloadAction<boolean>) => {
      state.testSnowflakeAccountConnected = action.payload;
    },
    setDeploymentOption: (state, action: PayloadAction<DeploymentOption>) => {
      state.deplyomentOption = action.payload;
    },
    setPythonUdfDescription: (state, action: PayloadAction<string>) => {
      state.pythonUdfDescription = action.payload;
    },
    setSqlUdfDescription: (state, action: PayloadAction<string>) => {
      state.sqlUdfDescription = action.payload;
    },
    setStreamlitDeploymentOption: (state, action: PayloadAction<StreamlitDeploymentOption>) => {
      state.streamlitDeploymentOption = action.payload;
    },
    setStreamlitDescription: (state, action: PayloadAction<string>) => {
      state.streamlitDescription = action.payload;
    },
    setSpcsMinNodes: (state, action: PayloadAction<number>) => {
      state.spcsMinNodes = action.payload;
    },
    setSpcsMaxNodes: (state, action: PayloadAction<number>) => {
      state.spcsMaxNodes = action.payload;
    },
    setSpcsInstanceFamily: (state, action: PayloadAction<string>) => {
      state.spcsInstanceFamily = action.payload;
    },
    setSpcsDescription: (state, action: PayloadAction<string>) => {
      state.spcsDescription = action.payload;
    },
    setSnowflakeAiDescription: (state, action: PayloadAction<string>) => {
      state.snowflakeAiDescription = action.payload;
    },
    setCortexDescription: (state, action: PayloadAction<string>) => {
      state.cortexDescription = action.payload;
    },
    setCortexSearchDescription: (state, action: PayloadAction<string>) => {
      state.cortexSearchDescription = action.payload;
    },
    setPackageName: (state, action: PayloadAction<string>) => {
      state.packageName = action.payload;
    },
    setPackageRole: (state, action: PayloadAction<string>) => {
      state.packageRole = action.payload;
    },
    setPackageWarehouse: (state, action: PayloadAction<string>) => {
      state.packageWarehouse = action.payload;
    },
    setApplicationName: (state, action: PayloadAction<string>) => {
      state.applicationName = action.payload;
    },
    setApplicationRole: (state, action: PayloadAction<string>) => {
      state.applicationRole = action.payload;
    },
    setApplicationWarehouse: (state, action: PayloadAction<string>) => {
      state.applicationWarehouse = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
});

export const {
  setNativeAppName,
  setSelectedModules,
  setDeploymentOption,
  setTestSnowflakeAccount,
  setTestSnowflakeUsername,
  setTestSnowflakeWarehouse,
  setTestSnowflakeRole,
  setTestSnowflakeAccountConnected,
  setPythonUdfDescription,
  setSqlUdfDescription,
  setStreamlitDeploymentOption,
  setStreamlitDescription,
  setSpcsMinNodes,
  setSpcsMaxNodes,
  setSpcsInstanceFamily,
  setSpcsDescription,
  setSnowflakeAiDescription,
  setCortexDescription,
  setCortexSearchDescription,
  setPackageName,
  setPackageRole,
  setPackageWarehouse,
  setApplicationName,
  setApplicationRole,
  setApplicationWarehouse,
} = nativeAppSlice.actions;

export const selectNativeAppName = (state: RootState): string => state.nativeApp.nativeAppName;
export const selectSelectedModules = (state: RootState): IModule[] => state.nativeApp.selectedModules;
export const selectDeploymentOption = (state: RootState): DeploymentOption => state.nativeApp.deplyomentOption;
export const selectTestSnowflakeAccount = (state: RootState): string => state.nativeApp.testSnowflakeAccount;
export const selectTestSnowflakeUsername = (state: RootState): string => state.nativeApp.testSnowflakeUsername;
export const selectTestSnowflakeWarehouse = (state: RootState): string => state.nativeApp.testSnowflakeWarehouse;
export const selectTestSnowflakeRole = (state: RootState): string => state.nativeApp.testSnowflakeRole;
export const selectTestSnowflakeAccountConnected = (state: RootState): boolean =>
  state.nativeApp.testSnowflakeAccountConnected;
export const selectPythonUdfDescription = (state: RootState): string => state.nativeApp.pythonUdfDescription;
export const selectSqlUdfDescription = (state: RootState): string => state.nativeApp.sqlUdfDescription;
export const selectStreamlitDeploymentOption = (state: RootState): StreamlitDeploymentOption =>
  state.nativeApp.streamlitDeploymentOption;
export const selectStreamlitDescription = (state: RootState): string => state.nativeApp.streamlitDescription;
export const selectSpcsMinNodes = (state: RootState): number => state.nativeApp.spcsMinNodes;
export const selectSpcsMaxNodes = (state: RootState): number => state.nativeApp.spcsMaxNodes;
export const selectSpcsInstanceFamily = (state: RootState): string => state.nativeApp.spcsInstanceFamily;
export const selectSpcsDescription = (state: RootState): string => state.nativeApp.spcsDescription;
export const selectSnowflakeAiDescription = (state: RootState): string => state.nativeApp.snowflakeAiDescription;
export const selectCortexDescription = (state: RootState): string => state.nativeApp.cortexDescription;
export const selectCortexSearchDescription = (state: RootState): string => state.nativeApp.cortexSearchDescription;
export const selectPackageName = (state: RootState): string => state.nativeApp.packageName;
export const selectPackageRole = (state: RootState): string => state.nativeApp.packageRole;
export const selectPackageWarehouse = (state: RootState): string => state.nativeApp.packageWarehouse;
export const selectApplicationName = (state: RootState): string => state.nativeApp.applicationName;
export const selectApplicationRole = (state: RootState): string => state.nativeApp.applicationRole;
export const selectApplicationWarehouse = (state: RootState): string => state.nativeApp.applicationWarehouse;

export default nativeAppSlice.reducer;
