import React, { useState, useRef } from 'react';
import axios from 'axios';
import { Switch } from '@headlessui/react';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { selectProjectPath, selectBranch } from 'app/sharedSlice';
import {
  selectDbtProjectFilename,
  selectDbtVersion,
  selectCompileDbt,
  setDbtProjectFilename,
  setDbtVersion,
  setCompileDbt,
  setSelectorTags,
  setSelectorFiles,
} from '../reducers/dbtMigrationSlice';
import { FolderIcon, CheckCircleIcon } from '@heroicons/react/20/solid';
import StepWrapper from 'components/StepWrapper';
import LoadingAndErrorSection from 'components/LoadingAndErrorSection';
import DropdownSingleselect from 'components/DropdownSingleselect';
import { getConfig } from 'config/config-helper';
import { classNames } from 'utils/styleUtils';

const { dataopsliveBaseUrl } = getConfig();

const supportedDbtVersions = ['1.2', '1.3', '1.4', '1.5', '1.6', '1.7'];

export interface SourceProjectStepProps {
  onBack: () => void;
  onContinue: () => void;
}

export default function SourceProjectStep(props: SourceProjectStepProps): JSX.Element {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('Failed to upload project');

  const [responseMessage, setResponseMessage] = useState<string>();
  const [numberofFilesUploaded, setNumberOfFilesUploaded] = useState<number>();

  const projectPath = useAppSelector(selectProjectPath);
  const branch = useAppSelector(selectBranch);
  const dbtProjectFilename = useAppSelector(selectDbtProjectFilename);
  const dbtVersion = useAppSelector(selectDbtVersion);
  const compileDbt = useAppSelector(selectCompileDbt);

  const [filename, setFilename] = useState<string>(dbtProjectFilename);

  const [dbtOption, setDbtOption] = useState<string>('dbt-core');

  const fileInputRef = useRef<HTMLInputElement>(null);

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files !== null) {
      setFilename(e.target.files?.[0].name);
    }
  };
  const catchError = (error: any): void => {
    console.log('dbt-upload failed');
    setFailed(true);
    setErrorMessage(error.message);
    setSuccess(false);
    setLoading(false);
  };

  const onProjectUpload = (): void => {
    if (fileInputRef !== null) {
      setLoading(true);
      setFailed(false);
      const fileReader = new FileReader();
      fileReader.onload = (event) => {
        const data = event.target?.result;
        const uploadUrl = `/api/v1/dbt-upload/process?branch=${branch}&repository=${dataopsliveBaseUrl}/${projectPath
          .toLowerCase()
          .split(' ')
          .join('-')}.git${compileDbt ? '&dbt=' + dbtVersion : ''}`;

        if (event.target !== null) {
          console.log('Upload successful');
          axios
            .get('/api/v1/dbt-upload/url')
            .then((urlRes: any) => {
              const form = new FormData();
              for (const key in urlRes.data.fields) {
                form.append(key, urlRes.data.fields[key]);
              }
              form.append('file', new Blob([data as ArrayBuffer], { type: 'application/zip' }));
              axios
                .post(urlRes.data.url, form, {
                  transformRequest: (data, headers) => {
                    delete headers.common;
                    delete headers.Authorization;
                    return data;
                  },
                })
                .then((uploadRes: any) => {
                  const key = String(urlRes.data.fields.key);
                  const processUrl = `${uploadUrl}&file_key=${key}`;
                  axios
                    .get(processUrl)
                    .then((res: any) => {
                      console.log(res.data);

                      dispatch(setDbtProjectFilename(filename));

                      if (res.data.objects_stats !== undefined) {
                        setResponseMessage(res.data.objects_stats);
                      }

                      if (res.data.selectors?.tags !== undefined) {
                        dispatch(setSelectorTags(res.data.selectors?.tags));
                      }

                      if (res.data.selectors?.files !== undefined) {
                        setNumberOfFilesUploaded(res.data.selectors?.files?.length);
                        dispatch(setSelectorFiles(res.data.selectors?.files));
                      }

                      setSuccess(true);
                    })
                    .catch(catchError)
                    .finally(() => {
                      setLoading(false);
                    });
                })
                .catch(catchError);
            })
            .catch(catchError);
        }
      };
      if (fileInputRef.current?.files?.[0] !== undefined) {
        fileReader.readAsArrayBuffer(fileInputRef.current?.files?.[0]);
      }
    }
  };

  return (
    <StepWrapper
      title="Source project"
      subtitle="Select a source of your dbt project"
      onBack={() => props.onBack()}
      onContinue={() => props.onContinue()}
      continueDisabled={dbtProjectFilename === ''}
    >
      <div className="w-full xl:w-3/4 wide:w-1/2 mx-[auto] flex flex-col items-center space-y-5">
        <fieldset className="w-1/2">
          <div className="divide-y divide-gray-200">
            <div className="relative flex items-start pb-4 pt-3.5">
              <div className="min-w-0 flex-1 text-sm leading-6">
                <label htmlFor="snowflake-option" className="font-medium text-gray-900">
                  dbt™ Core
                </label>
              </div>
              <div className="ml-3 pr-1 flex h-6 items-center">
                <input
                  id="snowflake-option"
                  name="snowflake-option"
                  type="radio"
                  checked={dbtOption === 'dbt-core'}
                  onChange={() => setDbtOption('dbt-core')}
                  className="w-5 h-5 rounded border-gray-300"
                />
              </div>
            </div>
            <div className="relative flex items-start pb-4 pt-3.5">
              <div className="min-w-0 flex-1 text-sm leading-6">
                <label htmlFor="ddl-option" className="font-medium text-gray-900">
                  dbt™ Cloud
                </label>
              </div>
              <div className="ml-3 pr-1 flex h-6 items-center">
                <input
                  id="ddl-option"
                  name="ddl-option"
                  type="radio"
                  checked={dbtOption === 'dbt-cloud'}
                  onChange={() => setDbtOption('dbt-cloud')}
                  className="w-5 h-5 rounded border-gray-300"
                />
              </div>
            </div>
          </div>
        </fieldset>

        <div className="w-4/5 border-t border-gray-300"></div>

        {dbtOption === 'dbt-core' && (
          <div className="w-full flex flex-col items-center space-y-4">
            <div className="mt-2 w-full flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
              <div className="text-center flex flex-col items-center">
                <FolderIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                <div className="mt-4 flex text-sm leading-6 text-gray-600">
                  <label
                    htmlFor="file-upload"
                    className="relative cursor-pointer rounded-md bg-white font-semibold text-dataops-secondary-blue hover:text-hover-secondary-blue"
                  >
                    <span>Upload </span>
                    <input
                      type="file"
                      accept=".zip"
                      onChange={onFileChange}
                      ref={fileInputRef}
                      id="file-upload"
                      name="file-upload"
                      className="sr-only"
                    />
                  </label>
                  <p className="pl-1">or drag and drop your dbt project</p>
                </div>
                {filename !== '' && <div className="mt-4 text-sm text-gray-600 font-semibold">{filename}</div>}
              </div>
            </div>

            <div className="w-full flex justify-evenly items-center">
              <div>
                <DropdownSingleselect
                  label="dbt version"
                  options={supportedDbtVersions}
                  selected={dbtVersion}
                  setSelected={(value) => dispatch(setDbtVersion(value))}
                />
              </div>
              <Switch.Group as="div" className="flex flex-col items-center justify-center mb-1">
                <Switch
                  checked={compileDbt}
                  onChange={() => dispatch(setCompileDbt(!compileDbt))}
                  className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none"
                >
                  <span className="sr-only">Use setting</span>
                  <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-md bg-white" />
                  <span
                    aria-hidden="true"
                    className={classNames(
                      compileDbt ? 'bg-dataops-primary-blue' : 'bg-gray-200',
                      'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out',
                    )}
                  />
                  <span
                    aria-hidden="true"
                    className={classNames(
                      compileDbt ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out',
                    )}
                  />
                </Switch>
                <Switch.Label as="span" className="ml-1 cursor-default">
                  <span className="text-sm font-medium text-gray-700">Compile dbt</span>
                </Switch.Label>
              </Switch.Group>
            </div>

            <button
              className="mx-[auto] min-w-[7rem] rounded-md bg-dataops-secondary-blue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-hover-secondary-blue disabled:bg-gray-400"
              onClick={() => onProjectUpload()}
              disabled={fileInputRef.current?.files?.[0] === undefined}
            >
              Upload project
            </button>

            <LoadingAndErrorSection isLoading={loading} isFailed={failed} errorMessage={errorMessage} />

            {success && (
              <div className="w-full rounded-md bg-green-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-green-800">Project successfully uploaded</h3>
                    <div className="flex flex-wrap">
                      {responseMessage !== undefined && responseMessage !== '' && (
                        <div className="mt-2 mr-6 text-sm text-green-700">
                          <p>
                            <span className="font-semibold text-green-900">Message:</span> {responseMessage}
                          </p>
                        </div>
                      )}
                      {numberofFilesUploaded !== undefined && (
                        <div className="mt-2 text-sm text-green-700">
                          <p className="whitespace-nowrap">
                            <span className="font-semibold text-green-900">Files uploaded:</span>{' '}
                            {numberofFilesUploaded}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {dbtOption === 'dbt-cloud' && (
          <div className="w-full flex justify-center">
            <button className="w-1/2 max-w-[20rem] flex items-center justify-center mt-6 bg-white rounded-md py-1 px-3 text-gray-900 shadow-sm hover:bg-gray-200 ring-1 ring-gray-300">
              <span className="pr-2">Connect to</span>
              <img className="w-12 h-[auto]" src="/static/dbt-logo.png" />
            </button>
          </div>
        )}
      </div>
    </StepWrapper>
  );
}
