import React, { useState, useEffect } from 'react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { Disclosure, Transition } from '@headlessui/react';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { selectSnowflakeAccount } from 'app/snowflakeSlice';
import {
  selectSelectedDatabases,
  selectSelectedSchemas,
  selectSelectedTables,
  selectSchema,
  unselectSchema,
  addTables,
} from '../reducers/builderSlice';
import { useGetSchemaLevelObjectsQuery } from 'app/createApi';
import TableItem from './TableItem';
import { classNames } from 'utils/styleUtils';

const tooltipStyles = {
  ...defaultStyles,
  backgroundColor: '#4b5563',
  color: 'white',
  fontSize: 12,
  zIndex: 30,
  maxWidth: 500,
};

interface TooltipData {
  description: string;
}

interface SchemaItemProps {
  databaseName: string;
  schemaName: string;
  mode: 'selection' | 'preview';
  highlighted?: boolean;
}

export default function SchemaItem(props: SchemaItemProps): JSX.Element {
  const dispatch = useAppDispatch();
  const snowflakeAccount = useAppSelector(selectSnowflakeAccount);
  const selectedDatabases = useAppSelector(selectSelectedDatabases);
  const selectedSchemas = useAppSelector(selectSelectedSchemas);
  const selectedTables = useAppSelector(selectSelectedTables);
  const { data: schemaLevelObjects, isLoading } = useGetSchemaLevelObjectsQuery({
    account: snowflakeAccount,
    database: props.databaseName,
    schema: props.schemaName,
    object_type: 'table',
  });
  const [filteredTables, setFilteredTables] = useState<string[]>([]);
  const [selected, setSelected] = useState(false);

  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip<TooltipData>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  useEffect(() => {
    if (schemaLevelObjects !== undefined) {
      const tableArray = schemaLevelObjects.map((table) => {
        return { database: props.databaseName, schema: props.schemaName, table };
      });
      dispatch(addTables({ schemaKey: `${props.databaseName}.${props.schemaName}`, tables: tableArray }));
    }
  }, [schemaLevelObjects]);

  // In preview mode, only show the selected tables
  useEffect(() => {
    if (schemaLevelObjects !== undefined) {
      if (props.mode === 'selection') {
        setFilteredTables(schemaLevelObjects);
      } else {
        setFilteredTables(
          schemaLevelObjects.filter(
            (table) =>
              selectedTables.some(
                (selected) =>
                  selected.database === props.databaseName &&
                  selected.schema === props.schemaName &&
                  selected.table === table,
              ) ||
              selectedSchemas.some(
                (selected) => selected.database === props.databaseName && selected.schema === props.schemaName,
              ) ||
              selectedDatabases.some((selected) => selected.database === props.databaseName),
          ),
        );
      }
    }
  }, [schemaLevelObjects, selectedDatabases, selectedSchemas, selectedTables]);

  useEffect(() => {
    const databaseSelected = selectedDatabases.find((database) => database.database === props.databaseName);
    const schemaSelected = selectedSchemas.find(
      (schema) => schema.schema === props.schemaName && schema.database === props.databaseName,
    );
    if (databaseSelected !== undefined || schemaSelected !== undefined) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [selectedDatabases, selectedSchemas]);

  const onClick = (): void => {
    if (selected) {
      dispatch(unselectSchema({ schema: props.schemaName, database: props.databaseName }));
    } else {
      dispatch(selectSchema({ schema: props.schemaName, database: props.databaseName }));
    }
    setSelected(!selected);
  };

  return (
    <>
      <Disclosure>
        {({ open }) => (
          <>
            <div
              className={classNames(
                'w-full relative flex py-1 border border-gray-300 bg-gray-50 rounded-md text-gray-700 cursor-default',
                props.mode === 'selection' ? 'px-4' : 'px-2',
              )}
            >
              {props.mode === 'selection' && (
                <div className="absolute left-2">
                  <input
                    type="checkbox"
                    checked={selected}
                    onChange={() => onClick()}
                    className="rounded border-gray-300 cursor-pointer"
                  />
                </div>
              )}
              <div
                className={classNames('min-w-[20px] w-5 mr-1', props.mode === 'selection' ? 'ml-2' : '')}
                ref={containerRef}
              >
                {props.highlighted === true ? (
                  <img className="w-5" src="/static/schema-icon-solid.svg" alt="Schema" />
                ) : (
                  <img className="w-5" src="/static/schema-icon.svg" alt="Schema" />
                )}
              </div>
              <div
                className="text-sm truncate mr-2"
                onMouseLeave={() => {
                  hideTooltip();
                }}
                onMouseMove={(event) => {
                  const eventSvgCoords = localPoint(event);
                  showTooltip({
                    tooltipData: { description: props.schemaName },
                    tooltipTop: eventSvgCoords?.y !== undefined ? eventSvgCoords.y + 10 : undefined,
                    tooltipLeft: eventSvgCoords?.x,
                  });
                }}
              >
                {props.schemaName}
              </div>
              <Disclosure.Button className="absolute right-1 w-5 cursor-pointer">
                <ChevronRightIcon
                  className={classNames(
                    open ? 'transform rotate-90' : '',
                    'w-5 h-5 ml-auto text-gray-700 transition duration-150 ease-in-out',
                  )}
                />
              </Disclosure.Button>
            </div>

            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacitsy-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Disclosure.Panel className="px-4 space-y-1">
                {!isLoading &&
                  filteredTables.map((table) => (
                    <TableItem
                      key={table}
                      databaseName={props.databaseName}
                      schemaName={props.schemaName}
                      tableName={table}
                      mode={props.mode}
                      highlighted={props.highlighted}
                    />
                  ))}
                {isLoading && (
                  <div className="w-full h-[30px] border border-gray-300 rounded-md py-1 px-2">
                    <div className="animate-pulse h-2 bg-slate-300 rounded mt-[6px]"></div>
                  </div>
                )}
                {!isLoading && filteredTables.length === 0 && (
                  <div className="mb-2 text-sm text-gray-500">No tables found</div>
                )}
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
      {tooltipOpen && tooltipData !== undefined && (
        <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
          <div className="text-sm">{tooltipData.description}</div>
        </TooltipInPortal>
      )}
    </>
  );
}
