import React from 'react';
import { classNames } from 'utils/styleUtils';

interface TestBadgeProps {
  testType: string;
}

export default function TestBadge(props: TestBadgeProps): JSX.Element {
  return (
    <span
      key={props.testType}
      className={classNames(
        'inline-flex items-center gap-x-0.5 rounded-md px-2 py-1 text-xs font-medium',
        props.testType === 'unique'
          ? 'bg-blue-100 text-blue-700'
          : props.testType === 'accepted_values'
          ? 'bg-indigo-100 text-indigo-700'
          : props.testType === 'relationships'
          ? 'bg-gray-100 text-gray-700'
          : 'bg-purple-100 text-purple-700',
      )}
    >
      {props.testType === 'dbt_utils_unique_combination_of_columns' ? 'combination_of_columns' : props.testType}
    </span>
  );
}
