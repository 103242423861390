import React, { useState, useEffect } from 'react';
import { DocumentIcon } from '@heroicons/react/24/outline';
import { useGenerateSoleFromDDLMutation, useSoleMutation } from 'app/createApi';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { selectProjectPath, selectBranch } from 'app/sharedSlice';
import {
  selectSnowflakeAccount,
  selectSnowflakeUsername,
  selectSnowflakeWarehouse,
  selectSnowflakeRole,
  selectSnowflakeAccountConnected,
  selectSnowflakePrivateKey,
  selectSnowflakePassphrase,
  setSnowflakeAccount,
  setSnowflakeUsername,
  setSnowflakeWarehouse,
  setSnowflakeRole,
  setSnowflakeAccountConnected,
  setSnowflakePrivateKey,
  setSnowflakePassphrase,
} from 'app/snowflakeSlice';
import { selectFlow, selectDataProductName, setFlow, setSoleOutput } from '../reducers/builderSlice';
import StepWrapper from 'components/StepWrapper';
import SnowflakeAccountForm from 'components/SnowflakeAccountForm';
import LoadingAndErrorSection from 'components/LoadingAndErrorSection';

export interface MetadataImportStepProps {
  onBack: () => void;
  onContinue: () => void;
}

export default function MetadataImportStep(props: MetadataImportStepProps): JSX.Element {
  const dispatch = useAppDispatch();

  const [ddlStatement, setDdlStatement] = useState<string>('');
  const [filename, setFilename] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [errorMessage] = useState<string>('Failed to generate config');

  const flow = useAppSelector(selectFlow);
  const snowflakeAccount = useAppSelector(selectSnowflakeAccount);
  const snowflakeUsername = useAppSelector(selectSnowflakeUsername);
  const snowflakeWarehouse = useAppSelector(selectSnowflakeWarehouse);
  const snowflakeRole = useAppSelector(selectSnowflakeRole);
  const snowflakePrivateKey = useAppSelector(selectSnowflakePrivateKey);
  const snowflakePassphrase = useAppSelector(selectSnowflakePassphrase);
  const snowflakeAccountConnected = useAppSelector(selectSnowflakeAccountConnected);
  const dataProductName = useAppSelector(selectDataProductName);
  const projectPath = useAppSelector(selectProjectPath);
  const branch = useAppSelector(selectBranch);

  const [generateSoleFromDDL] = useGenerateSoleFromDDLMutation();
  const [sole] = useSoleMutation();

  const snowflakeOptionCompleted = flow === 'snowflake' && snowflakeAccountConnected;
  const ddlOptionCompleted = flow === 'ddl' && ddlStatement !== '' && snowflakeAccountConnected;

  const onContinue = (): void => {
    if (flow === 'snowflake') {
      props.onContinue();
      return;
    }

    setLoading(true);
    setFailed(false);
    generateSoleFromDDL({
      ddl: ddlStatement,
    })
      .unwrap()
      .then((res: any) => {
        console.log(res);
        const dbtSole = res;
        sole({
          dbtSole,
          manageSole: dbtSole,
          toIncludeModels: true,
          toWriteConfigs: true,
          toWriteJobs: true,
          dataProductName,
          projectName: projectPath,
          account: snowflakeAccount,
          branch,
        })
          .unwrap()
          .then((soleRes: any) => {
            console.log(soleRes);
            setSuccess(true);
            dispatch(setSoleOutput(soleRes));
          })
          .catch((soleErr) => {
            console.log(soleErr);
            setFailed(true);
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((err) => {
        console.log(err);
        setFailed(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (success) {
      props.onContinue();
    }
  }, [success]);

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files !== null) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target !== null) {
          setDdlStatement(event.target.result as string);
        }
      };
      reader.readAsText(e.target.files[0]);
      setFilename(e.target.files[0].name);
      e.target.value = '';
    }
  };

  return (
    <StepWrapper
      title="Where does the dataset source metadata come from?"
      subtitle="Select to have the metadata from an existing Snowflake account through live interaction or from a Data Definition Language (DDL) file that defines a static structure of a database"
      onBack={() => props.onBack()}
      onContinue={() => onContinue()}
      continueDisabled={!snowflakeOptionCompleted && !ddlOptionCompleted}
    >
      <div className="w-full flex flex-col items-center">
        <fieldset>
          <div className="divide-y divide-gray-200">
            <div className="relative flex items-start pb-4 pt-3.5">
              <div className="min-w-0 flex-1 text-sm leading-6">
                <label htmlFor="snowflake-option" className="font-medium text-gray-900">
                  Snowflake
                </label>
                <p className="text-gray-500">Get dataset source metadata from your Snowflake account</p>
              </div>
              <div className="ml-3 pr-1 flex h-6 items-center">
                <input
                  id="snowflake-option"
                  name="snowflake-option"
                  type="radio"
                  checked={flow === 'snowflake'}
                  onChange={() => dispatch(setFlow('snowflake'))}
                  className="w-5 h-5 rounded border-gray-300"
                />
              </div>
            </div>
            <div className="relative flex items-start pb-4 pt-3.5">
              <div className="min-w-0 flex-1 text-sm leading-6">
                <label htmlFor="ddl-option" className="font-medium text-gray-900">
                  DDL statement
                </label>
                <p className="text-gray-500">Get dataset source metadata from a DDL file</p>
              </div>
              <div className="ml-3 pr-1 flex h-6 items-center">
                <input
                  id="ddl-option"
                  name="ddl-option"
                  type="radio"
                  checked={flow === 'ddl'}
                  onChange={() => dispatch(setFlow('ddl'))}
                  className="w-5 h-5 rounded border-gray-300"
                />
              </div>
            </div>
          </div>
        </fieldset>

        {flow !== 'not-selected' && <div className="w-4/5 border-t border-gray-300 my-8"></div>}

        <div className="w-full flex flex-col items-center">
          {flow === 'snowflake' && (
            <div className="space-y-2 w-full flex flex-col items-center">
              <div className="text-gray-600 text-sm mb-2 w-3/4 text-center">
                Connect to your Snowflake account to browse your objects
              </div>
              <SnowflakeAccountForm
                snowflakeAccount={snowflakeAccount}
                setSnowflakeAccount={(account) => dispatch(setSnowflakeAccount(account))}
                snowflakeUsername={snowflakeUsername}
                setSnowflakeUsername={(username) => dispatch(setSnowflakeUsername(username))}
                snowflakeWarehouse={snowflakeWarehouse}
                setSnowflakeWarehouse={(warehouse) => dispatch(setSnowflakeWarehouse(warehouse))}
                snowflakeRole={snowflakeRole}
                setSnowflakeRole={(role) => dispatch(setSnowflakeRole(role))}
                snowflakeAccountConnected={snowflakeAccountConnected}
                setSnowflakeAccountConnected={(connected) => dispatch(setSnowflakeAccountConnected(connected))}
                snowflakePrivateKey={snowflakePrivateKey}
                setSnowflakePrivateKey={(key) => dispatch(setSnowflakePrivateKey(key))}
                snowflakePassphrase={snowflakePassphrase}
                setSnowflakePassphrase={(passphrase) => dispatch(setSnowflakePassphrase(passphrase))}
              />
            </div>
          )}
          {flow === 'ddl' && (
            <>
              <div className="w-3/4 mb-6">
                <label className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 py-2 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <input type="file" accept=".sql" onChange={onFileChange} className="hidden" />
                  {filename === undefined && (
                    <span className="flex items-center justify-center text-sm text-gray-500">Upload a DDL file</span>
                  )}
                  {filename !== undefined && (
                    <div className="flex items-center justify-center">
                      <DocumentIcon className="h-4 w-4 text-gray-500" aria-hidden="true" />
                      <span className="flex items-center justify-center text-sm text-gray-500">{filename}</span>
                    </div>
                  )}
                </label>
              </div>
              <div className="w-3/4">
                <label htmlFor="ddl" className="block text-sm font-medium leading-6 text-gray-900">
                  Paste DDL statements (separated by semicolons)
                </label>
                <div className="mt-1">
                  <textarea
                    id="ddl"
                    name="ddl"
                    rows={5}
                    value={ddlStatement}
                    onChange={(e) => setDdlStatement(e.target.value)}
                    className="block w-full rounded-md border-0 p-2 text-sm text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-40"
                  />
                </div>
              </div>
              <div className="w-4/5 border-t border-gray-300 my-8" />
              <div className="text-gray-600 text-sm mb-2 w-3/4 text-center">
                Connect to your target Snowflake account
              </div>
              <SnowflakeAccountForm
                snowflakeAccount={snowflakeAccount}
                setSnowflakeAccount={(account) => dispatch(setSnowflakeAccount(account))}
                snowflakeUsername={snowflakeUsername}
                setSnowflakeUsername={(username) => dispatch(setSnowflakeUsername(username))}
                snowflakeWarehouse={snowflakeWarehouse}
                setSnowflakeWarehouse={(warehouse) => dispatch(setSnowflakeWarehouse(warehouse))}
                snowflakeRole={snowflakeRole}
                setSnowflakeRole={(role) => dispatch(setSnowflakeRole(role))}
                snowflakeAccountConnected={snowflakeAccountConnected}
                setSnowflakeAccountConnected={(connected) => dispatch(setSnowflakeAccountConnected(connected))}
                snowflakePrivateKey={snowflakePrivateKey}
                setSnowflakePrivateKey={(key) => dispatch(setSnowflakePrivateKey(key))}
                snowflakePassphrase={snowflakePassphrase}
                setSnowflakePassphrase={(passphrase) => dispatch(setSnowflakePassphrase(passphrase))}
              />
              <LoadingAndErrorSection isLoading={loading} isFailed={failed} errorMessage={errorMessage} />
            </>
          )}
        </div>
      </div>
    </StepWrapper>
  );
}
