import React, { useState, useRef, useEffect } from 'react';
import { useNativeAppCreateProjectMutation } from 'app/createApi';
import { TrashIcon } from '@heroicons/react/20/solid';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import {
  selectNativeAppName,
  selectSelectedModules,
  setNativeAppName,
  setSelectedModules,
} from 'features/native-app-builder/reducers/nativeAppSlice';
import {
  selectNewProjectName,
  selectSelectedGroup,
  selectProjectsCreatedInCurrentSession,
  setNewProjectName,
  setProjectsCreatedInCurrentSession,
} from 'app/sharedSlice';
import StepWrapper from 'components/StepWrapper';
import GroupAndProjectSelector, { sanitizeProjectName } from 'components/GroupAndProjectSelector';
import LoadingAndErrorSection from 'components/LoadingAndErrorSection';
import type { IModuleType } from 'utils/types';
import { classNames } from 'utils/styleUtils';

const modules = [
  {
    name: 'Python UDF',
    description: (
      <div>
        <p>
          A Snowflake Python User-Defined Function (UDF) allows you to define custom functions in Python that can be
          executed as part of SQL queries in the Snowflake Data Cloud environment. This capability is particularly
          useful because it integrates Python&apos;s extensive functionality directly into Snowflake&apos;s data
          processing, enabling complex operations that are difficult or impossible to perform with standard SQL.
        </p>
        <div className="mt-1 font-semibold">Key Features of Snowflake Python UDFs:</div>
        <ol className="list-decimal list-inside">
          <li className="ml-2">
            <span className="font-semibold">Python Integration: </span> Leverage Python libraries and functionality
            within your SQL queries.
          </li>
          <li className="ml-2">
            <span className="font-semibold">Custom Processing: </span> Perform operations that are not natively
            supported by SQL, such as advanced statistical calculations, text processing, or even machine learning
            predictions.
          </li>
          <li className="ml-2">
            <span className="font-semibold">Data Transformation: </span> Manipulate and transform data in ways that are
            more natural or easier in Python than in SQL.
          </li>
        </ol>
        <div className="mt-1 font-semibold">When to Use Snowflake Python UDFs:</div>
        <ul className="list-disc list-inside">
          <li className="ml-2">
            <span className="font-semibold">Complex Calculations: </span> When you need to perform mathematical or
            statistical calculations that go beyond the capabilities of standard SQL functions.
          </li>
          <li className="ml-2">
            <span className="font-semibold">Text and Data Analysis: </span> For advanced string manipulation, regular
            expressions, or natural language processing which are more straightforward in Python.
          </li>
          <li className="ml-2">
            <span className="font-semibold">Integrating External Libraries: </span> Utilizing Python&apos;s vast
            ecosystem of libraries (like NumPy, Pandas, or SciPy) for tasks such as data analysis, scientific
            computations, or other specialized functions.
          </li>
          <li className="ml-2">
            <span className="font-semibold">Machine Learning: </span> Applying machine learning models directly within
            your database. You can score or classify data using pre-trained models right where your data resides.
          </li>
          <li className="ml-2">
            <span className="font-semibold">Custom Data Formats: </span> When working with data in formats that SQL
            can&apos;t natively parse or process, Python can be used to extend Snowflake&apos;s capabilities.
          </li>
        </ul>
      </div>
    ),
  },
  {
    name: 'SQL UDF',
    description: (
      <div>
        <p>
          A Snowflake SQL User-Defined Function (UDF) allows you to define custom functions using SQL that can be used
          within Snowflake, a cloud data platform. These UDFs let you encapsulate complex logic into single functions
          that you can reuse in various SQL queries, enhancing readability, maintainability, and efficiency of your SQL
          scripts.
        </p>
        <div className="mt-1 font-semibold">Key Features of Snowflake SQL UDFs:</div>
        <ol className="list-decimal list-inside">
          <li className="ml-2">
            <span className="font-semibold">Custom SQL Logic: </span> You can encapsulate complex SQL expressions and
            logic into a function, making it easier to reuse and manage.
          </li>
          <li className="ml-2">
            <span className="font-semibold">Performance: </span> SQL UDFs generally execute faster than UDFs written in
            other languages like Python or JavaScript because they run natively in Snowflake&apos;s optimized SQL
            engine.
          </li>
          <li className="ml-2">
            <span className="font-semibold">Simplicity: </span> Using SQL for defining UDFs keeps the entire data
            transformation process within the realm of SQL, avoiding the need to switch contexts or languages.
          </li>
        </ol>
        <div className="mt-1 font-semibold">When to Use Snowflake SQL UDFs:</div>
        <ul className="list-disc list-inside">
          <li className="ml-2">
            <span className="font-semibold">Reusable Logic: </span> When you have complex SQL expressions that you need
            to use repeatedly across different queries or reports, encapsulating this logic in a UDF can reduce
            redundancy and errors.
          </li>
          <li className="ml-2">
            <span className="font-semibold">Simplifying Queries: </span> SQL UDFs can make complicated SQL queries
            simpler and more readable by hiding complex logic inside functions.
          </li>
          <li className="ml-2">
            <span className="font-semibold">Performance Optimization: </span> For performance-critical applications
            where keeping the computations within Snowflake&apos;s SQL engine is advantageous.
          </li>
          <li className="ml-2">
            <span className="font-semibold">Conditional Logic: </span> Implementing complex conditional logic within SQL
            that might be cumbersome to repeat in multiple queries.
          </li>
          <li className="ml-2">
            <span className="font-semibold">Data Cleansing: </span> Encapsulating common data cleansing routines in UDFs
            to ensure data quality and consistency across analyses.
          </li>
        </ul>
      </div>
    ),
  },
  {
    name: 'Streamlit',
    description: (
      <div>
        <p>
          Streamlit is an open-source Python library that simplifies the process of creating and sharing beautiful,
          custom web applications for machine learning and data science. In a nutshell, it turns data scripts into
          shareable web apps with minimal effort. Here&pos;s more about Streamlit apps and when they might be used:
        </p>
        <div className="mt-1 font-semibold">Features of Streamlit Apps:</div>
        <ul className="list-disc list-inside">
          <li className="ml-2">
            <span className="font-semibold">Ease of Use: </span> Streamlit apps are easy to create with only a few lines
            of Python code. It handles all the front-end work automatically, so you don&apos;t need to be proficient in
            HTML, CSS, or JavaScript.
          </li>
          <li className="ml-2">
            <span className="font-semibold">Interactive Widgets: </span> It provides built-in widgets like sliders,
            buttons, and text input that can be used to interactively change the parameters of your model or data views.
          </li>
          <li className="ml-2">
            <span className="font-semibold">Real-time Updates: </span> The apps update in real-time as users interact
            with them, making it an excellent tool for data exploration and visualization.
          </li>
          <li className="ml-2">
            <span className="font-semibold">Component Flexibility: </span> Streamlit supports custom components that let
            you integrate other web technologies and frameworks when needed.
          </li>
          <li className="ml-2">
            <span className="font-semibold">Deployment Ease: </span> Streamlit apps can be quickly deployed to share
            with others using Streamlit in Snowflake or as an externally hosted application.
          </li>
        </ul>
        <div className="mt-1 font-semibold">When to Use Streamlit Apps</div>
        <ul className="list-disc list-inside">
          <li className="ml-2">
            <span className="font-semibold">Data Science Exploration: </span> Streamlit is ideal for building
            interactive tools that help explore datasets and models. Data scientists can use it to demonstrate how
            different parameters affect outcomes or to visualize data in various ways.
          </li>
          <li className="ml-2">
            <span className="font-semibold">Machine Learning Model Demonstration: </span> It&apos;s commonly used to
            create interfaces that demonstrate the capabilities of machine learning models, allowing users to input data
            and see model outputs in real-time.
          </li>
          <li className="ml-2">
            <span className="font-semibold">Prototyping and MVP Development: </span> If you need to quickly prototype an
            app to showcase an idea or a proof of concept, Streamlit lets you build and iterate rapidly.
          </li>
          <li className="ml-2">
            <span className="font-semibold">Internal Tools: </span> For companies that need custom internal tools for
            data analysis, Streamlit provides a fast way to build and deploy apps that can be used by teams without
            technical expertise.
          </li>
          <li className="ml-2">
            <span className="font-semibold">Educational Purposes: </span> Educators and trainers can use Streamlit apps
            to create interactive tutorials or demonstrations that enhance learning about complex topics.
          </li>
        </ul>
        <p className="mt-1">
          Overall, Streamlit is a powerful tool when you need a quick and interactive way to present data-driven
          insights, visualize data, or interact with machine learning models, all without requiring in-depth knowledge
          of web development technologies.
        </p>
      </div>
    ),
  },
  {
    name: 'Snowpark Container Services',
    description: (
      <div>
        <p>
          Snowpark Container Services allows developers and data scientists to run containerized workloads directly in
          Snowflake. This capability is part of the Snowpark API, which extends Snowflake&apos;s support for various
          programming languages and frameworks, enabling users to bring their custom code and libraries directly into
          their data platform. Here&apos;s a detailed look at Snowpark Container Services and its optimal use cases:
        </p>
        <div className="mt-1 font-semibold">Features of Snowpark Container Services:</div>
        <ul className="list-disc list-inside">
          <li className="ml-2">
            <span className="font-semibold">Containerization: </span> You can package your code along with its
            dependencies into containers, which are executed directly within Snowflake&apos;s environment. This ensures
            that your applications run consistently, regardless of where they are deployed.
          </li>
          <li className="ml-2">
            <span className="font-semibold">Language Support: </span> While Snowflake traditionally supports SQL,
            Snowpark extends this to include other languages like Python, Java, and Scala. This means you can use these
            languages directly in Snowflake for complex data processing tasks.
          </li>
          <li className="ml-2">
            <span className="font-semibold">Integrated Data Processing: </span> By running containerized workloads in
            Snowflake, you can leverage Snowflake&apos;s powerful data processing capabilities without moving data
            outside of the platform. This is particularly useful for handling large datasets efficiently.
          </li>
          <li className="ml-2">
            <span className="font-semibold">Security and Governance: </span> Snowpark Container Services adheres to
            Snowflake&apos;s stringent security and governance models, ensuring that data is protected and access is
            controlled.
          </li>
        </ul>
        <div className="mt-1 font-semibold">When to Use Snowpark Container Services:</div>
        <ul className="list-disc list-inside">
          <li className="ml-2">
            <span className="font-semibold">Advanced Data Processing Workflows: </span> Use Snowpark when standard SQL
            is not sufficient for your data processing needs, such as for custom data transformations, complex
            statistical models, or machine learning workflows.
          </li>
          <li className="ml-2">
            <span className="font-semibold">Custom Language and Library Requirements: </span> If your data processing or
            analytics work requires specific programming languages or libraries not natively supported in Snowflake,
            Snowpark allows you to bring these into the Snowflake environment.
          </li>
          <li className="ml-2">
            <span className="font-semibold">Consolidating Data Operations: </span> Snowpark Container Services is ideal
            for scenarios where you want to consolidate all data operations within Snowflake to avoid data silos and
            minimize data movement across platforms, thus maintaining efficiency and security.
          </li>
          <li className="ml-2">
            <span className="font-semibold">Development Efficiency: </span> Developers can use familiar languages and
            tools to create data-intensive applications directly in Snowflake, reducing the learning curve and speeding
            up development cycles.
          </li>
          <li className="ml-2">
            <span className="font-semibold">Scalability Needs: </span> For applications requiring the scalability and
            performance benefits of Snowflake, especially when working with very large data sets, Snowpark enables these
            workloads to scale seamlessly without compromising on performance.
          </li>
        </ul>
        <p className="mt-1">
          Overall, Snowpark Container Services is particularly useful for organizations that are already invested in the
          Snowflake ecosystem and need to perform advanced data processing tasks or require the use of specific
          programming languages and libraries within their data workflows. It helps in maintaining a secure, scalable,
          and efficient data processing environment by leveraging Snowflake&apos;s robust architecture.
        </p>
      </div>
    ),
  },
  {
    name: 'Snowflake AI',
    description: (
      <div>
        <p>
          Snowflake AI is a set of machine learning capabilities integrated into the Snowflake Data Cloud platform. This
          initiative is part of Snowflake&apos;s broader efforts to enhance its analytics and data science offerings by
          embedding machine learning tools directly within its ecosystem. Snowflake Snowflake AI enables data scientists
          and analysts to build, train, and deploy machine learning models using the vast data sets available in
          Snowflake, utilizing familiar SQL and other programming languages supported through Snowpark.
        </p>
        <div className="mt-1 font-semibold">Features of Snowflake AI:</div>
        <ul className="list-disc list-inside">
          <li className="ml-2">
            <span className="font-semibold">Native ML Functionality: </span> Snowflake AI provides native support for
            creating and managing machine learning models directly within Snowflake. This includes model training,
            inference, and lifecycle management without needing to export data to external machine learning platforms.
          </li>
          <li className="ml-2">
            <span className="font-semibold">Integration with Popular ML Frameworks: </span> While offering native
            capabilities, it also integrates seamlessly with popular external machine learning frameworks and tools such
            as Scikit-learn, TensorFlow, and PyTorch through Snowpark APIs. This allows users to bring their existing ML
            workflows into Snowflake.
          </li>
          <li className="ml-2">
            <span className="font-semibold">Automated Machine Learning (AutoML): </span> For users looking for more
            automated solutions, Snowflake Snowflake AI offers AutoML features that simplify the process of model
            selection, feature engineering, and hyperparameter tuning.
          </li>
          <li className="ml-2">
            <span className="font-semibold">Secure and Governed Data Access: </span> SLeveraging Snowflake&apos;s robust
            data governance and security framework, Snowflake AI ensures that all data used for machine learning
            purposes is secure and compliant with organizational policies.
          </li>
        </ul>
        <div className="mt-1 font-semibold">When to Use Snowflake AI:</div>
        <ul className="list-disc list-inside">
          <li className="ml-2">
            <span className="font-semibold">Centralized Data Science Workflows: </span> When your data science teams
            need to collaborate and work with large, centralized datasets stored in Snowflake, Snowflake AI can
            facilitate this by enabling direct model development and deployment within the same environment.
          </li>
          <li className="ml-2">
            <span className="font-semibold">Enhanced Data Privacy and Security: </span> In scenarios where data privacy
            and security are paramount (such as in financial services or healthcare), keeping all data and ML processes
            within Snowflake&apos;s secure environment can be beneficial.
          </li>
          <li className="ml-2">
            <span className="font-semibold">Scaling ML Operations: </span> For organizations that require the
            scalability provided by Snowflake&apos;s architecture to handle large volumes of data or complex model
            training, Snowflake AI can efficiently scale these operations without the need for significant additional
            infrastructure.
          </li>
          <li className="ml-2">
            <span className="font-semibold">Streamlining Operations: </span> If reducing the complexity of your
            technology stack is a goal, integrating ML workflows directly into Snowflake can eliminate the need for
            separate ML platforms and data movement, simplifying operations and maintenance.
          </li>
          <li className="ml-2">
            <span className="font-semibold">Real-time ML Applications: </span> When machine learning models need to be
            applied to real-time data streams (like financial transactions or IoT device data), Snowflake AI&apos;s
            capabilities can be leveraged to perform real-time predictions directly within the data platform.
          </li>
        </ul>
      </div>
    ),
  },
  {
    name: 'Cortex AI',
    description: (
      <div>
        <p>
          Cortex AI gives you instant access to industry-leading large language models (LLMs) trained by researchers at
          companies like Mistral, Reka, Meta, and Google, including Snowflake Arctic, an open enterprise-grade model
          developed by Snowflake. Since these LLMs are fully hosted and managed by Snowflake, using them requires no
          setup. Your data stays within Snowflake, giving you the performance, scalability, and governance you expect.
        </p>
        <p>
          Cortex AI features are provided as SQL functions and are also available in Python. The available functions are
          summarized below.
        </p>
        <ul className="list-disc list-inside">
          <li className="ml-2">
            <a
              href="https://docs.snowflake.com/en/user-guide/snowflake-cortex/llm-functions#label-cortex-llm-complete"
              target="_blank"
              rel="noreferrer"
              className="font-semibold text-dataops-secondary-blue hover:underline"
            >
              COMPLETE
            </a>
            : Cortex AI provides native support for creating and managing machine learning models directly within
            Snowflake. This includes model training, inference, and lifecycle management without needing to export data
            to external machine learning platforms.
          </li>
          <li className="ml-2">
            <a
              href="https://docs.snowflake.com/en/user-guide/snowflake-cortex/llm-functions#label-cortex-llm-embed-text"
              target="_blank"
              rel="noreferrer"
              className="font-semibold text-dataops-secondary-blue hover:underline"
            >
              EMBED_TEXT_768
            </a>
            : Given a piece of text, returns a vector embedding that represents that text.
          </li>
          <li className="ml-2">
            <a
              href="https://docs.snowflake.com/en/user-guide/snowflake-cortex/llm-functions#label-cortex-llm-extract-answer"
              target="_blank"
              rel="noreferrer"
              className="font-semibold text-dataops-secondary-blue hover:underline"
            >
              EXTRACT_ANSWER
            </a>
            : Given a question and unstructured data, returns the answer to the question if it can be found in the data.
          </li>
          <li className="ml-2">
            <a
              href="https://docs.snowflake.com/en/user-guide/snowflake-cortex/llm-functions#label-cortex-llm-sentiment"
              target="_blank"
              rel="noreferrer"
              className="font-semibold text-dataops-secondary-blue hover:underline"
            >
              SENTIMENT
            </a>
            : Returns a sentiment score, from -1 to 1, representing the detected positive or negative sentiment of the
            given text.
          </li>
          <li className="ml-2">
            <a
              href="https://docs.snowflake.com/en/user-guide/snowflake-cortex/llm-functions#label-cortex-llm-summarize"
              target="_blank"
              rel="noreferrer"
              className="font-semibold text-dataops-secondary-blue hover:underline"
            >
              SUMMARIZE
            </a>
            : Returns a summary of the given text.
          </li>
          <li className="ml-2">
            <a
              href="https://docs.snowflake.com/en/user-guide/snowflake-cortex/llm-functions#label-cortex-llm-translate"
              target="_blank"
              rel="noreferrer"
              className="font-semibold text-dataops-secondary-blue hover:underline"
            >
              TRANSLATE
            </a>
            : Translates given text from any supported language to any other.
          </li>
        </ul>
      </div>
    ),
  },
  {
    name: 'Cortex AI Search',
    description: (
      <div>
        <p>
          Cortex AI Search gives you instant access to industry-leading large language models (LLMs) trained by
          researchers at companies like Mistral, Reka, Meta, and Google, including Snowflake Arctic, an open
          enterprise-grade model developed by Snowflake. Since these LLMs are fully hosted and managed by Snowflake,
          using them requires no setup. Your data stays within Snowflake, giving you the performance, scalability, and
          governance you expect.
        </p>
        <p>
          Cortex AI Search features are provided as SQL functions and are also available in Python. The available
          functions are summarized below.
        </p>
        <ul className="list-disc list-inside">
          <li className="ml-2">
            <a
              href="https://docs.snowflake.com/en/user-guide/snowflake-cortex/llm-functions#label-cortex-llm-complete"
              target="_blank"
              rel="noreferrer"
              className="font-semibold text-dataops-secondary-blue hover:underline"
            >
              COMPLETE
            </a>
            : Cortex AI Search provides native support for creating and managing machine learning models directly within
            Snowflake. This includes model training, inference, and lifecycle management without needing to export data
            to external machine learning platforms.
          </li>
          <li className="ml-2">
            <a
              href="https://docs.snowflake.com/en/user-guide/snowflake-cortex/llm-functions#label-cortex-llm-embed-text"
              target="_blank"
              rel="noreferrer"
              className="font-semibold text-dataops-secondary-blue hover:underline"
            >
              EMBED_TEXT_768
            </a>
            : Given a piece of text, returns a vector embedding that represents that text.
          </li>
          <li className="ml-2">
            <a
              href="https://docs.snowflake.com/en/user-guide/snowflake-cortex/llm-functions#label-cortex-llm-extract-answer"
              target="_blank"
              rel="noreferrer"
              className="font-semibold text-dataops-secondary-blue hover:underline"
            >
              EXTRACT_ANSWER
            </a>
            : Given a question and unstructured data, returns the answer to the question if it can be found in the data.
          </li>
          <li className="ml-2">
            <a
              href="https://docs.snowflake.com/en/user-guide/snowflake-cortex/llm-functions#label-cortex-llm-sentiment"
              target="_blank"
              rel="noreferrer"
              className="font-semibold text-dataops-secondary-blue hover:underline"
            >
              SENTIMENT
            </a>
            : Returns a sentiment score, from -1 to 1, representing the detected positive or negative sentiment of the
            given text.
          </li>
          <li className="ml-2">
            <a
              href="https://docs.snowflake.com/en/user-guide/snowflake-cortex/llm-functions#label-cortex-llm-summarize"
              target="_blank"
              rel="noreferrer"
              className="font-semibold text-dataops-secondary-blue hover:underline"
            >
              SUMMARIZE
            </a>
            : Returns a summary of the given text.
          </li>
          <li className="ml-2">
            <a
              href="https://docs.snowflake.com/en/user-guide/snowflake-cortex/llm-functions#label-cortex-llm-translate"
              target="_blank"
              rel="noreferrer"
              className="font-semibold text-dataops-secondary-blue hover:underline"
            >
              TRANSLATE
            </a>
            : Translates given text from any supported language to any other.
          </li>
        </ul>
      </div>
    ),
  },
  {
    name: 'Shared Data',
    description: (
      <div>
        <p>
          Shared Data provides a unified, scalable solution for data sharing across different platforms and
          environments. Developed by industry leaders, Shared Data ensures seamless data integration and
          synchronization, eliminating the need for complex setup and configuration. Your data remains within your
          control, ensuring optimal performance, scalability, and governance.
        </p>
        <div className="mt-1 font-semibold">Features of Shared Data:</div>
        <ul className="list-disc list-inside">
          <li className="ml-2">
            <span className="font-semibold">Data Synchronization: </span> Shared Data provides native support for
            synchronizing data across different platforms and environments. This includes data replication, conflict
            resolution, and lifecycle management without needing to export data to external data integration platforms.
          </li>
          <li className="ml-2">
            <span className="font-semibold">Query Support: </span> Given a query, Shared Data returns the corresponding
            data from the shared data pool.
          </li>
          <li className="ml-2">
            <span className="font-semibold">Data Update: </span> Shared Data allows for updating data in the shared data
            pool.
          </li>
          <li className="ml-2">
            <span className="font-semibold">Data Deletion: </span> Shared Data allows for deleting data from the shared
            data pool.
          </li>
        </ul>
        <div className="mt-1 font-semibold">When to Use Shared Data:</div>
        <ul className="list-disc list-inside">
          <li className="ml-2">
            <span className="font-semibold">Data Sharing Needs: </span> Use Shared Data when you need to share data
            across different platforms and environments, ensuring data consistency and integrity.
          </li>
          <li className="ml-2">
            <span className="font-semibold">Data Integration Requirements: </span> If your data processing or analytics
            work requires data integration across different platforms, Shared Data provides a seamless solution.
          </li>
          <li className="ml-2">
            <span className="font-semibold">Data Governance: </span> Shared Data adheres to stringent data governance
            models, ensuring that data is protected and access is controlled.
          </li>
          <li className="ml-2">
            <span className="font-semibold">Scalability Needs: </span> For applications requiring scalability and
            performance, Shared Data ensures that your data operations scale seamlessly without compromising on
            performance.
          </li>
        </ul>
        <p className="mt-1">
          Overall, Shared Data is particularly useful for organizations that need to share and synchronize data across
          different platforms and environments. It helps in maintaining a secure, scalable, and efficient data sharing
          environment by leveraging robust data integration and synchronization capabilities.
        </p>
      </div>
    ),
  },
  {
    name: 'Notebook',
    description: (
      <div>
        <p>
          Notebook provides an interactive, web-based platform for data analysis and visualization. Developed with the
          needs of data scientists in mind, Notebook supports multiple programming languages and offers a wide range of
          tools for data manipulation and display.
        </p>
        <div className="mt-1 font-semibold">Features of Notebook:</div>
        <ul className="list-disc list-inside">
          <li className="ml-2">
            <span className="font-semibold">Interactive Programming: </span> Notebook supports interactive programming,
            allowing users to write and execute code in a variety of languages and see the results in real-time.
          </li>
          <li className="ml-2">
            <span className="font-semibold">Data Visualization: </span> Notebook includes built-in support for
            visualizing data, making it easy to create charts, graphs, and other visual representations of data.
          </li>
          <li className="ml-2">
            <span className="font-semibold">Collaboration: </span> Notebook allows for real-time collaboration, making
            it easy for teams to work together on data analysis tasks.
          </li>
          <li className="ml-2">
            <span className="font-semibold">Integration: </span> Notebook can be integrated with a variety of data
            sources, making it easy to import and manipulate data.
          </li>
        </ul>
        <div className="mt-1 font-semibold">When to Use Notebook:</div>
        <ul className="list-disc list-inside">
          <li className="ml-2">
            <span className="font-semibold">Data Analysis: </span> Use Notebook when you need to perform complex data
            analysis tasks, such as statistical analysis or machine learning.
          </li>
          <li className="ml-2">
            <span className="font-semibold">Data Visualization: </span> If your work involves creating visual
            representations of data, Notebook provides a range of tools to make this task easier.
          </li>
          <li className="ml-2">
            <span className="font-semibold">Collaborative Work: </span> Notebook is a great choice for teams that need
            to collaborate on data analysis tasks, as it allows for real-time collaboration and sharing of notebooks.
          </li>
          <li className="ml-2">
            <span className="font-semibold">Interactive Programming: </span> For tasks that require interactive
            programming, Notebook is an excellent choice, as it allows for real-time execution and display of code.
          </li>
        </ul>
        <p className="mt-1">
          Overall, Notebook is a powerful tool for data analysis and visualization. It provides a range of features that
          make it easy to manipulate and display data, and its support for interactive programming and collaboration
          makes it a great choice for teams.
        </p>
      </div>
    ),
  },
];

export interface NativeAppDefinitionStepProps {
  onBack: () => void;
  onContinue: () => void;
}

export default function NativeAppDefinitionStep(props: NativeAppDefinitionStepProps): JSX.Element {
  const dispatch = useAppDispatch();
  const nativeAppName = useAppSelector(selectNativeAppName);
  const selectedModules = useAppSelector(selectSelectedModules);

  const group = useAppSelector(selectSelectedGroup);
  const newProjectName = useAppSelector(selectNewProjectName);
  const projectsCreatedInCurrentSession = useAppSelector(selectProjectsCreatedInCurrentSession);

  const [activeModuleIndex, setActiveModuleIndex] = useState<number>(0);

  const [loading, setLoading] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [errorMessage] = useState<string>('Failed to create project');

  const formRef = useRef<HTMLFormElement>(null);

  const [nativeAppCreateProject] = useNativeAppCreateProjectMutation();

  useEffect(() => {
    dispatch(setNewProjectName(sanitizeProjectName(nativeAppName)));
  }, [nativeAppName]);

  const addModule = (type: IModuleType): void => {
    const countOfType = selectedModules.filter((module) => module.type === type).length;
    dispatch(
      setSelectedModules([
        ...selectedModules,
        {
          type,
          name: `${nativeAppName.replaceAll(' ', '_').toLowerCase()}.${(type as string)
            .replaceAll(' ', '_')
            .toLowerCase()}_${countOfType + 1}`,
        },
      ]),
    );
  };

  const removeModule = (moduleName: string): void => {
    dispatch(setSelectedModules(selectedModules.filter((module) => module.name !== moduleName)));
  };

  // const removeModule = (moduleIdx: number): void => {
  //   dispatch(setSelectedModules(selectedModules.filter((_, index) => index !== moduleIdx)));
  // };

  const changeModuleName = (type: IModuleType, moduleIdx: number, name: string): void => {
    const tmpSelectedModules = [...selectedModules];
    let indexWithinType = 0;
    for (let i = 0; i < tmpSelectedModules.length; i++) {
      if (tmpSelectedModules[i].type !== type) {
        continue;
      }

      if (indexWithinType === moduleIdx) {
        console.log(tmpSelectedModules[i]);
        tmpSelectedModules[i] = {
          type,
          name,
        };
        break;
      }

      indexWithinType++;
    }

    dispatch(setSelectedModules(tmpSelectedModules));
  };

  const onContinue = (): void => {
    if (formRef.current?.reportValidity() === true) {
      const projectName = `${group.path}/${newProjectName}`;

      const projectAlredyCreatedInThisSession = projectsCreatedInCurrentSession.includes(projectName);
      if (projectAlredyCreatedInThisSession) {
        props.onContinue();
        return;
      }

      setLoading(true);
      setFailed(false);
      nativeAppCreateProject({
        projectName,
      })
        .unwrap()
        .then((res: any) => {
          dispatch(setProjectsCreatedInCurrentSession([...projectsCreatedInCurrentSession, projectName]));
          props.onContinue();
        })
        .catch((err) => {
          console.log(err);
          setFailed(true);
          // setErrorMessage(err.data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const getLogo = (moduleType: IModuleType): string => {
    switch (moduleType) {
      case 'Python UDF':
        return '/static/python-udf-logo.png';
      case 'Streamlit':
        return '/static/streamlit-logo.png';
      case 'SQL UDF':
        return '/static/sql-udf-logo.png';
      case 'Snowpark Container Services':
        return '/static/spcs-logo.png';
      case 'Snowflake AI':
        return '/static/snowflake-ai-logo.png';
      case 'Cortex AI':
        return '/static/snowflake-cortex-logo.png';
      case 'Cortex AI Search':
        return '/static/snowflake-cortex-logo.png';
      case 'Shared Data':
        return '/static/snowflake-sharing-logo.png';
      case 'Notebook':
        return '/static/notebook-logo.png';
      default:
        return '/static/snowflake-cortex-logo.png';
    }
  };

  return (
    <StepWrapper
      title="Native app definition"
      subtitle="Define the native app properties"
      onBack={() => props.onBack()}
      onContinue={() => onContinue()}
    >
      <div className="h-full">
        <form ref={formRef}>
          <div className="mx-[auto] w-3/4 pb-2 grid grid-cols-2 gap-6">
            <div className="col-span-2">
              <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                Name
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300">
                  <input
                    type="text"
                    value={nativeAppName}
                    onChange={(e) => dispatch(setNativeAppName(e.target.value))}
                    name="name"
                    id="name"
                    autoComplete="name"
                    className="block flex-1 border-0 bg-transparent p-1.5 text-black placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder="Advertising Spend Analysis"
                    required
                  />
                </div>
              </div>
            </div>
            <GroupAndProjectSelector showCreateNewProject={false} />
          </div>
        </form>

        <div className="w-4/5 mx-[auto] flex">
          <div className="w-1/2 flex flex-col items-center">
            <div className="mx-[auto] relative w-full mb-3 mt-3">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-400" />
              </div>
              <div className="relative flex justify-center">
                <span className="bg-white px-2 text-sm text-gray-600">Modules</span>
              </div>
            </div>
            <div className="w-full bg-gray-50 p-2">
              {modules.map((module, moduleIndex) => (
                <div
                  key={moduleIndex}
                  className={classNames(
                    'w-full max-w-4xl relative flex flex-col items-center p-2 mb-1 rounded-md text-gray-700 bg-white cursor-pointer',
                    activeModuleIndex === moduleIndex
                      ? 'border-2 border-dataops-secondary-blue'
                      : 'border border-gray-300',
                  )}
                  onClick={() => setActiveModuleIndex(moduleIndex)}
                >
                  <div className="w-full flex items-center">
                    <img
                      className="h-10 w-10 object-contain p-1 rounded-full overflow-visible"
                      src={getLogo(module.name as IModuleType)}
                    />
                    <div className="grow truncate ml-1 mr-2 text-gray-800 text-base font-medium">{module.name}</div>
                    <button
                      type="button"
                      onClick={() => addModule(module.name as IModuleType)}
                      className="ml-2 rounded-full bg-dataops-secondary-blue px-2.5 py-1 text-xs font-semibold text-white shadow-sm hover:bg-hover-secondary-blue"
                    >
                      Add
                    </button>
                  </div>
                  <ul className="w-full space-y-1">
                    {selectedModules
                      .filter((selectedModule) => selectedModule.type === module.name)
                      .map((selectedModule, selectedModuleIndex) => (
                        <li key={selectedModuleIndex} className="flex ml-3">
                          <input
                            type="text"
                            value={selectedModule.name}
                            onChange={(e) => changeModuleName(selectedModule.type, selectedModuleIndex, e.target.value)}
                            className="block rounded truncate mr-2 px-1 flex-1 border-[1px] border-transparent bg-transparent text-sm  text-gray-600 placeholder:text-gray-400 outline-0 focus-visible:border-gray-400 hover:border-gray-200"
                          />
                          {/* <div className="truncate text-sm mr-2 text-gray-600">{selectedModule.name}</div> */}
                          <button
                            type="button"
                            onClick={() => removeModule(selectedModule.name)}
                            className="ml-[auto] rounded-full bg-dataops-red p-1 text-white shadow-sm"
                          >
                            <TrashIcon className="h-4 w-4" aria-hidden="true" />
                          </button>
                        </li>
                      ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>

          {/* Description of selected module */}
          <div className="w-1/2 mx-[auto] flex flex-col items-center">
            <div className="mx-[auto] relative w-full mb-3 mt-3">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-400" />
              </div>
              <div className="relative flex justify-center">
                <span className="bg-white px-2 text-sm text-gray-600">{modules[activeModuleIndex].name}</span>
              </div>
            </div>
            <div className="w-full p-2 bg-gray-50">
              <div className="border border-gray-300 p-2 rounded-md text-sm bg-white">
                {modules[activeModuleIndex].description}
              </div>
            </div>
          </div>

          {/* Module selector */}
          {/* <div className="w-1/2 flex flex-col items-center">
            <div className="mx-[auto] relative w-full mb-3 mt-3">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-400" />
              </div>
              <div className="relative flex justify-center">
                <span className="bg-white px-2 text-sm text-gray-600">Modules</span>
              </div>
            </div>
            {modules.map((module) => (
              <Disclosure key={module.name}>
                {({ open }) => (
                  <>
                    <div className="w-full max-w-4xl relative flex items-center p-2 pr-6 mb-1 border border-gray-300 rounded-md text-gray-700 bg-gray-50 cursor-default">
                      <div className="truncate mr-2 text-gray-800 text-base">{module.name}</div>
                      <button
                        type="button"
                        onClick={() => addModule(module.name as IModuleType)}
                        className="ml-2 rounded-full bg-dataops-secondary-blue px-2.5 py-1 text-xs font-semibold text-white shadow-sm hover:bg-hover-secondary-blue"
                      >
                        Add
                      </button>
                      <Disclosure.Button className="absolute right-1 w-5 cursor-pointer">
                        <ChevronRightIcon
                          className={classNames(
                            open ? 'transform rotate-90' : '',
                            'w-5 h-5 ml-auto text-gray-700 transition duration-150 ease-in-out',
                          )}
                        />
                      </Disclosure.Button>
                    </div>

                    <Transition
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacitsy-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                      className="w-full"
                    >
                      <Disclosure.Panel className="p-1.5 rounded-sm border border-gray-300 mb-1">
                        <div className="text-sm text-gray-600 text-left">{module.description}</div>
                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>
            ))}
          </div> */}

          {/* Preview of selected modules */}
          {/* <div className="w-1/2 mx-[auto] flex flex-col items-center">
            <div className="mx-[auto] relative w-full mb-3 mt-3">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-400" />
              </div>
              <div className="relative flex justify-center">
                <span className="bg-white px-2 text-sm text-gray-600">Selected</span>
              </div>
            </div>

            {selectedModules.map((module: IModule, moduleIdx) => (
              <div
                key={`${moduleIdx}`}
                className="w-full max-w-4xl relative p-2 mb-1 border border-gray-300 rounded-md text-gray-700 bg-gray-50 cursor-default space-y-2"
              >
                <div className="flex items-center">
                  <div className="truncate mr-2 text-gray-800 text-base">{module.type}</div>
                  <button
                    type="button"
                    onClick={() => removeModule(moduleIdx)}
                    className="ml-2 rounded-full bg-red-500 px-2.5 py-1 text-xs font-semibold text-white shadow-sm hover:bg-red-600"
                  >
                    Remove
                  </button>
                </div>
                <div className="flex shadow-sm w-full">
                  <span className="inline-flex items-center border border-r-0 rounded-l-md border-gray-300 bg-gray-50 px-2 text-gray-500 text-xs">
                    Name
                  </span>
                  <input
                    required
                    type="text"
                    value={module.name}
                    onChange={(e) => changeModuleName(moduleIdx, e.target.value)}
                    className="relative truncate grow block appearance-none border border-gray-300 rounded-r-md px-3 py-1 text-gray-900 placeholder-gray-500 text-xs"
                  />
                </div>
              </div>
            ))}
            {selectedModules.length === 0 && (
              <div className="text-sm text-gray-500 text-center">No modules selected</div>
            )}
            </div> */}
        </div>

        <LoadingAndErrorSection isLoading={loading} isFailed={failed} errorMessage={errorMessage} />
      </div>
    </StepWrapper>
  );
}
