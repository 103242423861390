import React, { useState, useEffect } from 'react';
import { useGetModelsFileContentQuery, useSetModelsFileContentMutation } from 'app/createApi';
import { useAppSelector } from 'app/hooks';
import { selectProjectPath, selectBranch } from 'app/sharedSlice';
import { selectName } from '../reducers/astraZenecaSlice';
import { cloneDeep } from 'lodash';
import StepWrapper from 'components/StepWrapper';
import TestEditor from 'features/astra-zeneca-flow/components/TestEditor';
import AddTestDialog from 'features/astra-zeneca-flow/components/AddTestDialog';
import LoadingAndErrorSection from 'components/LoadingAndErrorSection';
import type {
  IModelsFileContent,
  ITest,
  IDbtUtilsUniqueCombinationOfColumnsTest,
  IModelAndColumn,
} from '../types/testEditorTypes';

export interface DatabaseTablesStepProps {
  onBack: () => void;
  onContinue: () => void;
}

export default function TestEditorStep(props: DatabaseTablesStepProps): JSX.Element {
  const [processedModelsFileContent, setProcessedModelsFileContent] = useState<IModelsFileContent>();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedModelsAndColumns, setSelectedModelsAndColumns] = useState<IModelAndColumn[]>([]);
  const [existingTest, setExistingTest] = useState<ITest | undefined>();

  const [loading, setLoading] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [errorMessage] = useState<string>(
    'Oops! Failed to create tests. Please try again or contact support@dataops.live if the issue persists.',
  );

  const projectPath = useAppSelector(selectProjectPath);
  const branch = useAppSelector(selectBranch);
  const dataProductName = useAppSelector(selectName);

  const { data: modelsFileContent, isLoading } = useGetModelsFileContentQuery({
    projectPath,
    branch,
    dataProductName,
  });

  const [setModelsFileContent] = useSetModelsFileContentMutation();

  useEffect(() => {
    const tmpModelsFileContent = cloneDeep(modelsFileContent);

    for (const model of tmpModelsFileContent?.models ?? []) {
      for (const uniqueCombinationTest of model.tests ?? []) {
        for (const uniqueCombinationTestColumn of uniqueCombinationTest['dbt_utils.unique_combination_of_columns']
          .combination_of_columns) {
          for (const column of model.columns) {
            if (column.name === uniqueCombinationTestColumn) {
              column.tests.push(uniqueCombinationTest);
            }
          }
        }
      }
    }

    setProcessedModelsFileContent(tmpModelsFileContent);
  }, [modelsFileContent]);

  console.log('models: ', modelsFileContent);

  const onAddTest = (newTest: ITest, existingTest: ITest | undefined): string => {
    if (processedModelsFileContent === undefined) {
      return 'No models file found';
    }

    let updatedModelsFileContent = cloneDeep(processedModelsFileContent);

    if (existingTest !== undefined) {
      for (const selectedModelAndColum of selectedModelsAndColumns) {
        updatedModelsFileContent =
          removeTest(selectedModelAndColum.modelName, selectedModelAndColum.columnName, existingTest) ??
          updatedModelsFileContent;
      }
    }

    if ((newTest as IDbtUtilsUniqueCombinationOfColumnsTest)['dbt_utils.unique_combination_of_columns'] === undefined) {
      for (const selectedModelAndColum of selectedModelsAndColumns) {
        for (const model of updatedModelsFileContent.models) {
          if (model.name === selectedModelAndColum.modelName) {
            for (const column of model.columns) {
              if (column.name === selectedModelAndColum.columnName) {
                if (column.tests === undefined) {
                  column.tests = [];
                }
                column.tests.push(newTest);
              }
            }
          }
        }
      }
    } else {
      for (const selectedModelAndColum of selectedModelsAndColumns) {
        for (const model of updatedModelsFileContent.models) {
          if (model.name === selectedModelAndColum.modelName) {
            for (const uniqueCombinationTestColumn of (newTest as IDbtUtilsUniqueCombinationOfColumnsTest)[
              'dbt_utils.unique_combination_of_columns'
            ].combination_of_columns) {
              for (const column of model.columns) {
                if (column.name === uniqueCombinationTestColumn) {
                  if (column.tests === undefined) {
                    column.tests = [];
                  }
                  column.tests.push(newTest);
                }
              }
            }

            if (model.tests === undefined) {
              model.tests = [];
            }

            model.tests.push(newTest as IDbtUtilsUniqueCombinationOfColumnsTest);
          }
        }
      }
    }

    setProcessedModelsFileContent(updatedModelsFileContent);

    return 'success';
  };

  const onRemoveTest = (modelName: string, columnName: string, test: ITest): void => {
    const updatedModelsFileContent = removeTest(modelName, columnName, test);
    setProcessedModelsFileContent(updatedModelsFileContent);
  };

  const removeTest = (modelName: string, columnName: string, test: ITest): IModelsFileContent | undefined => {
    if (processedModelsFileContent === undefined) {
      return processedModelsFileContent;
    }

    const updatedModelsFileContent = cloneDeep(processedModelsFileContent);

    if ((test as IDbtUtilsUniqueCombinationOfColumnsTest)['dbt_utils.unique_combination_of_columns'] === undefined) {
      for (const model of updatedModelsFileContent.models) {
        if (model.name === modelName) {
          for (const column of model.columns) {
            if (column.name === columnName) {
              const index = column.tests.findIndex((t) => JSON.stringify(t) === JSON.stringify(test));
              if (index > -1) {
                column.tests.splice(index, 1);
              }
            }
          }
        }
      }
    } else {
      for (const model of updatedModelsFileContent.models) {
        if (model.name === modelName) {
          for (const uniqueCombinationTestColumn of (test as IDbtUtilsUniqueCombinationOfColumnsTest)[
            'dbt_utils.unique_combination_of_columns'
          ].combination_of_columns) {
            for (const column of model.columns) {
              if (column.name === uniqueCombinationTestColumn) {
                const index = column.tests.findIndex((t) => JSON.stringify(t) === JSON.stringify(test));
                if (index > -1) {
                  column.tests.splice(index, 1);
                }
              }
            }
          }

          const index = model.tests.findIndex((t) => JSON.stringify(t) === JSON.stringify(test));
          if (index > -1) {
            model.tests.splice(index, 1);
          }
        }
      }
    }

    return updatedModelsFileContent;
  };

  const onContinue = (): void => {
    if (processedModelsFileContent === undefined || processedModelsFileContent.models === undefined) {
      props.onContinue();
    }

    const preparedModelsFileContent: IModelsFileContent = cloneDeep(processedModelsFileContent as IModelsFileContent);
    for (const model of preparedModelsFileContent.models) {
      for (const column of model.columns) {
        if (column.tests === undefined) {
          continue;
        }
        column.tests = column.tests.filter(
          (test) =>
            (test as IDbtUtilsUniqueCombinationOfColumnsTest)['dbt_utils.unique_combination_of_columns'] === undefined,
        );
      }
    }

    setLoading(true);
    setFailed(false);

    setModelsFileContent({
      projectPath,
      branch,
      dataProductName,
      modelsFileContent: preparedModelsFileContent,
    })
      .unwrap()
      .then(() => {
        props.onContinue();
        setFailed(false);
      })
      .catch((err) => {
        console.log(err);
        setFailed(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <StepWrapper
      title="Test Editor"
      subtitle="Add tests to your models"
      onBack={() => props.onBack()}
      onContinue={() => onContinue()}
      isLoading={loading}
    >
      <div className="w-full max-w-[40rem] mx-[auto] flex flex-col items-center">
        <TestEditor
          modelsFileContent={processedModelsFileContent}
          openDialog={setIsDialogOpen}
          selectedModelsAndColumns={selectedModelsAndColumns}
          setSelectedModelsAndColumns={setSelectedModelsAndColumns}
          setExistingTest={setExistingTest}
          onRemoveTest={onRemoveTest}
          isLoading={isLoading}
        />
        <AddTestDialog
          modelsFileContent={processedModelsFileContent}
          open={isDialogOpen}
          setOpen={setIsDialogOpen}
          selectedModelsAndColumns={selectedModelsAndColumns}
          setSelectedModelsAndColumns={setSelectedModelsAndColumns}
          existingTest={existingTest}
          setExistingTest={setExistingTest}
          onAddTest={onAddTest}
        />
        <LoadingAndErrorSection isLoading={loading} isFailed={failed} errorMessage={errorMessage} hideLoading={true} />
      </div>
    </StepWrapper>
  );
}
