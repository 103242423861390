import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetProjectQuery,
  useGetBranchesQuery,
  useGetLatestPipelineQuery,
  useLazyGetLatestPipelineQuery,
  useGetMergeRequestsQuery,
  useLazyGetMergeRequestsApprovalsQuery,
} from 'app/platformApi';
import { ArrowLeftIcon, ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import type { IExtendedPlatformBranch, IExtendedPlatformMergeRequest, IPipelineStatus } from 'utils/platformApiTypes';
import MergeRequestItem from '../components/MergeRequestItem';
import PipelineStatusBadge from '../components/PipelineStatusBadge';
import CreateMRDialog from '../components/CreateMRDialog';
import CommentOnMRDialog from '../components/CommentOnMRDialog';
import { formatDate } from 'utils/formatUtils';

export default function ProjectStatusView(props: {}): JSX.Element {
  const navigate = useNavigate();
  const { projectId = '' } = useParams<{ projectId: string }>();
  const [extendedBranches, setExtendedBranches] = useState<IExtendedPlatformBranch[]>([]);
  const [extendedMergeRequests, setExtendedMergeRequests] = useState<IExtendedPlatformMergeRequest[]>([]);
  const [isCreateMRDialogOpen, setIsCreateMRDialogOpen] = useState(false);
  const [isCommentOnMRDialogOpen, setIsCommentOnMRDialogOpen] = useState(false);
  const [selectedBranchName, setSelectedBranchName] = useState('');
  const [selectedMergeRequestId, setSelectedMergeRequestId] = useState<number>(0);

  const { data: project, isLoading: isLoadingProject } = useGetProjectQuery({ projectId });
  const { data: branches, isLoading: isLoadingBranches } = useGetBranchesQuery({ projectId });
  const { data: mergeRequests, isLoading: isLoadingMRs } = useGetMergeRequestsQuery({ projectId });
  const { data: pipelines, isLoading: isLoadingPipelines } = useGetLatestPipelineQuery({
    projectId,
    branch: 'sit',
    numPipelines: 5,
  });
  const [getLatestPipeline] = useLazyGetLatestPipelineQuery();
  const [getMergeRequestsApprovals] = useLazyGetMergeRequestsApprovalsQuery();

  useEffect(() => {
    const fetchPipelineStatus = async (): Promise<void> => {
      if (branches !== undefined) {
        const updatedBranches = await Promise.all(
          branches.map(async (branch) => {
            try {
              const response = await getLatestPipeline({ projectId, branch: branch.name, numPipelines: 1 }).unwrap();
              if (response.length === 0) {
                return {
                  ...branch,
                  pipelineStatus: 'no-pipeline' as IPipelineStatus,
                };
              }
              return {
                ...branch,
                pipelineStatus: response[0].status,
                pipelineWebUrl: response[0].web_url,
              };
            } catch (error) {
              console.error(error);
              return {
                ...branch,
                pipelineStatus: 'no-pipeline' as IPipelineStatus,
              };
            }
          }),
        );
        setExtendedBranches(updatedBranches);
      }
    };

    fetchPipelineStatus().catch(console.error);
  }, [branches, projectId]);

  useEffect(() => {
    const fetchApprovals = async (): Promise<void> => {
      if (mergeRequests !== undefined) {
        const updatedMergeRequests = await Promise.all(
          mergeRequests.map(async (mergeRequest) => {
            try {
              const response = await getMergeRequestsApprovals({
                projectId,
                mergeRequestId: mergeRequest.iid,
              }).unwrap();
              return {
                ...mergeRequest,
                approval: response,
              };
            } catch (error) {
              console.error(error);
              return {
                ...mergeRequest,
              };
            }
          }),
        );
        setExtendedMergeRequests(updatedMergeRequests);
      }
    };

    fetchApprovals().catch(console.error);
  }, [mergeRequests, projectId]);

  return (
    <>
      <div className="w-full flex flex-col items-center pb-4">
        {!isLoadingProject && (
          <div className="w-full wide:w-3/4 max-w-[60rem] items-end flex justify-between">
            <div className="text-[1.8rem]">{project?.name}</div>
            <div className="text-sm text-gray-600">{project?.path_with_namespace}</div>
          </div>
        )}
        {isLoadingProject && (
          <div className="w-full wide:w-3/4 space-y-1">
            <div className="w-full h-[40px] py-1 flex">
              <div className="w-1/3 animate-pulse h-6 bg-slate-200 rounded mt-[6px]"></div>
            </div>
          </div>
        )}
        <button
          type="button"
          onClick={() => navigate('/astra-zeneca-monitoring')}
          className="absolute rounded-full hover:bg-gray-200 hover:text-gray-800 top-2 left-2 p-1.5 text-gray-700"
        >
          <ArrowLeftIcon aria-hidden="true" className="h-7 w-7" />
        </button>
        <div className="p-4 rounded-md bg-white w-full wide:w-3/4 max-w-[60rem] mt-6">
          <div className="text-left text-xl text-gray-800 text-semibold mb-2">Submit for Review</div>
          <ul role="list" className="w-full divide-y divide-gray-100">
            {!isLoadingBranches &&
              extendedBranches
                ?.filter((branch) => !branch.merged && !branch.protected)
                .map((branch) => (
                  <li key={branch.name} className="w-full grid grid-cols-6 gap-2">
                    <div className="col-span-4">
                      <a
                        href={`https://app.dataops.live/${
                          (project?.path_with_namespace as string) ?? ''
                        }/-/compare/sit...${branch.name}`}
                        target="_blank"
                        rel="noreferrer"
                        className="pt-2 flex items-center w-fit hover:underline"
                      >
                        <p className="text-sm font-semibold leading-6 text-gray-900 truncate">{branch.name}</p>
                        <ArrowTopRightOnSquareIcon className="w-4 min-w-4 pl-0.5" />
                      </a>
                      <div className="py-2 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                        <p>{branch.commit.author_name}</p>
                        <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                          <circle r={1} cx={1} cy={1} />
                        </svg>
                        <p>
                          <time dateTime="2024-10-10">{formatDate(branch.commit.created_at)}</time>
                        </p>
                      </div>
                    </div>
                    <div className="col-span-1 flex flex-col items-center justify-center">
                      <PipelineStatusBadge status={branch.pipelineStatus} url={branch.pipelineWebUrl} />
                      {branch.merged && (
                        <span className="inline-flex items-center rounded-md bg-green-50 px-1 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 cursor-default">
                          Merged
                        </span>
                      )}
                    </div>
                    <div className="col-span-1 flex items-center justify-center">
                      {/* <a
                        href={`https://app.dataops.live/${
                          (project?.path_with_namespace as string) ?? ''
                        }/-/compare/sit...${branch.name}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <button
                          type="button"
                          className="h-[30px] rounded-full bg-white px-2.5 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        >
                          Compare
                        </button>
                      </a> */}
                      {branch.pipelineStatus !== 'failed' &&
                        !extendedMergeRequests.some(
                          (mergeRequest) =>
                            mergeRequest.source_branch === branch.name &&
                            mergeRequest.state !== 'closed' &&
                            mergeRequest.state !== 'merged',
                        ) && (
                          <button
                            type="button"
                            onClick={() => {
                              setSelectedBranchName(branch.name);
                              setIsCreateMRDialogOpen(true);
                            }}
                            className="w-[9rem] h-[30px] rounded-full bg-white px-2.5 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 whitespace-nowrap disabled:bg-gray-50 disabled:text-gray-400"
                          >
                            Submit for Review
                          </button>
                        )}
                      {extendedMergeRequests.some((mergeRequest) => mergeRequest.source_branch === branch.name) && (
                        <span className="w-[9rem] h-[30px] rounded-full bg-white px-2.5 py-1 text-sm font-thin text-gray-500 whitespace-nowrap cursor-default">
                          Already submitted
                        </span>
                      )}
                      {!extendedMergeRequests.some((mergeRequest) => mergeRequest.source_branch === branch.name) &&
                        branch.pipelineStatus === 'failed' && (
                          <a href={branch.pipelineWebUrl} target="_blank" rel="noreferrer">
                            <button
                              type="button"
                              className="w-[9rem] h-[30px] rounded-full bg-white px-2.5 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 whitespace-nowrap disabled:bg-gray-50 disabled:text-gray-400"
                            >
                              Resolve issues
                            </button>
                          </a>
                        )}
                    </div>
                  </li>
                ))}
            {isLoadingBranches && (
              <div className="w-full space-y-1 mt-4">
                <div className="w-full h-[40px] py-1 flex">
                  <div className="w-1/2 animate-pulse h-4 bg-slate-200 rounded mt-[6px]"></div>
                  <div className="ml-[auto] w-1/4 animate-pulse h-4 bg-slate-200 rounded mt-[6px]"></div>
                </div>
                <div className="w-full h-[40px] py-1 flex">
                  <div className="w-1/2 animate-pulse h-4 bg-slate-200 rounded mt-[6px]"></div>
                  <div className="ml-[auto] w-1/4 animate-pulse h-4 bg-slate-200 rounded mt-[6px]"></div>
                </div>
              </div>
            )}
          </ul>
        </div>
        <div className="p-4 rounded-md bg-white w-full wide:w-3/4 max-w-[60rem] mt-4">
          <div className="text-left text-xl text-gray-800 text-semibold mb-2">Waiting for Approval</div>
          <ul role="list" className="w-full divide-y divide-gray-100">
            {!isLoadingMRs &&
              extendedMergeRequests
                ?.filter((mergeRequest) => mergeRequest.state !== 'closed')
                .map((mergeRequest: IExtendedPlatformMergeRequest) => (
                  <MergeRequestItem
                    key={mergeRequest.iid}
                    mergeRequest={mergeRequest}
                    extendedBranches={extendedBranches}
                    projectId={projectId}
                    setSelectedMergeRequestId={setSelectedMergeRequestId}
                    setIsCommentOnMRDialogOpen={setIsCommentOnMRDialogOpen}
                  />
                ))}
            {!isLoadingMRs && mergeRequests?.length === 0 && (
              <div className="text-sm text-gray-600">No merge requests</div>
            )}
            {isLoadingMRs && (
              <div className="w-full space-y-1 mt-4">
                <div className="w-full h-[40px] py-1 flex">
                  <div className="w-1/2 animate-pulse h-4 bg-slate-200 rounded mt-[6px]"></div>
                  <div className="ml-[auto] w-1/4 animate-pulse h-4 bg-slate-200 rounded mt-[6px]"></div>
                </div>
                <div className="w-full h-[40px] py-1 flex">
                  <div className="w-1/2 animate-pulse h-4 bg-slate-200 rounded mt-[6px]"></div>
                  <div className="ml-[auto] w-1/4 animate-pulse h-4 bg-slate-200 rounded mt-[6px]"></div>
                </div>
              </div>
            )}
          </ul>
        </div>
        <div className="p-4 rounded-md bg-white w-full wide:w-3/4 max-w-[60rem] mt-4">
          <div className="text-left text-xl text-gray-800 text-semibold mb-2">Latest updates in Production</div>
          <ul role="list" className="w-full divide-y divide-gray-100">
            {!isLoadingPipelines &&
              pipelines
                ?.filter((pipeline) => pipeline.status !== 'skipped')
                .map((pipeline) => (
                  <li key={pipeline.id} className="w-full grid grid-cols-6 gap-2 py-2">
                    <div className="col-span-2">
                      <a
                        href={pipeline.web_url}
                        target="_blank"
                        rel="noreferrer"
                        className="flex items-center w-fit hover:underline"
                      >
                        <p className="text-sm font-semibold leading-6 text-gray-900">{pipeline.name ?? 'Pipeline'}</p>
                        <ArrowTopRightOnSquareIcon className="w-4 min-w-4 pl-0.5" />
                      </a>
                    </div>
                    <p className="col-span-1 text-gray-600 text-sm">
                      <time dateTime="2024-10-10">{formatDate(pipeline.updated_at)}</time>
                    </p>
                    <div className="col-span-1 text-gray-800 text-sm text-center">{pipeline.ref}</div>
                    <div className="col-span-1 flex items-center justify-center">
                      <PipelineStatusBadge status={pipeline.status} url={pipeline.web_url} />
                    </div>
                    <div className="col-span-1 text-gray-800 text-sm text-center"></div>
                  </li>
                ))}
          </ul>
          {!isLoadingPipelines && pipelines?.length === 0 && <div className="text-sm text-gray-600">No pipelines</div>}
          {isLoadingPipelines && (
            <div className="w-full space-y-1 mt-4">
              <div className="w-full h-[40px] py-1 flex">
                <div className="w-1/2 animate-pulse h-4 bg-slate-200 rounded mt-[6px]"></div>
                <div className="ml-[auto] w-1/4 animate-pulse h-4 bg-slate-200 rounded mt-[6px]"></div>
              </div>
              <div className="w-full h-[40px] py-1 flex">
                <div className="w-1/2 animate-pulse h-4 bg-slate-200 rounded mt-[6px]"></div>
                <div className="ml-[auto] w-1/4 animate-pulse h-4 bg-slate-200 rounded mt-[6px]"></div>
              </div>
            </div>
          )}
        </div>
      </div>
      <CreateMRDialog
        open={isCreateMRDialogOpen}
        setOpen={setIsCreateMRDialogOpen}
        projectId={projectId}
        branchName={selectedBranchName}
      />
      <CommentOnMRDialog
        open={isCommentOnMRDialogOpen}
        setOpen={setIsCommentOnMRDialogOpen}
        projectId={projectId}
        mergeRequestId={selectedMergeRequestId}
      />
    </>
  );
}
