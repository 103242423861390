import React from 'react';

export default function LoadingView(): JSX.Element {
  return (
    <div className="h-full w-full flex flex-col items-center">
      <div
        className="animate-spin inline-block w-32 h-32 mt-24 border-[3px] border-current border-t-transparent text-cyan-800 rounded-full"
        role="status"
        aria-label="loading"
      >
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}
