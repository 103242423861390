import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { selectCurrentStep, setCurrentStep } from 'app/sharedSlice';
import { IStep } from 'utils/types';
import useAstraZenecaFlowSteps from 'features/astra-zeneca-flow/steps/astraZenecaFlowSteps';
import DataProductDefinitionStep from 'features/astra-zeneca-flow/steps/DataProductDefinitionStep';
import DataProductDefinition2Step from 'features/astra-zeneca-flow/steps/DataProductDefinition2Step';
import SnowflakeConnectionStep from 'features/astra-zeneca-flow/steps/SnowflakeConnectionStep';
import DatabaseTablesStep from 'features/astra-zeneca-flow/steps/DatabaseTablesStep';
import SqlModelBuilderStep from 'features/astra-zeneca-flow/steps/SqlModelBuilderStep';
import TestEditorStep from 'features/astra-zeneca-flow/steps/TestEditorStep';
import DataProductSummaryStep from 'features/astra-zeneca-flow/steps/DataProductSummaryStep';
import { sendMetrics, MetricType } from 'utils/metrics';
import { selectUser } from 'features/authentication/reducers/userSlice';

export default function AstraZenecaFlowView(): JSX.Element {
  const steps: IStep[] = useAstraZenecaFlowSteps();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentStep = useAppSelector(selectCurrentStep);
  const user = useAppSelector(selectUser);

  const setStepAndSendMetric = (step: number): void => {
    sendMetrics(MetricType.create_analytics, user, step.toString(), null);
    dispatch(setCurrentStep(step));
  };

  const getComponent = (name: string, index: number): React.ReactNode => {
    switch (name) {
      case 'Core Data Product attributes':
        return (
          <DataProductDefinitionStep
            onBack={index === 0 ? () => navigate('/') : () => setStepAndSendMetric(index - 1)}
            onContinue={() => setStepAndSendMetric(index + 1)}
          />
        );
      case 'Extended Data Product attributes':
        return (
          <DataProductDefinition2Step
            onBack={() => setStepAndSendMetric(index - 1)}
            onContinue={() => setStepAndSendMetric(index + 1)}
          />
        );
      case 'Snowflake connection':
        return (
          <SnowflakeConnectionStep
            onBack={() => setStepAndSendMetric(index - 1)}
            onContinue={() => setStepAndSendMetric(index + 1)}
          />
        );
      case 'Source selection':
        return (
          <DatabaseTablesStep
            onBack={() => setStepAndSendMetric(index - 1)}
            onContinue={() => setStepAndSendMetric(index + 1)}
          />
        );
      case 'SQL Model Builder':
        return (
          <SqlModelBuilderStep
            onBack={() => setStepAndSendMetric(index - 1)}
            onContinue={() => setStepAndSendMetric(index + 1)}
          />
        );
      case 'Test Editor':
        return (
          <TestEditorStep
            onBack={() => setStepAndSendMetric(index - 1)}
            onContinue={() => setStepAndSendMetric(index + 1)}
          />
        );
      case 'Data Product summary':
        return (
          <DataProductSummaryStep
            onBack={() => setStepAndSendMetric(index - 1)}
            onContinue={() => setStepAndSendMetric(index + 1)}
          />
        );
    }
  };

  return (
    <>
      {steps.map((step, index) => (
        <Fragment key={`${step.name}-${index}`}>{currentStep === index && getComponent(step.name, index)}</Fragment>
      ))}
    </>
  );
}
