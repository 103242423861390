import React from 'react';
import { Outlet } from 'react-router-dom';
import AppBar from 'components/AppBar';

export default function App(): JSX.Element {
  return (
    <div className="h-full">
      <div className="w-full fixed top-0 z-40">
        <AppBar />
      </div>

      <div className="h-full">
        <main className="min-w-0 h-full border-t border-gray-200 max-w-[120rem] mx-[auto]">
          <Outlet />
        </main>
      </div>
    </div>
  );
}
