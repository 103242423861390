import React, { useState } from 'react';
import { PlusIcon, TrashIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Switch } from '@headlessui/react';
import StepWrapper from 'components/StepWrapper';
import { classNames } from 'utils/styleUtils';

interface IPrerequisite {
  text: string;
  isMandatory: boolean;
}

export interface PrerequisitesStepProps {
  onBack: () => void;
  onContinue: () => void;
}

export default function PrerequisitesStep(props: PrerequisitesStepProps): JSX.Element {
  const [isMandatory, setIsMandatory] = useState<boolean>(true);
  const [prerequisites, setPrerequisites] = useState<IPrerequisite[]>([
    { text: 'I have Notebooks available in my Account', isMandatory: true },
    { text: 'I have Cortex Search enabled in my Account', isMandatory: false },
    { text: 'I have Document AI available in my Account', isMandatory: false },
  ]);

  const updatePrerequisite = (index: number, text: string): void => {
    const updatedPrerequisites = [...prerequisites];
    updatedPrerequisites[index].text = text;
    setPrerequisites(updatedPrerequisites);
  };

  return (
    <StepWrapper
      title="Prerequisites"
      subtitle="Prerequisites for your SSC Solution"
      onBack={() => props.onBack()}
      onContinue={() => props.onContinue()}
    >
      <div className="h-full w-3/4 mx-[auto]">
        <div className="w-full mb-2 flex items-start">
          <button
            type="button"
            className="ml-[auto] rounded-full bg-dataops-secondary-blue p-2 text-white shadow-sm hover:bg-secondary-blue-hover"
          >
            <PlusIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        <div>
          <ul role="list" className="divide-y divide-gray-300">
            {prerequisites.map((prerequitise, prerequisiteIdx) => (
              <li
                key={prerequisiteIdx}
                className={classNames('flex py-6 px-2', prerequisiteIdx % 2 === 0 ? '' : 'bg-gray-50')}
              >
                <div className="flex-auto">
                  <div className="flex items-center justify-between gap-x-4">
                    <p className="text-lg font-medium leading-6 text-gray-900">#{prerequisiteIdx + 1}</p>
                    <Switch.Group as="div" className="col-span-2 flex items-center justify-center">
                      <Switch
                        checked={true}
                        onChange={() => setIsMandatory(!isMandatory)}
                        className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none"
                      >
                        <span className="sr-only">Use setting</span>
                        <span
                          aria-hidden="true"
                          className="pointer-events-none absolute h-full w-full rounded-md bg-white"
                        />
                        <span
                          aria-hidden="true"
                          className={classNames(
                            prerequitise.isMandatory ? 'bg-dataops-primary-blue' : 'bg-gray-200',
                            'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out',
                          )}
                        />
                        <span
                          aria-hidden="true"
                          className={classNames(
                            prerequitise.isMandatory ? 'translate-x-5' : 'translate-x-0',
                            'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out',
                          )}
                        />
                      </Switch>
                      <Switch.Label as="span" className="ml-1 cursor-default">
                        <span className="text-sm font-medium text-gray-700">Mandatory</span>
                      </Switch.Label>
                    </Switch.Group>
                    <div className="flex items-center justify-center">
                      <button type="button" className="ml-[auto] rounded-full bg-dataops-red p-1 text-white shadow-sm">
                        <TrashIcon className="h-4 w-4" aria-hidden="true" />
                      </button>
                      <button type="button" className="ml-[auto] rounded-full p-1 text-gray-700 shadow-sm">
                        <ChevronUpDownIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <textarea
                    value={prerequitise.text}
                    onChange={(e) => updatePrerequisite(prerequisiteIdx, e.target.value)}
                    rows={2}
                    className="block w-full rounded-md border-0 p-1.5 text-sm text-black placeholder:text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300"
                    required
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </StepWrapper>
  );
}
