import React from 'react';
import type {
  ITest,
  IAcceptedValuesTest,
  IRelationshipsTest,
  IDbtUtilsUniqueCombinationOfColumnsTest,
} from '../types/testEditorTypes';
import { classNames } from 'utils/styleUtils';

interface TestEditorTestBadgeProps {
  test: ITest;
  onRemoveTest: () => void;
  onEditTest: () => void;
}

export default function TestEditorTestBadge(props: TestEditorTestBadgeProps): JSX.Element {
  return (
    <span
      className={classNames(
        'inline-flex items-center gap-x-0.5 rounded-md px-2 py-1 text-xs font-medium',
        typeof props.test === 'string' && props.test === 'unique'
          ? 'bg-blue-100 text-blue-700'
          : (props.test as IAcceptedValuesTest).accepted_values !== undefined
          ? 'bg-indigo-100 text-indigo-700'
          : (props.test as IRelationshipsTest).relationships !== undefined
          ? 'bg-gray-100 text-gray-700'
          : (props.test as IDbtUtilsUniqueCombinationOfColumnsTest)['dbt_utils.unique_combination_of_columns'] !==
            undefined
          ? 'bg-blue-100 text-blue-700'
          : 'bg-purple-100 text-purple-700',
      )}
    >
      <div
        className={classNames(
          (props.test as IAcceptedValuesTest).accepted_values !== undefined ||
            (props.test as IRelationshipsTest).relationships !== undefined
            ? 'cursor-pointer'
            : '',
        )}
        onClick={(e) => {
          e.stopPropagation();
          if (
            (props.test as IAcceptedValuesTest).accepted_values !== undefined ||
            (props.test as IRelationshipsTest).relationships !== undefined
          ) {
            props.onEditTest();
          }
        }}
      >
        {typeof props.test === 'string' && <>{props.test}</>}
        {(props.test as IAcceptedValuesTest).accepted_values !== undefined && <>accepted_values</>}
        {(props.test as IRelationshipsTest).relationships !== undefined && <>relationships</>}
        {(props.test as IDbtUtilsUniqueCombinationOfColumnsTest)['dbt_utils.unique_combination_of_columns'] !==
          undefined && <>combination</>}
      </div>
      <button
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          props.onRemoveTest();
        }}
        className={classNames(
          'group relative -mr-1 h-3.5 w-3.5 rounded-sm',
          typeof props.test === 'string' && props.test === 'unique'
            ? 'hover:bg-blue-600/20'
            : (props.test as IAcceptedValuesTest).accepted_values !== undefined
            ? 'hover:bg-indigo-600/20'
            : (props.test as IRelationshipsTest).relationships !== undefined
            ? 'hover:bg-gray-600/20'
            : (props.test as IDbtUtilsUniqueCombinationOfColumnsTest)['dbt_utils.unique_combination_of_columns'] !==
              undefined
            ? 'hover:bg-blue-600/20'
            : 'hover:bg-purple-600/20',
        )}
      >
        <span className="sr-only">Remove</span>
        <svg
          viewBox="0 0 14 14"
          className={classNames(
            'h-3.5 w-3.5',
            typeof props.test === 'string' && props.test === 'unique'
              ? 'stroke-blue-800/50 group-hover:stroke-blue-800/75'
              : (props.test as IAcceptedValuesTest).accepted_values !== undefined
              ? 'stroke-indigo-800/50 group-hover:stroke-indigo-800/75'
              : (props.test as IRelationshipsTest).relationships !== undefined
              ? 'stroke-gray-800/50 group-hover:stroke-gray-800/75'
              : (props.test as IDbtUtilsUniqueCombinationOfColumnsTest)['dbt_utils.unique_combination_of_columns'] !==
                undefined
              ? 'stroke-blue-800/50 group-hover:stroke-blue-800/75'
              : 'stroke-purple-800/50 group-hover:stroke-purple-800/75',
          )}
        >
          <path d="M4 4l6 6m0-6l-6 6" />
        </svg>
        <span className="absolute -inset-1" />
      </button>
    </span>
  );
}
