import React from 'react';
import axios from 'axios';
import { createRoot } from 'react-dom/client';
import App from './App';
import { ApolloProvider } from '@apollo/client';
import client from 'apolloClient';
import { AuthProvider } from './react-oauth2-code-pkce/AuthContext';
import type { TAuthConfig, TRefreshTokenExpiredEvent } from './react-oauth2-code-pkce';
import { getConfig } from 'config/config-helper';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './app/store';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import LoginView from 'views/LoginView';
import ChooseFlow from 'views/ChooseFlow';
import FlowView from 'views/FlowView';
import DataProductBuilderView from 'views/DataProductBuilderView';
import AstraZenecaFlowView from 'views/AstraZenecaFlowView';
import AstraZenecaMonitoringView from 'views/AstraZenecaMonitoringView';
import ProjectSearchView from 'features/monitoring/views/ProjectSearchView';
import ProjectStatusView from 'features/monitoring/views/ProjectStatusView';
import DbtMigrationView from 'views/DbtMigrationView';
import NativeAppBuilderView from 'views/NativeAppBuilderView';
import DataAppBuilderView from 'views/DataAppBuilderView';
import SnowflakeSolutionCentralView from 'views/SolutionCentralView';
import DataProductComplete from 'features/data-product-builder/steps/DataProductComplete';
import DbtFlowComplete from 'features/dbt-migration/steps/DbtFlowComplete';
import NativeAppComplete from 'features/native-app-builder/steps/NativeAppComplete';
import DataAppComplete from 'features/data-app-builder/steps/DataAppComplete';
import SscSolutionComplete from 'features/snowflake-solution-central/steps/SscSolutionComplete';
import AstraZenecaFlowComplete from 'features/astra-zeneca-flow/steps/AstraZenecaFlowComplete';
import ErrorView from 'views/ErrorView';
import NotFoundView from 'views/NotFoundView';
import PublicRoute from 'features/authentication/components/PublicRoute';
import ProtectedRoute from 'features/authentication/components/ProtectedRoute';
import OauthCallback from 'views/OauthCallback';
import useDataProductCreatorSteps from 'features/data-product-builder/steps/dataProductCreatorSteps';
import useAstraZenecaFlowSteps from 'features/astra-zeneca-flow/steps/astraZenecaFlowSteps';
import useDbtMigrationSteps from 'features/dbt-migration/steps/dbtMigrationSteps';
import useNativeAppCreatorSteps from 'features/native-app-builder/steps/nativeAppBuilderSteps';
import useDataAppCreatorSteps from 'features/data-app-builder/steps/dataAppBuilderSteps';
import useSnowflakeSolutionCentralSteps from 'features/snowflake-solution-central/steps/snowflakeSolutionCentralSteps';
import * as Sentry from '@sentry/react';

axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.xsrfCookieName = 'csrftoken';

const { dataopsOAuthClient } = getConfig();
const { authorizationEndpoint, tokenEndpoint, redirectUri, scope, decodeToken, redirectCallbackPath, autoLogin } =
  dataopsOAuthClient;

const authConfig: TAuthConfig = {
  clientId: dataopsOAuthClient.clientId,
  authorizationEndpoint,
  tokenEndpoint,
  redirectUri,
  scope,
  decodeToken,
  redirectCallbackPath,
  autoLogin,
  onRefreshTokenExpire: (event: TRefreshTokenExpiredEvent) => {
    window.confirm('Session expired. Refresh page to continue using the site?') && event.login();
  },
};

Sentry.init({
  dsn: 'https://3af788ce0fe5ee6f031d6a22aadbdb2f@o4505504716816384.ingest.sentry.io/4506574040006656',
  integrations: [
    new Sentry.BrowserTracing({}),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  sampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorView />,
    children: [
      {
        path: '/',
        element: (
          <ProtectedRoute>
            <ChooseFlow />
          </ProtectedRoute>
        ),
      },
      {
        path: '/data-product-creator',
        element: (
          <ProtectedRoute>
            <FlowView
              useSteps={useDataProductCreatorSteps}
              mainPanelComponent={<DataProductBuilderView />}
              completedComponent={<DataProductComplete />}
            />
          </ProtectedRoute>
        ),
      },
      {
        path: '/astra-zeneca-flow',
        element: (
          <ProtectedRoute>
            <FlowView
              useSteps={useAstraZenecaFlowSteps}
              mainPanelComponent={<AstraZenecaFlowView />}
              completedComponent={<AstraZenecaFlowComplete />}
            />
          </ProtectedRoute>
        ),
      },
      {
        path: '/astra-zeneca-monitoring',
        element: (
          <ProtectedRoute>
            <AstraZenecaMonitoringView />
          </ProtectedRoute>
        ),
        children: [
          {
            path: '/astra-zeneca-monitoring',
            element: <ProjectSearchView />,
          },
          {
            path: '/astra-zeneca-monitoring/status/:projectId',
            element: <ProjectStatusView />,
          },
        ],
      },
      {
        path: '/dbt-migration',
        element: (
          <ProtectedRoute>
            <FlowView
              useSteps={useDbtMigrationSteps}
              mainPanelComponent={<DbtMigrationView />}
              completedComponent={<DbtFlowComplete />}
            />
          </ProtectedRoute>
        ),
      },
      {
        path: '/native-app-builder',
        element: (
          <ProtectedRoute>
            <FlowView
              useSteps={useNativeAppCreatorSteps}
              mainPanelComponent={<NativeAppBuilderView />}
              completedComponent={<NativeAppComplete />}
            />
          </ProtectedRoute>
        ),
      },
      {
        path: '/data-app-builder',
        element: (
          <ProtectedRoute>
            <FlowView
              useSteps={useDataAppCreatorSteps}
              mainPanelComponent={<DataAppBuilderView />}
              completedComponent={<DataAppComplete />}
            />
          </ProtectedRoute>
        ),
      },
      {
        path: '/snowflake-solution-central',
        element: (
          <ProtectedRoute>
            <FlowView
              useSteps={useSnowflakeSolutionCentralSteps}
              mainPanelComponent={<SnowflakeSolutionCentralView />}
              completedComponent={<SscSolutionComplete />}
            />
          </ProtectedRoute>
        ),
      },
      {
        path: '/login',
        element: (
          <PublicRoute>
            <LoginView />
          </PublicRoute>
        ),
      },
      {
        path: '*',
        element: <NotFoundView />,
      },
      {
        path: '/dataops-oauth-callback',
        element: <OauthCallback />,
        errorElement: <ErrorView />,
      },
    ],
  },
]);

const container = document.getElementById('root');

if (container != null) {
  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <Sentry.ErrorBoundary fallback={ErrorView} showDialog>
        <AuthProvider authConfig={authConfig}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <ApolloProvider client={client}>
                <RouterProvider router={router} />
              </ApolloProvider>
            </PersistGate>
          </Provider>
        </AuthProvider>
      </Sentry.ErrorBoundary>
    </React.StrictMode>,
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
