import React from 'react';
import { BookmarkIcon, BookmarkSlashIcon } from '@heroicons/react/24/outline';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { selectPersistSnowflakeAccount, setPersistSnowflakeAccount } from 'app/snowflakeSlice';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { localPoint } from '@visx/event';

const tooltipStyles = {
  ...defaultStyles,
  backgroundColor: '#4b5563',
  color: 'white',
  fontSize: 12,
  zIndex: 30,
  maxWidth: 250,
};

interface TooltipData {
  description: string;
}

export default function PersistSnowflakeAccountButton(): JSX.Element {
  const dispatch = useAppDispatch();
  const persistSnowflakeAccount = useAppSelector(selectPersistSnowflakeAccount);

  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip<TooltipData>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  return (
    <>
      <div
        ref={containerRef}
        onMouseLeave={() => {
          hideTooltip();
        }}
        onMouseMove={(event) => {
          const eventSvgCoords = localPoint(event);
          showTooltip({
            tooltipData: {
              description: persistSnowflakeAccount
                ? 'The account will be persisted. Click to disable.'
                : 'The account will not be persisted. Click to persist.',
            },
            tooltipTop: eventSvgCoords?.y,
            tooltipLeft: eventSvgCoords?.x,
          });
        }}
      >
        {persistSnowflakeAccount ? (
          <BookmarkIcon
            onClick={() => dispatch(setPersistSnowflakeAccount(false))}
            className="w-5 h-5 text-dataops-secondary-blue hover:text-hover-secondary-blue cursor-pointer"
          />
        ) : (
          <BookmarkSlashIcon
            onClick={() => dispatch(setPersistSnowflakeAccount(true))}
            className="w-5 h-5 text-dataops-secondary-blue hover:text-hover-secondary-blue cursor-pointer"
          />
        )}
      </div>
      {tooltipOpen && tooltipData !== undefined && (
        <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
          <div className="text-sm">{tooltipData.description}</div>
        </TooltipInPortal>
      )}
    </>
  );
}
