import React from 'react';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { classNames } from 'utils/styleUtils';

const tooltipStyles = {
  ...defaultStyles,
  backgroundColor: '#4b5563',
  color: 'white',
  fontSize: 12,
  zIndex: 30,
  maxWidth: 250,
};

interface TooltipData {
  description: string;
}

export interface InformationTooltipProps {
  tooltip: string;
  isRed?: boolean;
  isGray?: boolean;
}

const InformationTooltip: React.FC<InformationTooltipProps> = ({ tooltip, isRed, isGray }) => {
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip<TooltipData>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });
  return (
    <>
      <div
        className={classNames(
          isRed === true
            ? 'border-red-400 text-red-400 hover:border-red-500 hover:text-red-500'
            : isGray === true
            ? 'border-gray-50 text-gray-50 hover:border-gray-50 hover:text-white hover:bg-transparent'
            : 'border-sky-400 text-sky-400 hover:border-sky-500 hover:text-sky-500',
          'ml-1 border-[1px] w-3 h-3 rounded-full cursor-default flex items-center justify-center text-[10px] hover:bg-white',
        )}
        ref={containerRef}
        onMouseLeave={() => {
          hideTooltip();
        }}
        onMouseMove={(event) => {
          const eventSvgCoords = localPoint(event);
          showTooltip({
            tooltipData: { description: tooltip },
            tooltipTop: eventSvgCoords?.y,
            tooltipLeft: eventSvgCoords?.x,
          });
        }}
      >
        i
      </div>
      {tooltipOpen && tooltipData !== undefined && (
        <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
          <div className="text-sm">{tooltipData.description}</div>
        </TooltipInPortal>
      )}
    </>
  );
};

export default InformationTooltip;
