import React, { useState } from 'react';
import StepWrapper from 'components/StepWrapper';
import InfoSection from 'components/InfoSection';

export interface StreamlitConfigurationStepProps {
  onBack: () => void;
  onContinue: () => void;
}

export default function StreamlitConfigurationStep(props: StreamlitConfigurationStepProps): JSX.Element {
  const [streamlitDescription, setStreamlitDescription] = useState('');

  return (
    <StepWrapper
      title="Streamlit configuration"
      subtitle="Provide the Streamlit configuration for your SSC Solution"
      onBack={() => props.onBack()}
      onContinue={() => props.onContinue()}
    >
      <div className="h-full flex flex-col items-center">
        <fieldset className="w-3/4 max-w-[30rem] mt-6 mb-8">
          <div className="divide-y divide-gray-200">
            <div className="relative flex items-start pb-6 pt-3.5">
              <div className="min-w-0 flex-1 text-sm leading-6">
                <label htmlFor="first-option" className="font-medium text-gray-900">
                  Streamlit in Snowflake (SIS)
                </label>
                <p className="text-gray-500"></p>
              </div>
              <div className="ml-3 flex h-6 items-center">
                <input id="first-option" name="streamlit" type="radio" className="w-5 h-5 rounded border-gray-300" />
              </div>
            </div>
            <div className="relative flex items-start pb-6 pt-3.5">
              <div className="min-w-0 flex-1 text-sm leading-6">
                <label htmlFor="ddl-option" className="font-medium text-gray-900">
                  Streamlit standalone in SPCS
                </label>
                <p className="text-gray-500"></p>
              </div>
              <div className="ml-3 flex h-6 items-center">
                <input id="second-option" name="streamlit" type="radio" className="w-5 h-5 rounded border-gray-300" />
              </div>
            </div>
          </div>
        </fieldset>

        <form className="w-full pb-6">
          <div className="mx-[auto] w-3/4 pb-2 grid grid-cols-2 gap-6">
            <div className="col-span-2">
              <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                Description
              </label>
              <div className="mt-2 mb-4">
                <textarea
                  id="decription"
                  name="description"
                  value={streamlitDescription}
                  onChange={(e) => setStreamlitDescription(e.target.value)}
                  rows={5}
                  className="block w-full rounded-md border-0 p-1.5 text-sm text-black placeholder:text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300"
                  required
                />
              </div>
              <InfoSection
                text="Give a description of what you want to achieve with this SSC Solution component. DataOps.live Assist will
                then automatically generate code to getting you started in the right direction"
              />
            </div>
          </div>
        </form>
        <div className="w-full pb-8 flex items-center justify-center">
          <button className="w-3/4 mt-2 bg-white rounded-md py-1 px-3 text-gray-900 shadow-sm hover:bg-gray-200 ring-1 ring-gray-300">
            Import
          </button>
        </div>
      </div>
    </StepWrapper>
  );
}
