import React from 'react';

interface TargetAudienceBadgeProps {
  label: string;
}

export default function TargetAudienceBadge(props: TargetAudienceBadgeProps): JSX.Element {
  return (
    <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 mr-2 mb-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
      {props.label}
    </span>
  );
}
