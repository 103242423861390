import { useState, useEffect } from 'react';
import type { IStep } from 'utils/types';
import { useAppSelector } from 'app/hooks';
import { selectSkipSnowflakeConnectionStep, selectIsPowerUserMode } from '../reducers/astraZenecaSlice';

const defaultSteps = [
  {
    name: 'Core Data Product attributes',
    description: 'Add mandatory specifications',
    disabled: false,
  },
  {
    name: 'Extended Data Product attributes',
    description: 'Add optional specifications',
    disabled: false,
  },
  {
    name: 'Snowflake connection',
    description: 'Connect to your Snowflake account',
    disabled: false,
  },
  {
    name: 'Source selection',
    description: 'Select which data sources to use in your Data Product',
    disabled: false,
  },
  {
    name: 'Data Product summary',
    description: 'Review your Data Product',
    disabled: false,
  },
];

export default function useAstraZenecaFlowSteps(): IStep[] {
  const skipSnowflakeConnectionStep = useAppSelector(selectSkipSnowflakeConnectionStep);
  const isPowerUserMode = useAppSelector(selectIsPowerUserMode);
  const [steps, setSteps] = useState<IStep[]>(defaultSteps);

  useEffect(() => {
    const tmpSteps = [...defaultSteps];

    if (isPowerUserMode) {
      tmpSteps.splice(3, 1);
      tmpSteps.splice(3, 0, {
        name: 'SQL Model Builder',
        description: 'Transform your SQL queries into powerful, scalable data models with ease',
        disabled: false,
      });
      tmpSteps.splice(4, 0, { name: 'Test Editor', description: 'Add tests to your models', disabled: false });
    }

    if (skipSnowflakeConnectionStep) {
      tmpSteps.splice(2, 1);
    }

    setSteps(tmpSteps);
  }, [skipSnowflakeConnectionStep, isPowerUserMode]);

  return steps;
}
