import React from 'react';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import {
  selectCortexSearchDescription,
  setCortexSearchDescription,
} from '../../native-app-builder/reducers/nativeAppSlice';
import StepWrapper from 'components/StepWrapper';
import InfoSection from 'components/InfoSection';

export interface CortexAiSearchConfigurationStepProps {
  onBack: () => void;
  onContinue: () => void;
}

export default function CortexAiSearchConfigurationStep(props: CortexAiSearchConfigurationStepProps): JSX.Element {
  const dispatch = useAppDispatch();
  const cortexSearchDescription = useAppSelector(selectCortexSearchDescription);

  return (
    <StepWrapper
      title="Cortex AI Search configuration"
      subtitle="Describe your Cortex AI Search component"
      onBack={() => props.onBack()}
      onContinue={() => props.onContinue()}
    >
      <div className="h-full">
        <form className="w-full pb-6">
          <div className="mx-[auto] w-3/4 pb-2 grid grid-cols-2 gap-6">
            <div className="col-span-2">
              <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                Description
              </label>
              <div className="mt-2 mb-4">
                <textarea
                  id="decription"
                  name="description"
                  value={cortexSearchDescription}
                  onChange={(e) => dispatch(setCortexSearchDescription(e.target.value))}
                  rows={5}
                  className="block w-full rounded-md border-0 p-1.5 text-sm text-black placeholder:text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300"
                  required
                />
              </div>
              <InfoSection
                text="Give a description of what you want to achieve with this SSC Solution component. DataOps.live Assist will
                then automatically generate code to getting you started in the right direction"
              />
            </div>
          </div>
        </form>
      </div>
    </StepWrapper>
  );
}
