import React, { useState, useEffect } from 'react';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import {
  selectSelectedDatabases,
  selectSelectedSchemas,
  selectSelectedTables,
  selectTable,
  unselectTable,
} from '../reducers/builderSlice';
import { classNames } from 'utils/styleUtils';

const tooltipStyles = {
  ...defaultStyles,
  backgroundColor: '#4b5563',
  color: 'white',
  fontSize: 12,
  zIndex: 30,
  maxWidth: 500,
};

interface TooltipData {
  description: string;
}

interface TableItemProps {
  databaseName: string;
  schemaName: string;
  tableName: string;
  mode: 'selection' | 'preview';
  highlighted?: boolean;
}

export default function TableItem(props: TableItemProps): JSX.Element {
  const dispatch = useAppDispatch();
  const selectedDatabases = useAppSelector(selectSelectedDatabases);
  const selectedSchemas = useAppSelector(selectSelectedSchemas);
  const selectedTables = useAppSelector(selectSelectedTables);
  const [selected, setSelected] = useState(false);

  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip<TooltipData>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  useEffect(() => {
    const databaseSelected = selectedDatabases.find((database) => database.database === props.databaseName);
    const schemaSelected = selectedSchemas.find(
      (schema) => schema.schema === props.schemaName && schema.database === props.databaseName,
    );
    const tableSelected = selectedTables.find(
      (table) =>
        table.database === props.databaseName && table.schema === props.schemaName && table.table === props.tableName,
    );
    if (databaseSelected !== undefined || schemaSelected !== undefined || tableSelected !== undefined) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [selectedDatabases, selectedSchemas, selectedTables]);

  const onClick = (): void => {
    if (selected) {
      dispatch(unselectTable({ table: props.tableName, schema: props.schemaName, database: props.databaseName }));
    } else {
      dispatch(selectTable({ table: props.tableName, schema: props.schemaName, database: props.databaseName }));
    }
    setSelected(!selected);
  };

  return (
    <>
      <div
        className={classNames(
          'w-full relative items-center justify-start flex py-1 rounded-md border border-gray-300 bg-gray-50 text-gray-700 cursor-default',
          props.mode === 'selection' ? 'px-4' : 'px-2',
        )}
      >
        {props.mode === 'selection' && (
          <div className="absolute left-2">
            <input
              type="checkbox"
              checked={selected}
              onChange={() => onClick()}
              className="rounded border-gray-300 cursor-pointer"
            />
          </div>
        )}
        <div
          className={classNames('min-w-[20px] w-5 mr-1', props.mode === 'selection' ? 'ml-2' : '')}
          ref={containerRef}
        >
          {props.highlighted === true ? (
            <img className="w-5" src="/static/table-icon-solid.svg" alt="Table" />
          ) : (
            <img className="w-5" src="/static/table-icon.svg" alt="Table" />
          )}
        </div>
        <div
          className="text-sm truncate"
          onMouseLeave={() => {
            hideTooltip();
          }}
          onMouseMove={(event) => {
            const eventSvgCoords = localPoint(event);
            showTooltip({
              tooltipData: { description: props.tableName },
              tooltipTop: eventSvgCoords?.y !== undefined ? eventSvgCoords.y + 10 : undefined,
              tooltipLeft: eventSvgCoords?.x,
            });
          }}
        >
          {props.tableName}
        </div>
      </div>
      {tooltipOpen && tooltipData !== undefined && (
        <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
          <div className="text-sm">{tooltipData.description}</div>
        </TooltipInPortal>
      )}
    </>
  );
}
