import React from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  selectDeploymentOption,
  selectTestSnowflakeAccount,
  selectTestSnowflakeUsername,
  selectTestSnowflakeWarehouse,
  selectTestSnowflakeRole,
  selectTestSnowflakeAccountConnected,
  setTestSnowflakeAccount,
  setTestSnowflakeUsername,
  setTestSnowflakeWarehouse,
  setTestSnowflakeRole,
  setTestSnowflakeAccountConnected,
} from '../reducers/nativeAppSlice';
import {
  selectSnowflakeAccount,
  selectSnowflakeUsername,
  selectSnowflakeWarehouse,
  selectSnowflakeRole,
  selectSnowflakeAccountConnected,
  selectSnowflakePrivateKey,
  selectSnowflakePassphrase,
  setSnowflakeAccount,
  setSnowflakeUsername,
  setSnowflakeWarehouse,
  setSnowflakeRole,
  setSnowflakeAccountConnected,
  setSnowflakePrivateKey,
  setSnowflakePassphrase,
} from 'app/snowflakeSlice';
import StepWrapper from 'components/StepWrapper';
import SnowflakeAccountForm from 'components/SnowflakeAccountForm';

export interface SnowflakeAccountsStepProps {
  onBack: () => void;
  onContinue: () => void;
}

export default function SnowflakeAccountsStep(props: SnowflakeAccountsStepProps): JSX.Element {
  const dispatch = useAppDispatch();
  const deploymentOption = useAppSelector(selectDeploymentOption);

  const snowflakeAccount = useAppSelector(selectSnowflakeAccount);
  const snowflakeUsername = useAppSelector(selectSnowflakeUsername);
  const snowflakeWarehouse = useAppSelector(selectSnowflakeWarehouse);
  const snowflakeRole = useAppSelector(selectSnowflakeRole);
  const snowflakePrivateKey = useAppSelector(selectSnowflakePrivateKey);
  const snowflakePassphrase = useAppSelector(selectSnowflakePassphrase);
  const snowflakeAccountConnected = useAppSelector(selectSnowflakeAccountConnected);

  const testSnowflakeAccount = useAppSelector(selectTestSnowflakeAccount);
  const testSnowflakeUsername = useAppSelector(selectTestSnowflakeUsername);
  const testSnowflakeWarehouse = useAppSelector(selectTestSnowflakeWarehouse);
  const testSnowflakeRole = useAppSelector(selectTestSnowflakeRole);
  const testSnowflakeAccountConnected = useAppSelector(selectTestSnowflakeAccountConnected);

  return (
    <StepWrapper
      title="Snowflake accounts"
      subtitle="Connect to your Snowflake account"
      onBack={() => props.onBack()}
      onContinue={() => props.onContinue()}
      continueDisabled={!snowflakeAccountConnected || (deploymentOption === 'double' && !testSnowflakeAccountConnected)}
    >
      <div className="h-full flex flex-col items-center">
        <div className="relative w-3/4 mb-3">
          <div className="absolute inset-0 flex items-center" aria-hidden="true">
            <div className="w-full border-t border-gray-400" />
          </div>
          <div className="relative flex justify-center">
            <span className="bg-white px-2 text-sm text-gray-600">Main Snowflake instance</span>
          </div>
        </div>

        <SnowflakeAccountForm
          snowflakeAccount={snowflakeAccount}
          setSnowflakeAccount={(account) => dispatch(setSnowflakeAccount(account))}
          snowflakeUsername={snowflakeUsername}
          setSnowflakeUsername={(username) => dispatch(setSnowflakeUsername(username))}
          snowflakeWarehouse={snowflakeWarehouse}
          setSnowflakeWarehouse={(warehouse) => dispatch(setSnowflakeWarehouse(warehouse))}
          snowflakeRole={snowflakeRole}
          setSnowflakeRole={(role) => dispatch(setSnowflakeRole(role))}
          snowflakeAccountConnected={snowflakeAccountConnected}
          setSnowflakeAccountConnected={(connected) => dispatch(setSnowflakeAccountConnected(connected))}
          snowflakePrivateKey={snowflakePrivateKey}
          setSnowflakePrivateKey={(key) => dispatch(setSnowflakePrivateKey(key))}
          snowflakePassphrase={snowflakePassphrase}
          setSnowflakePassphrase={(passphrase) => dispatch(setSnowflakePassphrase(passphrase))}
        />

        {deploymentOption === 'double' && (
          <>
            <div className="relative w-3/4 mb-3 mt-6">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-400" />
              </div>
              <div className="relative flex justify-center">
                <span className="bg-white px-2 text-sm text-gray-600">External test instance</span>
              </div>
            </div>

            <SnowflakeAccountForm
              snowflakeAccount={testSnowflakeAccount}
              setSnowflakeAccount={(account) => dispatch(setTestSnowflakeAccount(account))}
              snowflakeUsername={testSnowflakeUsername}
              setSnowflakeUsername={(username) => dispatch(setTestSnowflakeUsername(username))}
              snowflakeWarehouse={testSnowflakeWarehouse}
              setSnowflakeWarehouse={(warehouse) => dispatch(setTestSnowflakeWarehouse(warehouse))}
              snowflakeRole={testSnowflakeRole}
              setSnowflakeRole={(role) => dispatch(setTestSnowflakeRole(role))}
              snowflakeAccountConnected={testSnowflakeAccountConnected}
              setSnowflakeAccountConnected={(connected) => dispatch(setTestSnowflakeAccountConnected(connected))}
              snowflakePrivateKey={snowflakePrivateKey}
              setSnowflakePrivateKey={(key) => dispatch(setSnowflakePrivateKey(key))}
              snowflakePassphrase={snowflakePassphrase}
              setSnowflakePassphrase={(passphrase) => dispatch(setSnowflakePassphrase(passphrase))}
            />
          </>
        )}
      </div>
    </StepWrapper>
  );
}
