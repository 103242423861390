import React, { useState } from 'react';
import { usePipelineMutation } from 'app/createApi';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { selectProjectPath, selectBranch, setCurrentStep, setPipelineId } from 'app/sharedSlice';
import { selectSnowflakeAccount, selectSelectedDatabase } from 'app/snowflakeSlice';
import {
  selectFlow,
  selectDataProductName,
  selectDataProductOwner,
  selectDataProductVersion,
  selectNumberOfSelectedSourceTables,
  selectNumberOfGeneratedTests,
} from '../reducers/builderSlice';
import StepWrapper from 'components/StepWrapper';
import LoadingAndErrorSection from 'components/LoadingAndErrorSection';
import OverviewBlock from 'components/OverviewBlock';
import SourceSelector from '../components/SourceSelector';

export interface DataProductSummaryStepProps {
  onBack: () => void;
  onContinue: () => void;
}

export default function DataProductSummaryStep(props: DataProductSummaryStepProps): JSX.Element {
  const dispatch = useAppDispatch();
  const flow = useAppSelector(selectFlow);
  const dataProductName = useAppSelector(selectDataProductName);
  const dataProductOwner = useAppSelector(selectDataProductOwner);
  const dataProductVersion = useAppSelector(selectDataProductVersion);
  const projectPath = useAppSelector(selectProjectPath);
  const branch = useAppSelector(selectBranch);
  const snowflakeAccount = useAppSelector(selectSnowflakeAccount);
  const selectedDatabase = useAppSelector(selectSelectedDatabase);
  const numberOfSelectedSourceTables = useAppSelector(selectNumberOfSelectedSourceTables);
  const numberOfGeneratedTests = useAppSelector(selectNumberOfGeneratedTests);

  const [loading, setLoading] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('Failed trigger pipeline');

  const [pipeline] = usePipelineMutation();

  const stats = [
    { name: 'Source tables', value: numberOfSelectedSourceTables },
    { name: 'Tests generated', value: numberOfGeneratedTests },
  ];

  const onContinue = (): void => {
    setLoading(true);
    setFailed(false);
    pipeline({ dataProductName, projectName: projectPath, branch, mergeMain: flow === 'ddl' })
      .unwrap()
      .then((res: any) => {
        dispatch(setPipelineId(res));
        props.onContinue();
      })
      .catch((err) => {
        console.log(err);
        setFailed(true);
        setErrorMessage(err.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /* eslint-disable */
  return (
    <StepWrapper
      title="Review your data product"
      subtitle="Check the summary before publishing the data product branch to the DataOps project (repository)"
      onBack={() => props.onBack()}
      onContinue={() => onContinue()}
      continueDisabled={false}
      isLastStep={true}
    >
      <div className="px-6">
        <div className="flex">
          <div className="w-2/3">
            <OverviewBlock
              dividerText="Definition"
              showEditButton
              onEditClick={() => dispatch(setCurrentStep(0))}
              showRightBorder
            >
              <dl className="grid grid-cols-2">
                <div className="border-gray-100 px-4 py-3">
                  <dt className="text-sm font-semibold text-gray-900">Data Product Name</dt>
                  <dd className="text-sm text-gray-700 truncate">{dataProductName}</dd>
                </div>
                <div className="border-gray-100 px-4 py-3">
                  <dt className="text-sm font-semibold text-gray-900">Data Product Owner</dt>
                  <dd className="text-sm text-gray-700 truncate">{dataProductOwner}</dd>
                </div>
                <div className="border-gray-100 px-4 py-3">
                  <dt className="text-sm font-semibold text-gray-900">Data Product Version</dt>
                  <dd className="text-sm text-gray-700">{dataProductVersion}</dd>
                </div>
                <div className="border-gray-100 px-4 py-3">
                  <dt className="text-sm font-semibold text-gray-900">Project</dt>
                  <dd className="text-sm text-gray-700 truncate">{projectPath}</dd>
                </div>
              </dl>
            </OverviewBlock>
          </div>
          <div className="w-1/3">
            <OverviewBlock dividerText="Data quality">
              <div className="text-sm text-gray-600 px-4">
                <dl className="border border-gray-300 rounded-md overflow-hidden mx-auto grid grid-cols-2 gap-px bg-gray-900/5">
                  {stats.map((stat) => (
                    <div
                      key={stat.name}
                      className="flex flex-wrap items-baseline justify-between gap-x-2 gap-y-1 bg-white px-6 py-4"
                    >
                      <dt className="text-sm font-medium leading-6 text-gray-500">{stat.name}</dt>
                      <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                        {stat.value}
                      </dd>
                    </div>
                  ))}
                </dl>
              </div>
            </OverviewBlock>
          </div>
        </div>

        <OverviewBlock
          dividerText="Dataset source"
          backgroundColor="bg-gray-50"
          showEditButton
          onEditClick={() => dispatch(setCurrentStep(1))}
        >
          <div className="px-6 text-sm text-gray-600">
            The metadata was imported from the following Snowflake account:{' '}
            <span className="font-semibold text-gray-800">{snowflakeAccount}</span>
          </div>
        </OverviewBlock>

        <OverviewBlock dividerText="Dataset management" showEditButton onEditClick={() => dispatch(setCurrentStep(2))}>
          <div className="px-6 text-sm text-gray-600">
            Your Snowflake database <span className="font-semibold text-gray-800">{selectedDatabase}</span> will be
            managed by DataOps.live and will remain unchanged. Instead, a clone is created on a feature branch, and
            changes are managed in the data product platform.
          </div>
        </OverviewBlock>

        {flow === 'snowflake' && (
          <OverviewBlock
            dividerText="Dataset schema"
            backgroundColor="bg-gray-50"
            showEditButton
            onEditClick={() => dispatch(setCurrentStep(3))}
          >
            <div className="w-full flex flex-col items-center">
              <div className="px-6 text-sm text-gray-600 mb-4">The following objects were selected as sources</div>
              <SourceSelector mode="preview" databases={[selectedDatabase]} />
            </div>
          </OverviewBlock>
        )}
        <LoadingAndErrorSection isLoading={loading} isFailed={failed} errorMessage={errorMessage} />
      </div>
    </StepWrapper>
  );
}
