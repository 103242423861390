import React from 'react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { classNames } from 'utils/styleUtils';
import type { IStep } from 'utils/types';

interface StepsProps {
  steps: IStep[];
  currentStep: number;
  setCurrentStep: (step: number) => void;
}

export default function Steps(props: StepsProps): JSX.Element {
  return (
    <nav aria-label="Progress">
      <ol role="list" className="overflow-hidden">
        {props.steps.map((step, stepIdx) => (
          <li
            key={`${step.name}.${stepIdx}`}
            className={classNames(stepIdx !== props.steps.length - 1 ? 'pb-2 lg:pb-4 2xl:pb-8' : '', 'relative')}
            // onClick={() => props.setCurrentStep(stepIdx)}
          >
            {props.currentStep > stepIdx ? (
              <>
                {stepIdx !== props.steps.length - 1 ? (
                  <div
                    className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-dataops-secondary-blue"
                    aria-hidden="true"
                  />
                ) : null}
                <div className="group relative flex items-start">
                  <span className="flex h-9 items-center">
                    <span
                      className={classNames(
                        'relative z-10 flex h-8 w-8 items-center justify-center rounded-full',
                        step.disabled ? 'bg-gray-400' : 'bg-dataops-secondary-blue group-hover:bg-hover-secondary-blue',
                      )}
                    >
                      <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
                    </span>
                  </span>
                  <span className="ml-4 flex min-w-0 flex-col">
                    <span
                      className={classNames('text-sm font-semibold', step.disabled ? 'text-gray-600' : 'text-gray-900')}
                    >
                      {step.name}
                    </span>
                    <span className="text-sm text-gray-500">{step.description}</span>
                  </span>
                </div>
              </>
            ) : props.currentStep === stepIdx ? (
              <>
                {stepIdx !== props.steps.length - 1 ? (
                  <div className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true" />
                ) : null}
                <div className="group relative flex items-start" aria-current="step">
                  <span className="flex h-9 items-center" aria-hidden="true">
                    <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-dataops-secodary-blue bg-white">
                      <span className="h-2.5 w-2.5 rounded-full bg-dataops-primary-blue" />
                    </span>
                  </span>
                  <span className="ml-4 flex min-w-0 flex-col">
                    <span className="text-sm font-semibold text-gray-900">{step.name}</span>
                    <span className="text-sm text-gray-500">{step.description}</span>
                  </span>
                </div>
              </>
            ) : (
              <>
                {stepIdx !== props.steps.length - 1 ? (
                  <div className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" aria-hidden="true" />
                ) : null}
                <div className="group relative flex items-start">
                  <span className="flex h-9 items-center" aria-hidden="true">
                    <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
                      <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
                    </span>
                  </span>
                  <span className="ml-4 flex min-w-0 flex-col">
                    <span
                      className={classNames('text-sm font-semibold', step.disabled ? 'text-gray-600' : 'text-gray-900')}
                    >
                      {step.name}
                    </span>
                    <span className="text-sm text-gray-500">{step.description}</span>
                  </span>
                </div>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}
