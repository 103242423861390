import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { selectCurrentStep, setCurrentStep } from 'app/sharedSlice';
import TemplateStep from 'features/native-app-builder/steps/TemplateStep';
import NativeAppDefinitionStep from 'features/native-app-builder/steps/NativeAppDefinitionStep';
import DeploymentConfigurationStep from 'features/native-app-builder/steps/DeploymentConfigurationStep';
import SnowflakeAccountsStep from 'features/native-app-builder/steps/SnowflakeAccountsStep';
import NativeModuleConfigurationStep from 'features/native-app-builder/steps/NativeModuleConfigurationStep';
import PythonUdfConfigurationStep from 'features/native-app-builder/steps/PythonUdfConfigurationStep';
import SqlUdfConfigurationStep from 'features/native-app-builder/steps/SqlUdfConfigurationStep';
import StreamlitConfigurationStep from 'features/native-app-builder/steps/StreamlitConfigurationStep';
import SpcsConfigurationStep from 'features/native-app-builder/steps/SpcsCofigurationStep';
import SnowflakeAiConfigurationStep from 'features/native-app-builder/steps/SnowflakeAiConfigurationStep';
import CortexAiConfigurationStep from 'features/native-app-builder/steps/CortexAiConfigurationStep';
import CortexAiSearchConfigurationStep from 'features/native-app-builder/steps/CortexAiSearchConfigurationStep';
import SharedDataDatabaseStep from 'features/native-app-builder/steps/SharedDataDatabaseStep';
import SharedDataSchemaStep from 'features/native-app-builder/steps/SharedDataSchemaStep';
import NotebookConfigurationStep from 'features/native-app-builder/steps/NotebookConfigurationStep';
import NativeAppSummaryStep from 'features/native-app-builder/steps/NativeAppSummaryStep';
import { sendMetrics, MetricType } from 'utils/metrics';
import { selectUser } from 'features/authentication/reducers/userSlice';
import useNativeAppCreatorSteps from 'features/native-app-builder/steps/nativeAppBuilderSteps';
import { IStep } from 'utils/types';

export default function NativeAppBuilderView(): JSX.Element {
  const steps: IStep[] = useNativeAppCreatorSteps();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentStep = useAppSelector(selectCurrentStep);
  const user = useAppSelector(selectUser);

  const setStepAndSendMetric = (step: number): void => {
    sendMetrics(MetricType.create_analytics, user, step.toString(), null);
    dispatch(setCurrentStep(step));
  };

  const getComponent = (name: string, index: number): React.ReactNode => {
    switch (name) {
      case 'Template':
        return (
          <TemplateStep
            onBack={index === 0 ? () => navigate('/') : () => setStepAndSendMetric(index - 1)}
            onContinue={() => setStepAndSendMetric(index + 1)}
          />
        );
      case 'Native app definition':
        return (
          <NativeAppDefinitionStep
            onBack={() => setStepAndSendMetric(index - 1)}
            onContinue={() => setStepAndSendMetric(index + 1)}
          />
        );
      case 'Deployment configuration':
        return (
          <DeploymentConfigurationStep
            onBack={() => setStepAndSendMetric(index - 1)}
            onContinue={() => setStepAndSendMetric(index + 1)}
          />
        );
      case 'Native module configuration':
        return (
          <NativeModuleConfigurationStep
            onBack={() => setStepAndSendMetric(index - 1)}
            onContinue={() => setStepAndSendMetric(index + 1)}
          />
        );
      case 'Native app summary':
        return (
          <NativeAppSummaryStep
            onBack={() => setStepAndSendMetric(index - 1)}
            onContinue={() => setStepAndSendMetric(index + 1)}
          />
        );
      case 'Snowflake accounts':
        return (
          <SnowflakeAccountsStep
            onBack={() => setStepAndSendMetric(index - 1)}
            onContinue={() => setStepAndSendMetric(index + 1)}
          />
        );
      case 'Python UDF configuration':
        return (
          <PythonUdfConfigurationStep
            onBack={() => setStepAndSendMetric(index - 1)}
            onContinue={() => setStepAndSendMetric(index + 1)}
          />
        );
      case 'SQL UDF configuration':
        return (
          <SqlUdfConfigurationStep
            onBack={() => setStepAndSendMetric(index - 1)}
            onContinue={() => setStepAndSendMetric(index + 1)}
          />
        );
      case 'Streamlit configuration':
        return (
          <StreamlitConfigurationStep
            onBack={() => setStepAndSendMetric(index - 1)}
            onContinue={() => setStepAndSendMetric(index + 1)}
          />
        );
      case 'SPCS configuration':
        return (
          <SpcsConfigurationStep
            onBack={() => setStepAndSendMetric(index - 1)}
            onContinue={() => setStepAndSendMetric(index + 1)}
          />
        );
      case 'Snowflake AI configuration':
        return (
          <SnowflakeAiConfigurationStep
            onBack={() => setStepAndSendMetric(index - 1)}
            onContinue={() => setStepAndSendMetric(index + 1)}
          />
        );
      case 'Cortex AI configuration':
        return (
          <CortexAiConfigurationStep
            onBack={() => setStepAndSendMetric(index - 1)}
            onContinue={() => setStepAndSendMetric(index + 1)}
          />
        );
      case 'Cortex AI Search configuration':
        return (
          <CortexAiSearchConfigurationStep
            onBack={() => setStepAndSendMetric(index - 1)}
            onContinue={() => setStepAndSendMetric(index + 1)}
          />
        );
      case 'Shared Data - Database':
        return (
          <SharedDataDatabaseStep
            onBack={() => setStepAndSendMetric(index - 1)}
            onContinue={() => setStepAndSendMetric(index + 1)}
          />
        );
      case 'Shared Data - Schema':
        return (
          <SharedDataSchemaStep
            onBack={() => setStepAndSendMetric(index - 1)}
            onContinue={() => setStepAndSendMetric(index + 1)}
          />
        );
      case 'Notebook configuration':
        return (
          <NotebookConfigurationStep
            onBack={() => setStepAndSendMetric(index - 1)}
            onContinue={() => setStepAndSendMetric(index + 1)}
          />
        );
    }
  };

  return (
    <>
      {steps.map((step, index) => (
        <Fragment key={`${step.name}-${index}`}>{currentStep === index && getComponent(step.name, index)}</Fragment>
      ))}
    </>
  );
}
