import React, { useState, useRef, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useAddMergeRequestNoteMutation } from 'app/platformApi';

interface CommentOnMRDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  projectId: string;
  mergeRequestId: number;
}

const CommentOnMRDialog = (props: CommentOnMRDialogProps): JSX.Element => {
  const cancelButtonRef = useRef(null);

  const [comment, setComment] = useState('');

  const [addMergeRequestNote] = useAddMergeRequestNoteMutation();

  const onAddComment = (): void => {
    addMergeRequestNote({
      projectId: props.projectId,
      mergeRequestId: props.mergeRequestId,
      comment,
    })
      .unwrap()
      .then(() => {
        props.setOpen(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => props.setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="w-[25rem] relative transform overflow-visible rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <Dialog.Title as="h3" className="mb-4 text-lg text-center font-semibold leading-6 text-gray-900">
                  Comment on MR
                </Dialog.Title>
                <div className="w-full">
                  <div className="">
                    <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                      Comment
                    </label>
                    <div className="">
                      <textarea
                        id="comment"
                        name="comment"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        rows={4}
                        required
                        className="block w-full rounded-md border-0 p-1.5 text-sm text-black placeholder:text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300"
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-6 flex items-center justify-center gap-2">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-dataops-secondary-blue px-3 py-2 text-sm font-semibold text-gray-50 shadow-sm hover:bg-hover-secondary-blue sm:mt-0 sm:w-auto"
                    onClick={() => onAddComment()}
                  >
                    Add comment
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    ref={cancelButtonRef}
                    onClick={() => props.setOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CommentOnMRDialog;
