import React from 'react';
import { PlusIcon } from '@heroicons/react/20/solid';
import type { IColumn, ITest, IModelAndColumn } from '../types/testEditorTypes';
import TestEditorTestBadge from './TestEditorTestBadge';
import { classNames } from 'utils/styleUtils';

interface ColumnItemProps {
  column: IColumn;
  schemaName: string;
  modelName: string;
  openDialog: (value: boolean) => void;
  selectedModelsAndColumns: IModelAndColumn[];
  setSelectedModelsAndColumns: (value: IModelAndColumn[]) => void;
  setExistingTest: (value: ITest | undefined) => void;
  onRemoveTest: (modelName: string, columnName: string, test: ITest) => void;
}

export default function TestEditorColumnItem(props: ColumnItemProps): JSX.Element {
  const isSelected = props.selectedModelsAndColumns.some(
    (modelAndColumn) => modelAndColumn.modelName === props.modelName && modelAndColumn.columnName === props.column.name,
  );

  return (
    <>
      <div
        className={classNames(
          isSelected ? 'bg-gray-200' : 'bg-gray-50',
          'w-full relative flex items-center justify-start px-3 py-1 rounded-md border border-gray-300 text-gray-700 cursor-default',
        )}
        onClick={() => {
          if (isSelected) {
            props.setSelectedModelsAndColumns(
              props.selectedModelsAndColumns.filter(
                (modelAndColumn) =>
                  modelAndColumn.modelName !== props.modelName || modelAndColumn.columnName !== props.column.name,
              ),
            );
          } else {
            props.setSelectedModelsAndColumns([
              ...props.selectedModelsAndColumns,
              { modelName: props.modelName, columnName: props.column.name },
            ]);
          }
        }}
      >
        <div className="grow flex items-center justify-start overflow-hidden">
          <div className="mr-1.5 pt-0.5">
            <svg
              width="20"
              height="20"
              viewBox="0 0 16 16"
              fill="none"
              stroke="#4b5563"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="5.5" y="1.5" width="6" height="12" rx="1" strokeLinecap="round"></rect>
              <line x1="6" y1="5.5" x2="12" y2="5.5"></line>
              <line x1="6" y1="9.5" x2="12" y2="9.5"></line>
            </svg>
          </div>
          <div className="text-sm truncate">{props.column.name}</div>
          <span className="ml-2 inline-flex self-end mb-[4px] items-center rounded-md px-1 text-[10px] font-medium text-gray-500 ring-1 ring-inset ring-gray-200">
            {props.column.type}
          </span>
        </div>
        {props.column.tests !== undefined && props.column.tests.length !== 0 && (
          <div className="flex space-x-1">
            {props.column.tests.map((test, index) => (
              <TestEditorTestBadge
                key={index}
                test={test}
                onRemoveTest={() => props.onRemoveTest(props.modelName, props.column.name, test)}
                onEditTest={() => {
                  props.setSelectedModelsAndColumns([{ modelName: props.modelName, columnName: props.column.name }]);
                  props.setExistingTest(test);
                  props.openDialog(true);
                }}
              />
            ))}
          </div>
        )}
        <button
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            props.setExistingTest(undefined);
            props.setSelectedModelsAndColumns([{ modelName: props.modelName, columnName: props.column.name }]);
            props.openDialog(true);
          }}
          className="rounded-full ml-2 p-0.5 text-gray-600 hover:text-gray-800 hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <PlusIcon aria-hidden="true" className="h-5 w-5" />
        </button>
      </div>
    </>
  );
}
