import type { IStep } from 'utils/types';

export default function useDbtMigrationSteps(): IStep[] {
  return [
    {
      name: 'Target project',
      description: 'Where would you like to create the new project?',
      disabled: false,
    },
    {
      name: 'Source project',
      description: 'Select a source of your dbt project',
      disabled: false,
    },
    {
      name: 'Target database',
      description: 'Select the target database',
      disabled: false,
    },
    {
      name: 'Pipeline configuration',
      description: 'Which jobs do you want to include in the pipeline?',
      disabled: false,
    },
    // {
    //   name: 'Summary',
    //   description: 'Review your new project',
    //   disabled: false,
    // },
  ];
}
