import React from 'react';
import { Outlet } from 'react-router-dom';

export default function AstraZenecaMontoringView(props: {}): JSX.Element {
  return (
    <div className="w-full h-full flex flex-col items-center relative top-[4rem] p-4">
      <Outlet />
    </div>
  );
}
