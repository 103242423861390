import { useState, useEffect } from 'react';
import { useAppSelector } from 'app/hooks';
import { selectSelectedModules, selectDeploymentOption } from '../../native-app-builder/reducers/nativeAppSlice';
import type { IStep } from 'utils/types';

const defaultSteps: IStep[] = [
  {
    name: 'Template',
    description: 'Choose a template',
    disabled: false,
  },
  {
    name: 'Select components',
    description: 'Select the Snowflake components',
    disabled: false,
  },
  {
    name: 'Deployment configuration',
    description: 'Choose from the deployment options',
    disabled: false,
  },
  {
    name: 'Summary',
    description: 'Review your components',
    disabled: false,
  },
];

export default function useDataAppCreatorSteps(): IStep[] {
  const selectedModules = useAppSelector(selectSelectedModules);
  const deploymentOption = useAppSelector(selectDeploymentOption);

  const hasPythonUdfModule = selectedModules.some((module) => module.type === 'Python UDF');
  const hasSqlUdfModule = selectedModules.some((module) => module.type === 'SQL UDF');
  const hasStreamlitModule = selectedModules.some((module) => module.type === 'Streamlit');
  const hasSpcsModule = selectedModules.some((module) => module.type === 'Snowpark Container Services');
  const hasSnowflakeAiModule = selectedModules.some((module) => module.type === 'Snowflake AI');
  const hasCortexAiModule = selectedModules.some((module) => module.type === 'Cortex AI');
  const hasCortexAiSearchModule = selectedModules.some((module) => module.type === 'Cortex AI Search');
  const hasSharedDataModule = selectedModules.some((module) => module.type === 'Shared Data');
  const hasNotebookModule = selectedModules.some((module) => module.type === 'Notebook');

  const [steps, setSteps] = useState<IStep[]>(defaultSteps);

  useEffect(() => {
    const tmpSteps = [...defaultSteps];

    if (deploymentOption === 'single' || deploymentOption === 'double' || hasSharedDataModule) {
      tmpSteps.splice(3, 0, {
        name: 'Snowflake accounts',
        description: 'Connect your Snowflake account',
        disabled: false,
      });
    }

    if (hasPythonUdfModule) {
      tmpSteps.splice(tmpSteps.length - 1, 0, {
        name: 'Python UDF configuration',
        description: 'Python UDF configuration for the app',
        disabled: false,
      });
    }

    if (hasSqlUdfModule) {
      tmpSteps.splice(tmpSteps.length - 1, 0, {
        name: 'SQL UDF configuration',
        description: 'SQL UDF configuration for the app',
        disabled: false,
      });
    }

    if (hasStreamlitModule) {
      tmpSteps.splice(tmpSteps.length - 1, 0, {
        name: 'Streamlit configuration',
        description: 'Streamlit configuration for the app',
        disabled: false,
      });
    }

    if (hasSpcsModule) {
      tmpSteps.splice(tmpSteps.length - 1, 0, {
        name: 'SPCS configuration',
        description: 'SPCS configuration for the app',
        disabled: false,
      });
    }

    if (hasSnowflakeAiModule) {
      tmpSteps.splice(tmpSteps.length - 1, 0, {
        name: 'Snowflake AI configuration',
        description: 'Snowflake AI configuration for the app',
        disabled: false,
      });
    }

    if (hasCortexAiModule) {
      tmpSteps.splice(tmpSteps.length - 1, 0, {
        name: 'Cortex AI configuration',
        description: 'Cortex AI configuration for the app',
        disabled: false,
      });
    }

    if (hasCortexAiSearchModule) {
      tmpSteps.splice(tmpSteps.length - 1, 0, {
        name: 'Cortex AI Search configuration',
        description: 'Cortex AI Search configuration for the app',
        disabled: false,
      });
    }

    if (hasSharedDataModule) {
      tmpSteps.splice(tmpSteps.length - 1, 0, {
        name: 'Shared Data - Database',
        description: 'Select the database for shared data',
        disabled: false,
      });

      tmpSteps.splice(tmpSteps.length - 1, 0, {
        name: 'Shared Data - Schema',
        description: 'Select the schemas and tables for shared data',
        disabled: false,
      });
    }

    if (hasNotebookModule) {
      tmpSteps.splice(tmpSteps.length - 1, 0, {
        name: 'Notebook configuration',
        description: 'Notebook configuration for the app',
        disabled: false,
      });
    }

    setSteps(tmpSteps);
  }, [
    hasPythonUdfModule,
    hasSqlUdfModule,
    hasStreamlitModule,
    hasSpcsModule,
    hasSnowflakeAiModule,
    hasCortexAiModule,
    hasCortexAiSearchModule,
    hasSharedDataModule,
    hasNotebookModule,
    deploymentOption,
  ]);

  return steps;
}
