import React, { useState, useEffect } from 'react';
import { useGetProjectsQuery } from 'app/platformApi';
import { useNavigate } from 'react-router-dom';
import { RocketLaunchIcon } from '@heroicons/react/20/solid';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import {
  selectNumberOfSteps,
  selectProjectPath,
  selectSelectedProject,
  selectNewProjectName,
  selectBranch,
  setCurrentStep,
} from 'app/sharedSlice';
import { getConfig } from 'config/config-helper';
import { sendMetrics, MetricType, ExitTo } from 'utils/metrics';
import { selectUser } from 'features/authentication/reducers/userSlice';

const { dataopsliveBaseUrl } = getConfig();

export default function AstraZenecaFlowComplete(): JSX.Element {
  const dispatch = useAppDispatch();
  const numberOfSteps = useAppSelector(selectNumberOfSteps);
  const projectPath = useAppSelector(selectProjectPath);
  const selectedProject = useAppSelector(selectSelectedProject);
  const newProjectName = useAppSelector(selectNewProjectName);
  const branch = useAppSelector(selectBranch);

  const [projectId, setProjectId] = useState(0);

  const pathForLink = projectPath.toLowerCase().split(' ').join('-');
  const devReadyLink = `https://develop.dataops.live/quickstart?autostart=true#LAUNCH_MODE=DATA_PRODUCT_CREATOR/https://app.dataops.live/${pathForLink}/-/tree/${branch}/`;
  const repoLink = `${dataopsliveBaseUrl}/${pathForLink}/-/tree/${branch}/`;
  const statusLink = `/astra-zeneca-monitoring/status/${projectId}`;
  const user = useAppSelector(selectUser);

  const { data: projects } = useGetProjectsQuery({ search: newProjectName });

  const [copySuccess, setCopySuccess] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (selectedProject.id !== 0) {
      setProjectId(selectedProject.id);
    } else if (projects !== undefined && projects.length > 0) {
      setProjectId(projects[0].id);
    }
  }, [projects, selectedProject.id]);

  const sendMetricsAndNavigate = (exitTo: ExitTo): void => {
    sendMetrics(MetricType.create_analytics_end, user, null, exitTo);
  };

  const copyToClipboard = async (): Promise<void> => {
    try {
      await navigator.clipboard.writeText(devReadyLink);
      setCopySuccess(true);
      setTimeout(() => {
        setCopySuccess(false);
      }, 3000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  return (
    <div className="h-fit w-fit max-w-lg mx-[auto] m-4 mt-[4.9rem] p-8 bg-white rounded-md drop-shadow-md flex flex-col items-center space-y-8">
      <div className="flex">
        <div className="text-2xl text-gray-900">Data Product created</div>
        <RocketLaunchIcon className="w-8 ml-4 text-dataops-primary-blue" />
      </div>
      <div className="flex flex-col items-center">
        <div className="text-gray-600">
          Open our dedicated development environment which provides all the tools you need to view and update your Data
          Product
        </div>
        <a href={devReadyLink} className="w-3/4" onClick={() => sendMetricsAndNavigate(ExitTo.developer)}>
          <button className="w-full mt-4 rounded-md bg-dataops-secondary-blue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-hover-secondary-blue disabled:bg-gray-400">
            Open Develop
          </button>
        </a>
        <button
          onClick={() => {
            copyToClipboard().catch((e) => e);
          }}
          className="w-3/4 mt-4 bg-white rounded-md py-1 px-3 text-gray-900 shadow-sm hover:bg-gray-200 ring-1 ring-gray-300"
        >
          {copySuccess ? 'Copied!' : 'Copy Develop link'}
        </button>
      </div>
      <div className="w-3/4 border-t border-gray-300"></div>
      <div className="flex flex-col items-center">
        <div className="text-gray-600">
          We are running a DataOps pipeline for you to deploy the initial version of your Data Product to Snowflake
        </div>
        <button
          onClick={() => navigate(statusLink)}
          className="w-3/4 mt-6 bg-white rounded-md py-1 px-3 text-gray-900 shadow-sm hover:bg-gray-200 ring-1 ring-gray-300"
        >
          Monitor project status
        </button>
        <a href={repoLink} className="w-3/4" onClick={() => sendMetricsAndNavigate(ExitTo.pipeline)}>
          <button className="w-full mt-6 bg-white rounded-md py-1 px-3 text-gray-900 shadow-sm hover:bg-gray-200 ring-1 ring-gray-300">
            Open Data Product repo
          </button>
        </a>
        <div className="text-gray-600 self-start mt-8">
          Pipeline status: <span className="text-dataops-primary-blue font-semibold">Running</span>
        </div>
        <div className="absolute bottom-6 right-8 ml-[auto]">
          <button
            type="button"
            onClick={() => dispatch(setCurrentStep(numberOfSteps - 1))}
            className="text-md font-semibold leading-6 text-gray-900 hover:underline"
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
}
