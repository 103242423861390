import React from 'react';
import { useAppSelector } from 'app/hooks';
import { selectSnowflakeAccount, selectSelectedDatabase } from 'app/snowflakeSlice';
import { selectNativeAppName } from '../../native-app-builder/reducers/nativeAppSlice';
import StepWrapper from 'components/StepWrapper';
import SourceSelector from 'features/data-product-builder/components/SourceSelector';

export interface SharedDataSchemaStepProps {
  onBack: () => void;
  onContinue: () => void;
}

export default function SharedDataSchemaStep(props: SharedDataSchemaStepProps): JSX.Element {
  const snowflakeAccount = useAppSelector(selectSnowflakeAccount);
  const nativeAppName = useAppSelector(selectNativeAppName);
  const selectedDatabase = useAppSelector(selectSelectedDatabase);

  return (
    <StepWrapper
      title="Shared Data - Schema and table selection"
      subtitle="Which schemas and tables do you want to use for shared data?"
      onBack={() => props.onBack()}
      onContinue={() => props.onContinue()}
    >
      <div className="w-full h-full flex flex-col items-center">
        <div className="flex w-full">
          <div className="w-1/2 h-full flex flex-col items-center">
            <div className="relative w-3/4 mb-3">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-400" />
              </div>
              <div className="relative flex justify-center">
                <span className="bg-white px-2 text-sm text-gray-600">{snowflakeAccount}</span>
              </div>
            </div>
            <SourceSelector mode="selection" databases={[selectedDatabase]} />
          </div>
          <div className="w-1/2 h-full flex flex-col items-center">
            <div className="relative w-3/4 mb-3">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-400" />
              </div>
              <div className="relative flex justify-center">
                <span className="bg-white px-2 text-sm text-gray-600">{nativeAppName}</span>
              </div>
            </div>
            <SourceSelector mode="preview" databases={[selectedDatabase]} />
          </div>
        </div>
      </div>
    </StepWrapper>
  );
}
