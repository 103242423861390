export function formatNumberLong(input: number, precision?: number): string {
  if (input === null) {
    return '0.0';
  }
  const formattedNumber = input.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: precision ?? (input < 1000 ? 1 : 0),
  });
  return formattedNumber;
}

export function getCamelCase(str: string): string {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join('')
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join('-')
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join('_');
}

export function formatDate(dateString: string): string {
  const date = new Date(dateString);
  return `${date.toLocaleString('en-US', {
    month: 'short',
  })} ${date.toLocaleString('en-US', {
    day: 'numeric',
  })}, ${date.getFullYear()}`;
}
