import React, { useState, useEffect } from 'react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { Disclosure, Transition } from '@headlessui/react';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { selectSnowflakeAccount } from 'app/snowflakeSlice';
import {
  selectSelectedDatabases,
  selectSelectedSchemas,
  selectSelectedTables,
  selectDatabase,
  unselectDatabase,
  addSchemas,
} from '../reducers/builderSlice';
import { useGetSchemasQuery } from 'app/createApi';
import SchemaItem from './SchemaItem';
import { classNames } from 'utils/styleUtils';

const tooltipStyles = {
  ...defaultStyles,
  backgroundColor: '#4b5563',
  color: 'white',
  fontSize: 12,
  zIndex: 30,
  maxWidth: 500,
};

interface TooltipData {
  description: string;
}

interface DatabaseItemProps {
  databaseName: string;
  openByDefault?: boolean;
  mode: 'selection' | 'preview';
  highlighted?: boolean;
}

export default function DatabaseItem(props: DatabaseItemProps): JSX.Element {
  const dispatch = useAppDispatch();
  const snowflakeAccount = useAppSelector(selectSnowflakeAccount);
  const selectedDatabases = useAppSelector(selectSelectedDatabases);
  const selectedSchemas = useAppSelector(selectSelectedSchemas);
  const selectedTables = useAppSelector(selectSelectedTables);
  const { data: schemas, isLoading } = useGetSchemasQuery({
    account: snowflakeAccount,
    database: props.databaseName,
  });
  const [filteredSchemas, setFilteredSchemas] = useState<string[]>([]);
  const [selected, setSelected] = useState(false);

  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip<TooltipData>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  useEffect(() => {
    if (schemas !== undefined) {
      const schemaArray = schemas.map((schema) => {
        return { database: props.databaseName, schema };
      });
      dispatch(addSchemas({ databaseKey: props.databaseName, schemas: schemaArray }));
    }
  }, [schemas]);

  // In preview mode, only show the selected schemas
  useEffect(() => {
    if (schemas !== undefined) {
      if (props.mode === 'selection') {
        setFilteredSchemas(schemas);
      } else {
        setFilteredSchemas(
          schemas.filter(
            (schema) =>
              selectedTables.some(
                (selected) => selected.database === props.databaseName && selected.schema === schema,
              ) ||
              selectedSchemas.some(
                (selected) => selected.database === props.databaseName && selected.schema === schema,
              ) ||
              selectedDatabases.some((selected) => selected.database === props.databaseName),
          ),
        );
      }
    }
  }, [schemas, selectedDatabases, selectedSchemas]);

  useEffect(() => {
    selectedDatabases.find((database) => database.database === props.databaseName) !== undefined
      ? setSelected(true)
      : setSelected(false);
  }, [selectedDatabases]);

  const onClick = (): void => {
    if (selected) {
      dispatch(unselectDatabase({ database: props.databaseName }));
    } else {
      dispatch(selectDatabase({ database: props.databaseName }));
    }
    setSelected(!selected);
  };

  /* eslint-disable @typescript-eslint/strict-boolean-expressions */
  return (
    <>
      <Disclosure defaultOpen={props.openByDefault}>
        {({ open }) => (
          <>
            <div
              className={classNames(
                'w-3/4 relative flex items-center justify-start py-1 border border-gray-300 bg-gray-50 rounded-md text-gray-700 cursor-default',
                props.mode === 'selection' ? 'px-4' : 'px-2',
              )}
            >
              {props.mode === 'selection' && (
                <div className="absolute left-2">
                  <input
                    type="checkbox"
                    checked={selected}
                    onChange={() => onClick()}
                    className="rounded border-gray-300 cursor-pointer"
                  />
                </div>
              )}
              <div
                className={classNames('min-w-[20px] w-5', props.mode === 'selection' ? 'ml-2' : '')}
                ref={containerRef}
              >
                {props.highlighted === true ? (
                  <img className="w-5" src="/static/database-icon-solid.svg" alt="Database" />
                ) : (
                  <img className="w-5" src="/static/database-icon.svg" alt="Database" />
                )}
              </div>
              <div
                className="text-sm truncate mr-2"
                onMouseLeave={() => {
                  hideTooltip();
                }}
                onMouseMove={(event) => {
                  const eventSvgCoords = localPoint(event);
                  showTooltip({
                    tooltipData: { description: props.databaseName },
                    tooltipTop: eventSvgCoords?.y !== undefined ? eventSvgCoords.y + 10 : undefined,
                    tooltipLeft: eventSvgCoords?.x,
                  });
                }}
              >
                {props.databaseName}
              </div>
              <Disclosure.Button className="absolute right-1 w-5 cursor-pointer">
                <ChevronRightIcon
                  className={classNames(
                    open ? 'transform rotate-90' : '',
                    'w-5 h-5 ml-auto text-gray-700 transition duration-150 ease-in-out',
                  )}
                />
              </Disclosure.Button>
            </div>

            <Transition
              show={open}
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Disclosure.Panel className="w-3/4 px-4 space-y-1">
                {filteredSchemas.map((schema) => (
                  <SchemaItem
                    key={schema}
                    databaseName={props.databaseName}
                    schemaName={schema}
                    mode={props.mode}
                    highlighted={props.highlighted}
                  />
                ))}
                {isLoading && (
                  <div className="w-full h-[30px] border border-gray-300 rounded-md py-1 px-2">
                    <div className="animate-pulse h-2 bg-slate-300 rounded mt-[6px]"></div>
                  </div>
                )}
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
      {tooltipOpen && tooltipData !== undefined && (
        <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
          <div className="text-sm">{tooltipData.description}</div>
        </TooltipInPortal>
      )}
    </>
  );
}
