import React from 'react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { Disclosure, Transition } from '@headlessui/react';
import TestEditorColumnItem from './TestEditorColumnItem';
import type { IModel, ITest, IModelAndColumn } from '../types/testEditorTypes';
import { classNames } from 'utils/styleUtils';

interface TestEditorModelItemProps {
  model: IModel;
  openDialog: (value: boolean) => void;
  selectedModelsAndColumns: IModelAndColumn[];
  setSelectedModelsAndColumns: (value: IModelAndColumn[]) => void;
  setExistingTest: (value: ITest | undefined) => void;
  onRemoveTest: (modelName: string, columnName: string, test: ITest) => void;
}

export default function TestEditorModelItem(props: TestEditorModelItemProps): JSX.Element {
  const numTests = props.model.columns.reduce((acc, column) => acc + (column.tests?.length ?? 0), 0);

  return (
    <>
      <Disclosure>
        {({ open }) => (
          <>
            <div className="w-full relative flex items-center px-3 py-1 border border-gray-300 bg-gray-50 rounded-md cursor-default overflow-hidden">
              <div className="mr-1.5 pt-0.5">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 16 16"
                  fill="#4b5563"
                  stroke="none"
                  xmlns="http://www.w3.org/2000/svg"
                  role="presentation"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.5 2C3.11929 2 2 3.11929 2 4.5V10.5C2 11.8807 3.11929 13 4.5 13H12.5C13.8807 13 15 11.8807 15 10.5V4.5C15 3.11929 13.8807 2 12.5 2H4.5ZM3 4.5C3 3.67157 3.67157 3 4.5 3H12.5C13.3284 3 14 3.67157 14 4.5V5H3V4.5ZM3 6V10.5C3 11.3284 3.67157 12 4.5 12H12.5C13.3284 12 14 11.3284 14 10.5V6H3Z"
                  ></path>
                </svg>
              </div>
              <div className="grow text-sm text-gray-700 truncate">{props.model.name.replace(/^(_v_|_t_)/, '')}</div>
              {numTests !== 0 && <div className="text-sm text-gray-500 mr-8">{`${numTests} tests`}</div>}
              <Disclosure.Button className="w-6 ml-2 cursor-pointer">
                <ChevronRightIcon
                  className={classNames(
                    open ? 'transform rotate-90' : '',
                    'w-6 h-6 ml-auto text-gray-700 transition duration-150 ease-in-out',
                  )}
                />
              </Disclosure.Button>
            </div>

            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacitsy-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Disclosure.Panel className="space-y-1 px-4">
                {props.model.columns.map((column: any) => (
                  <TestEditorColumnItem
                    key={column.name}
                    column={column}
                    schemaName={props.model.schema}
                    modelName={props.model.name}
                    openDialog={props.openDialog}
                    selectedModelsAndColumns={props.selectedModelsAndColumns}
                    setSelectedModelsAndColumns={props.setSelectedModelsAndColumns}
                    setExistingTest={props.setExistingTest}
                    onRemoveTest={props.onRemoveTest}
                  />
                ))}
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </>
  );
}
