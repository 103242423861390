import React from 'react';
import { RocketLaunchIcon } from '@heroicons/react/20/solid';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import {
  selectNumberOfSteps,
  selectSelectedGroup,
  selectNewProjectName,
  setCurrentStep,
  selectPipelineId,
} from 'app/sharedSlice';
import { selectSnowflakeAccount } from 'app/snowflakeSlice';
import { getConfig } from 'config/config-helper';
import { sendMetrics, MetricType, ExitTo } from 'utils/metrics';
import { selectUser } from 'features/authentication/reducers/userSlice';

const { dataopsliveBaseUrl } = getConfig();

export default function NativeAppComplete(): JSX.Element {
  const dispatch = useAppDispatch();
  const numberOfSteps = useAppSelector(selectNumberOfSteps);
  const group = useAppSelector(selectSelectedGroup);
  const newProjectName = useAppSelector(selectNewProjectName);
  const pipelineId = useAppSelector(selectPipelineId);
  const snowflakeAccount = useAppSelector(selectSnowflakeAccount);

  const projectPath = `${group.path}/${newProjectName}`;
  const pathForLink = projectPath.toLowerCase().split(' ').join('-');
  const devReadyLink = `https://develop.dataops.live/quickstart?autostart=true#LAUNCH_MODE=NORMAL/https://app.dataops.live/${pathForLink}/-/tree/main/`;
  const pipelineLink = `${dataopsliveBaseUrl}/${pathForLink}/-/pipelines/${pipelineId}`;
  const snowsightLink = `https://${snowflakeAccount}.snowflakecomputing.com/`;
  const user = useAppSelector(selectUser);

  const sendMetricsAndNavigate = (exitTo: ExitTo): void => {
    sendMetrics(MetricType.create_analytics_end, user, null, exitTo);
  };

  return (
    <div className="h-fit w-fit max-w-lg mx-[auto] m-4 mt-[4.9rem] p-8 bg-white rounded-md drop-shadow-md flex flex-col items-center space-y-8">
      <div className="flex">
        <div className="text-2xl text-gray-900">Native app created</div>
        <RocketLaunchIcon className="w-8 ml-4 text-dataops-primary-blue" />
      </div>
      <div className="flex flex-col items-center">
        <div className="text-gray-600">
          Open our dedicated development environment which provides all the tools you need to view and update your
          native app
        </div>
        <a href={devReadyLink} className="w-3/4" onClick={() => sendMetricsAndNavigate(ExitTo.developer)}>
          <button className="w-full mt-4 rounded-md bg-dataops-secondary-blue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-hover-secondary-blue disabled:bg-gray-400">
            Open Native App Designer
          </button>
        </a>
      </div>
      <div className="w-3/4 border-t border-gray-300"></div>
      <div className="flex flex-col items-center">
        <div className="text-gray-600">
          We are running a DataOps pipeline for you to deploy the initial version of your native app to Snowflake
        </div>
        <a href={pipelineLink} className="w-3/4" onClick={() => sendMetricsAndNavigate(ExitTo.pipeline)}>
          <button className="w-full mt-6 bg-white rounded-md py-1 px-3 text-gray-900 shadow-sm hover:bg-gray-200 ring-1 ring-gray-300">
            Open pipeline
          </button>
        </a>
        <a href={snowsightLink} className="w-3/4" onClick={() => sendMetricsAndNavigate(ExitTo.snowsight)}>
          <button className="w-full mt-6 bg-white rounded-md py-1 px-3 text-gray-900 shadow-sm hover:bg-gray-200 ring-1 ring-gray-300">
            Open in Snowsight UI
          </button>
        </a>
        <div className="text-gray-600 self-start mt-8">
          Pipeline status: <span className="text-dataops-primary-blue font-semibold">Running</span>
        </div>
        <div className="absolute bottom-6 right-8 ml-[auto]">
          <button
            type="button"
            onClick={() => dispatch(setCurrentStep(numberOfSteps - 1))}
            className="text-md font-semibold leading-6 text-gray-900 hover:underline"
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
}
