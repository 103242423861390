import React from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import TestEditorModelItem from './TestEditorModelItem';
import type { IModelsFileContent, ITest, IModelAndColumn } from '../types/testEditorTypes';

interface TestEditorProps {
  modelsFileContent: IModelsFileContent | undefined;
  openDialog: (value: boolean) => void;
  selectedModelsAndColumns: IModelAndColumn[];
  setSelectedModelsAndColumns: (value: IModelAndColumn[]) => void;
  setExistingTest: (value: ITest | undefined) => void;
  onRemoveTest: (modelName: string, columnName: string, test: ITest) => void;
  isLoading: boolean;
}

const TestEditor = (props: TestEditorProps): JSX.Element => {
  if (props.isLoading) {
    return (
      <div className="min-h-8 flex items-center justify-center">
        <div
          className="animate-spin inline-block w-8 h-8 border-[2px] border-current border-t-transparent text-cyan-800 rounded-full"
          role="status"
          aria-label="loading"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  if (
    props.modelsFileContent === undefined ||
    props.modelsFileContent.models === undefined ||
    props.modelsFileContent.models.length === 0
  ) {
    return (
      <div className="max-w-[50rem] mt-2 mx-[auto] rounded-md bg-blue-50 p-4 flex">
        <div className="flex-shrink-0">
          <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
        </div>
        <div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-blue-700 font-semibold">Heads up</p>
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-blue-700">
              Currently, your data product does not include any models. Please navigate to the SQL Model Builder step to
              add and configure models.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="w-full h-[1.5rem] max-w-[35rem] flex items-stretch justify-end">
        {props.selectedModelsAndColumns.length > 0 && (
          <>
            <div className="text-gray-500 text-sm">{props.selectedModelsAndColumns.length} columns selected</div>
            <button
              className="ml-[auto] rounded-full bg-dataops-secondary-blue px-2 py-0.5 text-sm font-semibold text-white shadow-sm hover:bg-hover-secondary-blue"
              onClick={() => {
                props.setExistingTest(undefined);
                props.openDialog(true);
              }}
            >
              Add test
            </button>
            <button
              className="rounded-full ml-1 px-2 py-0.5 text-sm font-semibold text-dataops-secondary-blue shadow-sm border border-dataops-secondary-blue hover:border-hover-secondary-blue hover:text-hover-secondary-blue"
              onClick={() => {
                props.setSelectedModelsAndColumns([]);
              }}
            >
              Clear selection
            </button>
          </>
        )}
      </div>
      <div className="w-full max-w-[35rem] mt-2 mb-4 lex flex-col items-center space-y-1">
        {props.modelsFileContent.models?.map((model) => (
          <TestEditorModelItem
            key={model.name}
            model={model}
            openDialog={props.openDialog}
            selectedModelsAndColumns={props.selectedModelsAndColumns}
            setSelectedModelsAndColumns={props.setSelectedModelsAndColumns}
            setExistingTest={props.setExistingTest}
            onRemoveTest={props.onRemoveTest}
          />
        ))}
      </div>
    </>
  );
};

export default TestEditor;
