import React from 'react';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { selectSelectedDatabase, setSelectedDatabase } from 'app/snowflakeSlice';
import { clearSelectedObjects } from 'features/data-product-builder/reducers/builderSlice';
import StepWrapper from 'components/StepWrapper';
import DatabaseSelector from 'components/DatabaseSelector';

export interface SharedDataDatabaseStepProps {
  onBack: () => void;
  onContinue: () => void;
}

export default function SharedDataDatabaseStep(props: SharedDataDatabaseStepProps): JSX.Element {
  const dispatch = useAppDispatch();
  const selectedDatabase = useAppSelector(selectSelectedDatabase);

  return (
    <StepWrapper
      title="Shared Data - Database selection"
      subtitle="Which database do you want to use for shared data?"
      onBack={() => props.onBack()}
      onContinue={() => props.onContinue()}
      continueDisabled={selectedDatabase === ''}
    >
      <div className="flex flex-col items-center">
        <div className="min-h-full w-full flex flex-col">
          <DatabaseSelector
            onSelect={(database) => {
              dispatch(clearSelectedObjects());
              dispatch(setSelectedDatabase(database));
            }}
            selectedDatabases={selectedDatabase !== '' ? [selectedDatabase] : []}
          />
        </div>
      </div>
    </StepWrapper>
  );
}
