import React from 'react';
import { RocketLaunchIcon } from '@heroicons/react/20/solid';
import { LightBulbIcon } from '@heroicons/react/24/outline';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import {
  selectNumberOfSteps,
  selectProjectPath,
  selectBranch,
  setCurrentStep,
  selectPipelineId,
} from 'app/sharedSlice';
import { getConfig } from 'config/config-helper';
import DividerWithText from 'components/DividerWithText';

const { dataopsliveBaseUrl } = getConfig();

export default function DbtFlowComplete(): JSX.Element {
  const dispatch = useAppDispatch();
  const numberOfSteps = useAppSelector(selectNumberOfSteps);
  const projectPath = useAppSelector(selectProjectPath);
  const branch = useAppSelector(selectBranch);
  const pipelineId = useAppSelector(selectPipelineId);

  const pathForLink = projectPath.toLowerCase().split(' ').join('-');
  const devReadyLink = `https://develop.dataops.live/quickstart?autostart=true#LAUNCH_MODE=DBT_QUICKSTART/https://app.dataops.live/${pathForLink}/-/tree/${branch}/`;
  const dataOpsProjectLink = `${dataopsliveBaseUrl}/${pathForLink}/-/tree/${branch}`;
  const pipelineLink = `${dataopsliveBaseUrl}/${pathForLink}/-/pipelines/${pipelineId}`;
  const newMergeRequestLink = `${dataopsliveBaseUrl}/${pathForLink}/-/merge_requests/new?merge_request%5Bsource_branch%5D=${branch}`;

  return (
    <div className="relative h-fit w-fit max-w-[60rem] mx-[auto] m-4 mt-[4.9rem] p-8 bg-white rounded-md drop-shadow-md flex flex-col items-center space-y-6">
      <div className="flex flex-col items-center">
        <div className="flex">
          <div className="text-2xl text-gray-900">DataOps Pipeline Created</div>
          <RocketLaunchIcon className="w-8 ml-4 text-dataops-primary-blue" />
        </div>
        <p className="w-full text-center mt-1 text-sm text-gray-600">
          Congratulations, you have just created your fist DataOps pipeline from your dbt core project. So what&apos;s
          next?
        </p>
      </div>

      <div className="flex gap-4 flex-wrap">
        <a
          href="https://docs.dataops.live/docs/guides/dataops-fundamentals/project-structure/"
          target="_blank"
          rel="noreferrer"
        >
          <button
            type="button"
            className="flex rounded-full bg-white px-3 py-1.5 text-sm font-semibold text-dataops-primary-blue shadow-sm ring-1 ring-inset ring-dataops-primary-blue hover:text-hover-primary-blue"
          >
            <div className="flex-shrink-0">
              <LightBulbIcon className="h-5 w-5" aria-hidden="true" />
            </div>

            <div className="ml-1 flex-1 justify-between">
              <p className="text-sm">DataOps projects and pipelines</p>
            </div>
          </button>
        </a>
        <a
          href="https://docs.dataops.live/docs/operate-operate-pipelines/scheduling-pipelines/"
          target="_blank"
          rel="noreferrer"
        >
          <button
            type="button"
            className="flex rounded-full bg-white px-3 py-1.5 text-sm font-semibold text-dataops-primary-blue shadow-sm ring-1 ring-inset ring-dataops-primary-blue hover:text-hover-primary-blue"
          >
            <div className="flex-shrink-0">
              <LightBulbIcon className="h-5 w-5" aria-hidden="true" />
            </div>

            <div className="ml-1 flex-1 justify-between">
              <p className="text-sm">How to schedule a pipeline</p>
            </div>
          </button>
        </a>
        <a
          href="https://docs.dataops.live/docs/dataops-development-environment/"
          target="_blank"
          rel="noreferrer"
          className="hidden xl:block"
        >
          <button
            type="button"
            className="flex rounded-full bg-white px-3 py-1.5 text-sm font-semibold text-dataops-primary-blue shadow-sm ring-1 ring-inset ring-dataops-primary-blue hover:text-hover-primary-blue"
          >
            <div className="flex-shrink-0">
              <LightBulbIcon className="h-5 w-5" aria-hidden="true" />
            </div>

            <div className="ml-1 flex-1 justify-between">
              <p className="text-sm">Learn about the DataOps.live IDE</p>
            </div>
          </button>
        </a>
      </div>

      <div className="w-full flex text-gray-700">
        <div className="w-full space-y-3 pr-4">
          <DividerWithText dividerText="Project" />
          <p className="px-2">
            We have migrated your dbt core project to DataOps project and started a simple pipeline on your behalf to
            build selected models in an automated way.
          </p>

          <p className="px-2">
            DataOps project name:{' '}
            <a
              href={dataOpsProjectLink}
              target="_blank"
              rel="noreferrer"
              className="text-dataops-secondary-blue hover:text-hover-secondary-blue"
            >
              {projectPath}
            </a>
          </p>

          <p className="px-2">
            DataOps project is a curated and governed project, we have created a main and a feature branch for you where
            you can go an do configuration and changes to your project in controlled way
          </p>
        </div>
      </div>

      <div className="w-full flex text-gray-700">
        <div className="w-full pr-4 space-y-3">
          <DividerWithText dividerText="Pipeline" />
          <p className="px-2">
            We have automatically created a simple DataOps pipeline for your and started it on your behalf. DataOps
            pipeline is run on feature branch and will create you a safe environment in your snowflake account where you
            can verify that all required models are created. Next you can go to your pipeline and try scheduling it or
            create a Merge Request and bring changes to production.
          </p>
          <div className="w-fit pt-2 mx-[auto] flex">
            <a href={pipelineLink} target="_blank" rel="noreferrer">
              <button className="w-[12rem] mr-8 rounded-md bg-dataops-secondary-blue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-hover-secondary-blue disabled:bg-gray-400">
                Open pipeline
              </button>
            </a>
            <a href={newMergeRequestLink} target="_blank" rel="noreferrer">
              <button className="w-[12rem] rounded-md bg-dataops-secondary-blue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-hover-secondary-blue disabled:bg-gray-400">
                Create MR
              </button>
            </a>
          </div>
        </div>
      </div>

      <div className="w-full flex text-gray-700">
        <div className="full pr-4 flex flex-col items-center space-y-3">
          <DividerWithText dividerText="DataOps.live | Develop" />
          <p className="px-2">
            You also have access to our OOTB cloud IDE that you can start working with in minutes. In our IDE you can do
            familiar work of building models, testing models and running DataOps pipelines.
          </p>
          <a href={devReadyLink}>
            <button className="w-[12rem] rounded-md bg-dataops-secondary-blue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-hover-secondary-blue disabled:bg-gray-400">
              Open designer
            </button>
          </a>
        </div>
      </div>

      <div className="absolute bottom-6 right-8 ml-[auto]">
        <button
          type="button"
          onClick={() => dispatch(setCurrentStep(numberOfSteps - 1))}
          className="text-md font-semibold leading-6 text-gray-900 hover:underline"
        >
          Back
        </button>
      </div>
    </div>
  );
}
