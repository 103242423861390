import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
import type { RootState } from 'app/store';
import type { IDatabase, ISchema, ITable, ITest } from 'utils/types';

type IFlow = 'not-selected' | 'snowflake' | 'ddl';

type IEnvironmentManagement = 'not-selected' | 'managed' | 'not-managed';

interface SchemaStore {
  [key: string]: ISchema[];
}

interface TableStore {
  [key: string]: ITable[];
}

export interface IBuilderState {
  flow: IFlow;
  dataProductName: string;
  dataProductDescription: string;
  dataProductOwner: string;
  dataProductVersion: string;
  dataQualityTests: string;
  schemas: SchemaStore;
  tables: TableStore;
  selectedDatabases: IDatabase[];
  selectedSchemas: ISchema[];
  selectedTables: ITable[];
  environmentManagement: IEnvironmentManagement;
  generateSoleFromObjectsOutput: string;
  soleOutput: string;
  numberOfSelectedSourceTables: number;
  numberOfGeneratedTests: number;
  generatedAutomatedTests: boolean;
  generatedTests: ITest[];
}

export const initialState: IBuilderState = {
  flow: 'not-selected',
  dataProductName: '',
  dataProductDescription: '',
  dataProductOwner: '',
  dataProductVersion: '0.1.0',
  dataQualityTests: '',
  schemas: {},
  tables: {},
  selectedDatabases: [],
  selectedSchemas: [],
  selectedTables: [],
  environmentManagement: 'not-selected',
  generateSoleFromObjectsOutput: '',
  soleOutput: '',
  numberOfSelectedSourceTables: 0,
  numberOfGeneratedTests: 0,
  generatedAutomatedTests: false,
  generatedTests: [],
};

export const builderSlice = createSlice({
  name: 'builder',
  initialState,
  reducers: {
    setFlow: (state, action: PayloadAction<IFlow>) => {
      state.flow = action.payload;
    },
    setDataProductName: (state, action: PayloadAction<string>) => {
      state.dataProductName = action.payload;
    },
    setDataProductDescription: (state, action: PayloadAction<string>) => {
      state.dataProductDescription = action.payload;
    },
    setDataProductOwner: (state, action: PayloadAction<string>) => {
      state.dataProductOwner = action.payload;
    },
    setDataProductVersion: (state, action: PayloadAction<string>) => {
      state.dataProductVersion = action.payload;
    },
    setDataQualityTests: (state, action: PayloadAction<string>) => {
      state.dataQualityTests = action.payload;
    },
    addSchemas: (state, action: PayloadAction<{ databaseKey: string; schemas: ISchema[] }>) => {
      state.schemas[action.payload.databaseKey] = action.payload.schemas;
    },
    addTables: (state, action: PayloadAction<{ schemaKey: string; tables: ITable[] }>) => {
      state.tables[action.payload.schemaKey] = action.payload.tables;
    },
    selectDatabase: (state, action: PayloadAction<IDatabase>) => {
      // Select the database
      const updatedDatabaseArray = [...state.selectedDatabases];
      updatedDatabaseArray.push(action.payload);
      state.selectedDatabases = updatedDatabaseArray;

      // Unselect the schemas within the database
      if (action.payload.database in state.schemas) {
        const originalSchemaArray = [...state.selectedSchemas];
        const updatedSchemaArray = originalSchemaArray.filter((schema) => schema.database !== action.payload.database);
        state.selectedSchemas = updatedSchemaArray;
      }
    },
    unselectDatabase: (state, action: PayloadAction<IDatabase>) => {
      const index = state.selectedDatabases.findIndex((database) => database.database === action.payload.database);
      const updatedDatabaseArray = [...state.selectedDatabases];
      updatedDatabaseArray.splice(index, 1);
      state.selectedDatabases = updatedDatabaseArray;

      // Unselect all schemas within the database
      const originalSchemaArray = [...state.selectedSchemas];
      const updatedSchemaArray = originalSchemaArray.filter((schema) => schema.database !== action.payload.database);
      state.selectedSchemas = updatedSchemaArray;
    },
    selectSchema: (state, action: PayloadAction<ISchema>) => {
      // Select the schema
      const updatedSchemaArray = [...state.selectedSchemas];
      updatedSchemaArray.push(action.payload);
      state.selectedSchemas = updatedSchemaArray;

      // Unselect the tables within the schema
      const schemaKey = `${action.payload.database}.${action.payload.schema}`;
      if (schemaKey in state.tables) {
        const originalTableArray = [...state.selectedTables];
        const updatedTableArray = originalTableArray.filter(
          (table) => table.schema !== action.payload.schema || table.database !== action.payload.database,
        );
        state.selectedTables = updatedTableArray;
      }

      // If all schemas within the database as selected then
      // select the database an unselect the schemas
      const allSchemasSelected = state.schemas[action.payload.database].every((schema) => {
        return (
          state.selectedSchemas.find(
            (selectedSchema) => selectedSchema.database === schema.database && selectedSchema.schema === schema.schema,
          ) !== undefined
        );
      });
      if (allSchemasSelected) {
        const updatedDatabaseArray = [...state.selectedDatabases];
        updatedDatabaseArray.push({ database: action.payload.database });
        state.selectedDatabases = updatedDatabaseArray;

        const updatedSchemaArray = [...state.selectedSchemas];
        state.schemas[action.payload.database].forEach((schema) => {
          updatedSchemaArray.splice(
            updatedSchemaArray.findIndex(
              (selectedSchema) =>
                selectedSchema.database === action.payload.database && selectedSchema.schema === schema.schema,
            ),
            1,
          );
        });
        state.selectedSchemas = updatedSchemaArray;
      }
    },
    unselectSchema: (state, action: PayloadAction<ISchema>) => {
      // Unselect database
      const databaseIndex = state.selectedDatabases.findIndex(
        (database) => database.database === action.payload.database,
      );
      if (databaseIndex !== -1) {
        const updatedDatabaseArray = [...state.selectedDatabases];
        updatedDatabaseArray.splice(databaseIndex, 1);
        state.selectedDatabases = updatedDatabaseArray;

        const updatedSchemaArray = [...state.selectedSchemas];
        state.schemas[action.payload.database].forEach((schema) => {
          updatedSchemaArray.push({ database: action.payload.database, schema: schema.schema });
        });
        state.selectedSchemas = updatedSchemaArray;
      }

      // Unselect schema
      const index = state.selectedSchemas.findIndex(
        (schema) => schema.schema === action.payload.schema && schema.database === action.payload.database,
      );
      const updatedSchemaArray = [...state.selectedSchemas];
      updatedSchemaArray.splice(index, 1);
      state.selectedSchemas = updatedSchemaArray;

      // Unselect the tables within the schema
      const schemaKey = `${action.payload.database}.${action.payload.schema}`;
      if (schemaKey in state.tables) {
        const originalTableArray = [...state.selectedTables];
        const updatedTableArray = originalTableArray.filter(
          (table) => table.schema !== action.payload.schema || table.database !== action.payload.database,
        );
        state.selectedTables = updatedTableArray;
      }
    },
    selectTable: (state, action: PayloadAction<ITable>) => {
      // Select the table
      const updatedTableArray = [...state.selectedTables];
      updatedTableArray.push(action.payload);
      state.selectedTables = updatedTableArray;

      // If all tables within the schema as selected then
      // select the schema an unselect the tables
      const schemaKey = `${action.payload.database}.${action.payload.schema}`;
      const allTablesSelected = state.tables[schemaKey].every((table) => {
        return (
          state.selectedTables.find(
            (selectedTable) =>
              selectedTable.database === table.database &&
              selectedTable.schema === table.schema &&
              selectedTable.table === table.table,
          ) !== undefined
        );
      });
      if (allTablesSelected) {
        const updatedSchemaArray = [...state.selectedSchemas];
        updatedSchemaArray.push({ database: action.payload.database, schema: action.payload.schema });
        state.selectedSchemas = updatedSchemaArray;

        const updatedTableArray = [...state.selectedTables];
        state.tables[schemaKey].forEach((table) => {
          updatedTableArray.splice(
            updatedTableArray.findIndex(
              (selectedTable) =>
                selectedTable.database === action.payload.database &&
                selectedTable.schema === action.payload.schema &&
                selectedTable.table === table.table,
            ),
            1,
          );
        });
        state.selectedTables = updatedTableArray;
      }

      // If all schemas within the database as selected then
      // select the database an unselect the schemas
      const allSchemasSelected = state.schemas[action.payload.database].every((schema) => {
        return (
          state.selectedSchemas.find(
            (selectedSchema) => selectedSchema.database === schema.database && selectedSchema.schema === schema.schema,
          ) !== undefined
        );
      });
      if (allSchemasSelected) {
        const updatedDatabaseArray = [...state.selectedDatabases];
        updatedDatabaseArray.push({ database: action.payload.database });
        state.selectedDatabases = updatedDatabaseArray;

        const updatedSchemaArray = [...state.selectedSchemas];
        state.schemas[action.payload.database].forEach((schema) => {
          updatedSchemaArray.splice(
            updatedSchemaArray.findIndex(
              (selectedSchema) =>
                selectedSchema.database === action.payload.database && selectedSchema.schema === schema.schema,
            ),
            1,
          );
        });
        state.selectedSchemas = updatedSchemaArray;
      }
    },
    unselectTable: (state, action: PayloadAction<ITable>) => {
      // Unselect database
      const databaseIndex = state.selectedDatabases.findIndex(
        (database) => database.database === action.payload.database,
      );
      if (databaseIndex !== -1) {
        const updatedDatabaseArray = [...state.selectedDatabases];
        updatedDatabaseArray.splice(databaseIndex, 1);
        state.selectedDatabases = updatedDatabaseArray;

        const updatedSchemaArray = [...state.selectedSchemas];
        state.schemas[action.payload.database].forEach((schema) => {
          updatedSchemaArray.push({ database: action.payload.database, schema: schema.schema });
        });
        state.selectedSchemas = updatedSchemaArray;
      }

      // Unselect the schema
      const schemaIndex = state.selectedSchemas.findIndex(
        (schema) => schema.database === action.payload.database && schema.schema === action.payload.schema,
      );
      if (schemaIndex !== -1) {
        const updatedArray = [...state.selectedSchemas];
        updatedArray.splice(schemaIndex, 1);
        state.selectedSchemas = updatedArray;

        const updatedTableArray = [...state.selectedTables];
        const schemaKey = `${action.payload.database}.${action.payload.schema}`;
        state.tables[schemaKey].forEach((table) => {
          updatedTableArray.push({ database: table.database, schema: table.schema, table: table.table });
        });
        state.selectedTables = updatedTableArray;
      }

      // Unselect the table
      const index = state.selectedTables.findIndex(
        (table) =>
          table.database === action.payload.database &&
          table.schema === action.payload.schema &&
          table.table === action.payload.table,
      );
      const updatedTableArray = [...state.selectedTables];
      updatedTableArray.splice(index, 1);
      state.selectedTables = updatedTableArray;
    },
    clearSelectedObjects: (state) => {
      state.selectedDatabases = [];
      state.selectedSchemas = [];
      state.selectedTables = [];
      state.schemas = {};
      state.tables = {};
    },
    clearSelectedObjectsForDatabase: (state, action: PayloadAction<string>) => {
      // Unselect all schemas within the database
      const database = action.payload;

      if (state.schemas[database] === undefined) {
        return;
      }

      const updatedSchemaArray = [...state.selectedSchemas];
      state.schemas[database].forEach((schema) => {
        updatedSchemaArray.splice(
          updatedSchemaArray.findIndex(
            (selectedSchema) => selectedSchema.database === database && selectedSchema.schema === schema.schema,
          ),
          1,
        );
      });
      state.selectedSchemas = updatedSchemaArray;
    },
    setEnvironmentManagement: (state, action: PayloadAction<IEnvironmentManagement>) => {
      state.environmentManagement = action.payload;
    },
    setGenerateSoleFromObjectsOutput: (state, action: PayloadAction<string>) => {
      state.generateSoleFromObjectsOutput = action.payload;
    },
    setSoleOutput: (state, action: PayloadAction<string>) => {
      state.soleOutput = action.payload;
    },
    setNumberOfSelectedSourceTables: (state, action: PayloadAction<number>) => {
      state.numberOfSelectedSourceTables = action.payload;
    },
    setNumberOfGeneratedTests: (state, action: PayloadAction<number>) => {
      state.numberOfGeneratedTests = action.payload;
    },
    setGenerateAutomatedTests: (state, action: PayloadAction<boolean>) => {
      state.generatedAutomatedTests = action.payload;
    },
    setGeneratedTests: (state, action: PayloadAction<ITest[]>) => {
      state.generatedTests = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => {
      return initialState;
    });
  },
});

export const {
  setFlow,
  setDataProductName,
  setDataProductDescription,
  setDataProductOwner,
  setDataProductVersion,
  setDataQualityTests,
  addSchemas,
  addTables,
  selectDatabase,
  unselectDatabase,
  selectSchema,
  unselectSchema,
  selectTable,
  unselectTable,
  clearSelectedObjects,
  clearSelectedObjectsForDatabase,
  setEnvironmentManagement,
  setGenerateSoleFromObjectsOutput,
  setSoleOutput,
  setNumberOfSelectedSourceTables,
  setNumberOfGeneratedTests,
  setGenerateAutomatedTests,
  setGeneratedTests,
} = builderSlice.actions;

export const selectFlow = (state: RootState): IFlow => state.builder.flow;
export const selectDataProductName = (state: RootState): string => state.builder.dataProductName;
export const selectDataProductDescription = (state: RootState): string => state.builder.dataProductDescription;
export const selectDataProductOwner = (state: RootState): string => state.builder.dataProductOwner;
export const selectDataProductVersion = (state: RootState): string => state.builder.dataProductVersion;
export const selectDataQualityTests = (state: RootState): string => state.builder.dataQualityTests;
export const selectSelectedDatabases = (state: RootState): IDatabase[] => state.builder.selectedDatabases;
export const selectSelectedSchemas = (state: RootState): ISchema[] => state.builder.selectedSchemas;
export const selectSelectedTables = (state: RootState): ITable[] => state.builder.selectedTables;
export const selectSchemas = (state: RootState): SchemaStore => state.builder.schemas;
export const selectTables = (state: RootState): TableStore => state.builder.tables;
export const selectEnvironmentManagement = (state: RootState): IEnvironmentManagement =>
  state.builder.environmentManagement;
export const selectGenerateSoleFromObjectsOutput = (state: RootState): string =>
  state.builder.generateSoleFromObjectsOutput;
export const selectSoleOutput = (state: RootState): string => state.builder.soleOutput;
export const selectNumberOfSelectedSourceTables = (state: RootState): number =>
  state.builder.numberOfSelectedSourceTables;
export const selectNumberOfGeneratedTests = (state: RootState): number => state.builder.numberOfGeneratedTests;
export const selectGenerateAutomatedTests = (state: RootState): boolean => state.builder.generatedAutomatedTests;
export const selectGeneratedTests = (state: RootState): ITest[] => state.builder.generatedTests;

export default builderSlice.reducer;
